import { Button, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Language, enterPasswordEN, enterPasswordPL, modalNoEN, modalNoPL, modalYesEN, modalYesPL, perlySangonomiyiGiveUpButtonEN, perlySangonomiyiGiveUpButtonPL, perlySangonomiyiGiveUpModalEN, perlySangonomiyiGiveUpModalPL, perlySangonomiyiGoBackToMapEN, perlySangonomiyiGoBackToMapPL } from '../../../constants';
import './QrCodeReader.scss';
import { QrScanner } from '@yudiel/react-qr-scanner';

interface QrCodeReaderProps {
    lang: Language,
    password: string,
    setPassword: (password: string) => void,
    goBackToMap: () => void,
    cancelQuest: () => void,
}

function QrCodeReader(props: QrCodeReaderProps) {
    const [isModalVisible, setModalVisibility] = useState(false);

    return (<div className='qrCodeReaderBody'>
        <QrScanner
            onDecode={(result) => props.setPassword(result)}
            onError={(error) => console.log(error?.message)}
        />
        <div className='qrCodeReaderInput'>
            <Input placeholder={props.lang === Language.PL ? enterPasswordPL : enterPasswordEN} onChange={(e) => props.setPassword(e.target.value)} size='large' value={props.password} />
        </div>
        <div className='qrCodeReaderButtons'>
            <Button
                size='large'
                type="primary"
                className='qrCodeReaderButton'
                onClick={() => props.goBackToMap()}>{props.lang === Language.PL ? perlySangonomiyiGoBackToMapPL : perlySangonomiyiGoBackToMapEN}</Button>
            <Button
                size='large'
                type="primary"
                onClick={() => setModalVisibility(true)}>{props.lang === Language.PL ? perlySangonomiyiGiveUpButtonPL : perlySangonomiyiGiveUpButtonEN}</Button>
        </div>
        <Modal open={isModalVisible}
            okText={props.lang === Language.PL ? modalYesPL : modalYesEN}
            cancelText={props.lang === Language.PL ? modalNoPL : modalNoEN}
            centered={true}
            bodyStyle={{ fontFamily: 'Lato', fontSize: '1.2rem' }}
            cancelButtonProps={{ size: 'large' }}
            okButtonProps={{ size: 'large' }}
            onOk={() => props.cancelQuest()}
            onCancel={() => setModalVisibility(false)}>
            <p>{props.lang === Language.PL ? perlySangonomiyiGiveUpModalPL : perlySangonomiyiGiveUpModalEN}</p>
        </Modal>
    </div>)
}

export default QrCodeReader;