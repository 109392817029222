import React, { useEffect, useState } from 'react'
import './QuestWrittenInTheStars.scss'
import { Language, QuestProgress, mobileEN, mobilePL, questGoBackToMenuEN, questGoBackToMenuPL, questWrittenInTheStarsCompletedEN, questWrittenInTheStarsCompletedPL, questWrittenInTheStarsFailedEN, questWrittenInTheStarsFailedPL } from '../../constants';
import { quests } from '../quests';
import QuestInfo from './QuestInfo/QuestInfo';
import Constellation from './Constellation/Constellation';
import ConstellationGuessingScreen from './ConstellationGuessingScreen/ConstellationGuessingScreen';
import Summary from './Summary/Summary';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import QuestMenuButton from '../../components/quest-menu-button/QuestMenuButton';
import laylaFailed from '../../assets/graphics/emojis/layla3.webp'
import laylaCompleted from '../../assets/graphics/emojis/layla4.webp'

interface QuestWrittenInTheStarsProps {
    lang: Language,
    questProgress: QuestProgress,
    setQuestProgress: (progress: QuestProgress) => void,
}

function QuestWrittenInTheStars(props: QuestWrittenInTheStarsProps) {
    const quest = quests.get(8);
    const [isInfoVisible, setInfoVisibility] = useState(true)
    const [selectedSetNumber, setSelectedSetNumber] = useState<number>()
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedSetNumber(Math.floor(Math.random() * 3))
    }, [])

    return (<div className='questWrittenInTheStarsBody'>
        <QuestMenuButton
            location={props.lang === Language.PL ? mobilePL : mobileEN}
            progress={props.questProgress}
            region={quest?.region}
            title={props.lang === Language.PL ? quest?.namePL : quest?.nameEN} />
        {isInfoVisible && props.questProgress === QuestProgress.inProgress && <QuestInfo lang={props.lang} closeInfo={() => setInfoVisibility(false)} />}
        {!isInfoVisible && props.questProgress === QuestProgress.inProgress && <ConstellationGuessingScreen lang={props.lang} setQuestProgress={props.setQuestProgress} selectedSetNumber={selectedSetNumber || 0} />}
        {props.questProgress === QuestProgress.completed && <div>
            <div className='questWrittenInTheStarsSpeechBubbleBox'>
            <div className="questWrittenInTheStarsSpeechBubble">
                <p className='questWrittenInTheStarsSpeechBubbleText'>
                    {props.lang === Language.PL ? questWrittenInTheStarsCompletedPL  : questWrittenInTheStarsCompletedEN}
                </p>
            </div>
            <img src={laylaCompleted} alt="Layla" className='questWrittenInTheStarsEmoji' />
        </div>
            </div>}
        {props.questProgress === QuestProgress.failed && <div>
            <div className='questWrittenInTheStarsSpeechBubbleBox'>
            <div className="questWrittenInTheStarsSpeechBubble">
                <p className='questWrittenInTheStarsSpeechBubbleText'>
                    {props.lang === Language.PL ? questWrittenInTheStarsFailedPL  : questWrittenInTheStarsFailedEN}
                </p>
            </div>
            <img src={laylaFailed} alt="Layla" className='questWrittenInTheStarsEmoji' />
        </div>
            </div>}
        {(props.questProgress === QuestProgress.completed || props.questProgress === QuestProgress.failed) && <Button size='large' type="primary"
            className="genericStandQuestPasswordButton" onClick={() => navigate('/questMenu')}>{props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button>}
        {(props.questProgress === QuestProgress.completed || props.questProgress === QuestProgress.failed) && <Summary lang={props.lang} selectedSet={selectedSetNumber || 0} />}
    </div>)
}

export default QuestWrittenInTheStars;