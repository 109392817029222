import React from 'react';
import './QuestPerlySangonomiyiFailed.scss'
import kokomi from '../../../../assets/graphics/quests/perlysangonomiyi/kokomiFailed.webp'
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Language, perlySangonomiyiFailedEN, perlySangonomiyiFailedPL, questGoBackToMenuEN, questGoBackToMenuPL } from '../../../../constants';

interface QuestPerlySangonomiyiFailedProps {
    lang: Language,
}

function QuestPerlySangonomiyiFailed(props: QuestPerlySangonomiyiFailedProps) {
    const navigate = useNavigate();
    
    return (<div className='questPerlyFailedBody'>
    <img src={kokomi} alt="Kokomi" className='questPerlyFailedKokomi'/>
    <p className='questPerlyFailedText'>{props.lang === Language.PL ? perlySangonomiyiFailedPL : perlySangonomiyiFailedEN}</p>
    <Button
        size='large'
        type="primary"
        onClick={() => navigate('/questMenu')}>{props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button>
</div>)
}

export default QuestPerlySangonomiyiFailed;