import React from 'react'
import './QuestWymianaJezykowaCompleted.scss'
import hilichurl from '../../../assets/graphics/quests/wymiana jezykowa/happy-hilichurl.webp'
import { Button } from 'antd';
import { Language, questGoBackToMenuEN, questGoBackToMenuPL, wymianaJezykowaCompletedTextEN, wymianaJezykowaCompletedTextPL } from '../../../constants';
import { useNavigate } from 'react-router-dom';

interface QuestWymianaJezykowaCompletedProps {
    lang: Language,
}

function QuestWymianaJezykowaCompleted(props: QuestWymianaJezykowaCompletedProps) {
    const navigate = useNavigate();

    return (<div className='questWymianaJezykowaCompletedBody'>
        <img src={hilichurl} alt="Hilichurl" className="questWymianaJezykowaCompletedEmoji" />
        <p className='questWymianaJezykowaCompletedText'>{props.lang === Language.PL ? wymianaJezykowaCompletedTextPL : wymianaJezykowaCompletedTextEN}</p>
        <Button size='large'
            type="primary"
            onClick={() => navigate('/questMenu')}>{props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button>
    </div>)
}

export default QuestWymianaJezykowaCompleted;