import { Button, Radio, Space } from 'antd';
import React, { useEffect, useState } from 'react'
import { Language, poem3answer1part1EN, poem3answer1part1PL, poem3answer1part2EN, poem3answer1part2PL, poem3answer2part1EN, poem3answer2part1PL, poem3answer2part2EN, poem3answer2part2PL, poem3answer3part1EN, poem3answer3part1PL, poem3answer3part2EN, poem3answer3part2PL, poem3correctAnswer, poem3verse1EN, poem3verse1PL, poem3verse2EN, poem3verse2PL, poem3verse3EN, poem3verse3PL, poem3verse4EN, poem3verse4PL, poem3verse5EN, poem3verse5PL, poem3verse6EN, poem3verse6PL, poem3verse7EN, poem3verse7PL, questMistrzSubmitAnswerEN, questMistrzSubmitAnswerPL, QuestProgress } from '../../../../../constants';
import VentiCompleted from '../../../results/VentiCompleted/VentiCompleted';
import VentiFailed from '../../../results/VentiFailed/VentiFailed';
import FillGap from '../../FillGap/FillGap';


interface Poem3Props {
    lang: Language,
    setQuestProgress: (progress: QuestProgress) => void,
}

function Poem3(props: Poem3Props) {
    const [selectedAnswer, setSelectedAnswer] = useState<undefined | number>(undefined)
    const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false)
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(false)

    useEffect(() => {
        if (selectedAnswer !== undefined) {
            setIsAnswerCorrect(selectedAnswer === poem3correctAnswer)
            props.setQuestProgress(selectedAnswer === poem3correctAnswer ? QuestProgress.completed : QuestProgress.failed)
        }
    }, [isAnswerSubmitted])
    
    return(<div className='poemBody'>
    <div className='poem'>
        <p className='poemVerse'>{props.lang === Language.PL ? poem3verse1PL : poem3verse1EN}</p>
        <p className='poemVerse'>{props.lang === Language.PL ? poem3verse2PL : poem3verse2EN}</p>
        <p className='poemVerse'>{props.lang === Language.PL ? poem3verse3PL : poem3verse3EN}</p>
        <p className='poemVerse'>{props.lang === Language.PL ? poem3verse4PL : poem3verse4EN}</p>
        <p className='poemVerse'>{props.lang === Language.PL ? poem3verse5PL : poem3verse5EN}</p>
        <p className='poemVerse'>{props.lang === Language.PL ? poem3verse6PL : poem3verse6EN}</p>
        <p className='poemVerse'>{props.lang === Language.PL ? poem3verse7PL : poem3verse7EN}</p>
        {!isAnswerSubmitted && <p className='poemVerse'>...</p>}
        {isAnswerSubmitted && selectedAnswer === 1 && <p className='poemVerse poemIncorrect'>{props.lang === Language.PL ? poem3answer1part1PL : poem3answer1part1EN}</p>}
        {isAnswerSubmitted && selectedAnswer === 1 && <p className='poemVerse poemIncorrect'>{props.lang === Language.PL ? poem3answer1part2PL : poem3answer1part2EN}</p>}
        {isAnswerSubmitted && selectedAnswer === 2 && <p className='poemVerse poemCorrect'>{props.lang === Language.PL ? poem3answer2part1PL : poem3answer2part1EN}</p>}
        {isAnswerSubmitted && selectedAnswer === 2 && <p className='poemVerse poemCorrect'>{props.lang === Language.PL ? poem3answer2part2PL : poem3answer2part2EN}</p>}
        {isAnswerSubmitted && selectedAnswer === 3 && <p className='poemVerse poemIncorrect'>{props.lang === Language.PL ? poem3answer3part1PL : poem3answer3part1EN}</p>}
        {isAnswerSubmitted && selectedAnswer === 3 && <p className='poemVerse poemIncorrect'>{props.lang === Language.PL ? poem3answer3part2PL : poem3answer3part2EN}</p>}
    </div>
    {!isAnswerSubmitted && <FillGap lang={props.lang} />}
    {!isAnswerSubmitted && <Radio.Group onChange={(e) => setSelectedAnswer(e.target.value)} value={selectedAnswer} size='large' className='poemAnswers'>
        <Space direction="vertical">
            <Radio value={1}>
                <div className="poemAnswerVerses">
                    <div className='poemVerse'>{props.lang === Language.PL ? poem3answer1part1PL : poem3answer1part1EN}</div>
                    <div className='poemVerse'>{props.lang === Language.PL ? poem3answer1part2PL : poem3answer1part2EN}</div>
                </div></Radio>
            <Radio value={2}>
                <div className="poemAnswerVerses">
                    <div className='poemVerse'>{props.lang === Language.PL ? poem3answer2part1PL : poem3answer2part1EN}</div>
                    <div className='poemVerse'>{props.lang === Language.PL ? poem3answer2part2PL : poem3answer2part2EN}</div>
                </div></Radio>
            <Radio value={3}>
                <div className="poemAnswerVerses">
                    <div className='poemVerse'>{props.lang === Language.PL ? poem3answer3part1PL : poem3answer3part1EN}</div>
                    <div className='poemVerse'>{props.lang === Language.PL ? poem3answer3part2PL : poem3answer3part2EN}</div>
                </div></Radio>
        </Space>
    </Radio.Group>}
    {!isAnswerSubmitted && <Button size='large'
        type="primary"
        disabled={selectedAnswer === undefined}
        onClick={() => setIsAnswerSubmitted(true)}>
        {props.lang === Language.PL ? questMistrzSubmitAnswerPL : questMistrzSubmitAnswerEN}</Button>}
    {isAnswerSubmitted && isAnswerCorrect && <VentiCompleted lang={props.lang}/>}
    {isAnswerSubmitted && !isAnswerCorrect && <VentiFailed lang={props.lang}
        correctAnswerPart1={props.lang === Language.PL ? poem3answer2part1PL : poem3answer2part1EN}
        correctAnswerPart2={props.lang === Language.PL ? poem3answer2part2PL : poem3answer2part2EN} />}
</div>)
}

export default Poem3;