import React from 'react'
import projectLogo from '../../assets/graphics/ui/project-logo.png'
import './TopBar.scss'
import { AiOutlineGlobal, AiOutlineMenu } from "react-icons/ai"
import { Link } from 'react-router-dom'

function TopBar() {
    return (<div className='topBarBody'>
        <Link to={"/menu"} className="link"><img src={projectLogo} className="topBarLogo" alt="logo" /></Link>
        <div className='topBarIconsBox'>
        <Link to={"/language"} className="link"><AiOutlineGlobal size={25} /></Link>
        <Link to={"/menu"} className="link"><AiOutlineMenu size={25} className="topBarIcon" /></Link>
        </div>
    </div>)
}

export default TopBar;