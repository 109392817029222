import React from 'react';
import MenuButton from '../../components/menu-button/MenuButton';
import { Language, LeftEmojis, menuWelcomeTextEN, menuWelcomeTextPL, RightEmojis } from '../../constants';
import scaramouche from '../../assets/graphics/emojis/scaramouche1.webp'
import './MenuScreen.scss';

interface MenuProps {
    lang: Language
}

function MenuScreen(props: MenuProps) {
    return (<div className="menuBody">
        <div className='scaramoucheBox'>
            <img src={scaramouche} className="scaramouche" alt="scaramouche"/>
            <div className="speech-bubble">
                <p className='speech-bubble-text'>{props.lang === Language.PL ? menuWelcomeTextPL : menuWelcomeTextEN}</p>
            </div>
        </div>
        <MenuButton emoji={RightEmojis.Tartaglia} lang={props.lang}/>
        <MenuButton emoji={LeftEmojis.Ayato} lang={props.lang}/>
        <MenuButton emoji={RightEmojis.Thoma} lang={props.lang}/>
        <MenuButton emoji={LeftEmojis.Kaeya} lang={props.lang}/>
    </div>)
}

export default MenuScreen;