import React, { useState } from 'react';
import QuestMenuButton from '../../components/quest-menu-button/QuestMenuButton';
import { Language, mobileEN, mobilePL, QuestProgress, wymianaJezykowaEmojiTextEN, wymianaJezykowaEmojiTextPL, wymianaJezykowaInputPlaceholderEN, wymianaJezykowaInputPlaceholderPL, wymianaJezykowaInstructionEN, wymianaJezykowaInstructionPL, wymianaJezykowaSubmitButtonTextEN, wymianaJezykowaSubmitButtonTextPL } from '../../constants';
import { quests, wymianaJezykowaIsAnswerCorrect } from '../quests';
import hutao from '../../assets/graphics/emojis/hutao1.webp'
import './QuestWymianaJezykowa.scss'
import { Button, Input } from 'antd';
import QuestWymianaJezykowaCompleted from './QuestWymianaJezykowaCompleted/QuestWymianaJezykowaCompleted';
import QuestWymianaJezykowaFailed from './QuestWymianaJezykowaFailed/QuestWymianaJezykowaFailed';

interface QuestWymianaJezykowaProps {
    lang: Language,
    questProgress: QuestProgress,
    setQuestProgress: (progress: QuestProgress) => void,
}

function QuestWymianaJezykowa(props: QuestWymianaJezykowaProps) {
    const [answer, setAnswer] = useState<undefined | string>(undefined);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
    const quest = quests.get(5);

    return (<div className='questWymianaJezykowaBody'>
        <QuestMenuButton
            location={props.lang === Language.PL ? mobilePL : mobileEN}
            progress={props.questProgress}
            region={quest?.region}
            title={props.lang === Language.PL ? quest?.namePL : quest?.nameEN} />
        {!isSubmitted && (
            <div>
            <div className='questWymianaJezykowaEmojiBox'>
                <div className="questWymianaJezykowaSpeechBubble">
                    <p className='questWymianaJezykowaSpeechBubble-speech-bubble-text'>
                        {props.lang === Language.PL ? wymianaJezykowaEmojiTextPL : wymianaJezykowaEmojiTextEN}
                    </p>
                </div>
                <img src={hutao} alt="Hu Tao" className='questWymianaJezykowaEmoji' />
            </div>
            <div className='questWymianaJezykowaInstructionsBox'>
                <p className='questWymianaJezykowaInstructionText'>{props.lang === Language.PL ? wymianaJezykowaInstructionPL : wymianaJezykowaInstructionEN}</p>
                <Input size="large"
                    className='introductionInput'
                    value={answer}
                    onChange={(e) => {
                        setAnswer(e.target.value)
                    }}
                    placeholder={props.lang === Language.PL ? wymianaJezykowaInputPlaceholderPL : wymianaJezykowaInputPlaceholderEN} />
                    <Button size='large' 
                    type="primary" 
                    disabled={answer === undefined} 
                    onClick={() => {
                        setIsSubmitted(true);
                        props.setQuestProgress(wymianaJezykowaIsAnswerCorrect(answer) ? QuestProgress.completed : QuestProgress.failed)
                        setIsAnswerCorrect(wymianaJezykowaIsAnswerCorrect(answer))
                    }}>
                        {props.lang === Language.PL ? wymianaJezykowaSubmitButtonTextPL : wymianaJezykowaSubmitButtonTextEN}</Button>
            </div>
        </div>
        )}
        {isSubmitted && isAnswerCorrect && (<QuestWymianaJezykowaCompleted lang={props.lang}/>)}
        {isSubmitted && !isAnswerCorrect && (<QuestWymianaJezykowaFailed lang={props.lang} />)}
    </div>)
}

export default QuestWymianaJezykowa;