import React from 'react';
import './QuestMenuButton.scss'
import questInProgressIcon from '../../assets/graphics/ui/questIcon.png'
import questCompletedIcon from '../../assets/graphics/ui/questIconCompleted.png';
import questFailedIcon from '../../assets/graphics/ui/questIconFailed.png';
import locationIcon from '../../assets/graphics/ui/location.webp'
import { QuestProgress, Region } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { DrawnQuest } from '../../quests/quests';

interface QuestMenuButtonProps {
    progress: QuestProgress,
    title: string | undefined,
    location: string,
    region: Region | undefined,
    setSelectedQuest?: () => void,
}

function QuestMenuButton(props: QuestMenuButtonProps) {
    const navigate = useNavigate();
    
    return (<div className={"questMenuButtonBody quest-" + props.progress} onClick={() => {
        if (props.setSelectedQuest && props.progress === QuestProgress.inProgress) {
            props.setSelectedQuest()
            navigate('/quest');
        }
    }}>
        <div className={'questMenuButtonBodyFrame quest-' + props.region}>
            {props.progress === QuestProgress.inProgress && <img src={questInProgressIcon} className='questMenuQuestIcon' alt="quest"/>}
            {props.progress === QuestProgress.completed && <img src={questCompletedIcon} className='questMenuQuestIcon' alt="quest"/>}
            {props.progress === QuestProgress.failed && <img src={questFailedIcon} className='questMenuQuestIcon' alt="quest"/>}
            <div>
                <div className='questMenuButtonTitle'>{props.title}</div>
                <div className='questMenuButtonLocationBox'>
                    <img src={locationIcon} className='questMenuLocationIcon' alt="location"/>
                    <span className='questMenuButtonLocationText'>{props.location}</span>
                </div>
            </div>
        </div>
    </div>)
}

export default QuestMenuButton;