import { QrScanner } from '@yudiel/react-qr-scanner'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Language, checkPasswordEN, checkPasswordPL, enterPasswordEN, enterPasswordPL, qrCodeNotWorkingEN, qrCodeNotWorkingPL, reedemReward1EN, reedemReward1PL, reedemReward2EN, reedemReward2PL } from '../../constants'
import './RedeemRewardScreen.scss'
import krolik from '../../assets/graphics/emojis/krolik1.webp'
import { Button, Input } from 'antd';

interface RedeemRewardScreenProps {
    lang: Language,
    redeemReward: () => void,
    numberOfQuestsCompleted: number,
}

function RedeemRewardScreen(props: RedeemRewardScreenProps) {
  const [inputPassword, setInputPassword] = useState("")
    const navigate = useNavigate();

    return (<div className='redeemRewardBody'>
        <img src={krolik} alt="Yao yao" className="rewardEmoji"/>
        <p className='rewardText'>{props.lang === Language.PL ? reedemReward1PL : reedemReward1EN}{props.numberOfQuestsCompleted}{props.lang === Language.PL ? reedemReward2PL : reedemReward2EN}</p>
        <div className='rewardScanner'><QrScanner
          onDecode={(result) => {
            if (result === "grwd") {
                props.redeemReward()
                navigate('/questMenu')
            }
          }}
          onError={(error) => console.log(error?.message)}
      />
      <p>{props.lang === Language.PL ? qrCodeNotWorkingPL : qrCodeNotWorkingEN}</p>
            <Input size='large' placeholder={props.lang === Language.PL ? enterPasswordPL : enterPasswordEN} onChange={(e) => setInputPassword(e.target.value)} value={inputPassword}/>
            <Button size='large' disabled={inputPassword === ""} type="primary" className="genericStandQuestPasswordButton" onClick={() => {
             if (inputPassword === "grwd") {
              props.redeemReward()
              navigate('/questMenu')
          }
        }}>{props.lang === Language.PL ? checkPasswordPL : checkPasswordEN}</Button>
      </div>
    </div>)
}

export default RedeemRewardScreen