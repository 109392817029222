import React from "react"
import '../../QuestWrittenInTheStars/Summary/Summary.scss'
import { Language, questWrittenInTheStarsCorrectAnswersEN, questWrittenInTheStarsCorrectAnswersPL } from "../../../constants"
import set1Img1 from '../../../assets/graphics/quests/kwiaty/set1/flowers1.png'
import set1Img2 from '../../../assets/graphics/quests/kwiaty/set1/flowers2.png'
import set1Img3 from '../../../assets/graphics/quests/kwiaty/set1/flowers3.png'
import set2Img1 from '../../../assets/graphics/quests/kwiaty/set2/flowers1.png'
import set2Img2 from '../../../assets/graphics/quests/kwiaty/set2/flowers2.png'
import set2Img3 from '../../../assets/graphics/quests/kwiaty/set2/flowers3.png'
import { flowerSets } from "../../quests"

interface SummaryProps {
    lang: Language,
    selectedSet: number,
}

function Summary(props: SummaryProps) {
    return (<div className="questSummaryConstellationHeader">
        <p className="questSummaryCorrectAnswers">{props.lang == Language.PL ? questWrittenInTheStarsCorrectAnswersPL : questWrittenInTheStarsCorrectAnswersEN}</p>
        <div className="questSummaryConstellationBox">
            <img src={props.selectedSet === 0 ? set1Img1 : set2Img1} alt="Constellation 1" className="questSummaryConstellationImg"/>
            <div className="questSummaryConstellationTitle">{flowerSets[props.selectedSet][0].correctAnswer === 1 ? flowerSets[props.selectedSet][0].option1 : (flowerSets[props.selectedSet][0].correctAnswer === 2 ? flowerSets[props.selectedSet][0].option2 : ((flowerSets[props.selectedSet][0].correctAnswer === 3) ? flowerSets[props.selectedSet][0].option3 : flowerSets[props.selectedSet][0].option4))}</div>
        </div>
        <div className="questSummaryConstellationBox">
            <img src={props.selectedSet === 0 ? set1Img2 : set2Img2} alt="Constellation 2" className="questSummaryConstellationImg"/>
            <div className="questSummaryConstellationTitle">{flowerSets[props.selectedSet][1].correctAnswer === 1 ? flowerSets[props.selectedSet][1].option1 : (flowerSets[props.selectedSet][1].correctAnswer === 2 ? flowerSets[props.selectedSet][1].option2 : ((flowerSets[props.selectedSet][1].correctAnswer === 3) ? flowerSets[props.selectedSet][1].option3 : flowerSets[props.selectedSet][1].option4))}</div>
        </div>
        <div className="questSummaryConstellationBox">
            <img src={props.selectedSet === 0 ? set1Img3 : set2Img3} alt="Constellation 3" className="questSummaryConstellationImg"/>
            <div className="questSummaryConstellationTitle">{flowerSets[props.selectedSet][2].correctAnswer === 1 ? flowerSets[props.selectedSet][2].option1 : (flowerSets[props.selectedSet][2].correctAnswer === 2 ? flowerSets[props.selectedSet][2].option2 : ((flowerSets[props.selectedSet][2].correctAnswer === 3) ? flowerSets[props.selectedSet][2].option3 : flowerSets[props.selectedSet][2].option4))}</div>
        </div>
    </div>)
}

export default Summary

