import React from 'react'
import './QuestWymianaJezykowaFailed.scss'
import hilichurl from '../../../assets/graphics/quests/wymiana jezykowa/angry-hilichurl.webp'
import ella from '../../../assets/graphics/quests/wymiana jezykowa/ella.webp'
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { Language, questGoBackToMenuEN, questGoBackToMenuPL, wymianaJezykowaFailedEllaTextEN, wymianaJezykowaFailedEllaTextPL, wymianaJezykowaFailedHilichurlText } from '../../../constants';

interface QuestWymianaJezykowaFailedProps {
    lang: Language,
}

function QuestWymianaJezykowaFailed(props: QuestWymianaJezykowaFailedProps) {
    const navigate = useNavigate();

    return (<div className='questWymianaJezykowaFailedBody'>
        <div className='questWymianaJezykowaFailedHilichurlBox'>
            <img src={hilichurl} className="questWymianaJezykowaFailedHilichurlEmoji" alt="Hilichurl"/>
            <div className="questWymianaJezykowaFailedHilichurlSpeechBubble">
                <p className='questWymianaJezykowaFailed-speech-bubble-text'>{wymianaJezykowaFailedHilichurlText}</p>
            </div>
        </div>
        <div className='questWymianaJezykowaFailedEllaBox'>
            <div className="questWymianaJezykowaFailedEllaSpeechBubble">
                <p className='questWymianaJezykowaFailed-speech-bubble-text'>{props.lang === Language.PL ? wymianaJezykowaFailedEllaTextPL : wymianaJezykowaFailedEllaTextEN}</p>
            </div>
            <img src={ella} className="questWymianaJezykowaFailedEllaEmoji" alt="Ella"/>
        </div>
        <Button size='large'
            type="primary"
            onClick={() => navigate('/questMenu')}>{props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button>
    </div>)
}

export default QuestWymianaJezykowaFailed;