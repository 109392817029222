import React from 'react';
import MemberPhoto from './MemberPhoto/MemberPhoto';
import './MembersGallery.scss'

function MembersGallery() {
    return (<div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='amai'/>
            <MemberPhoto name='fuki'/>
            <MemberPhoto name='gin'/>
        </div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='gloomy'/>
            <MemberPhoto name='grazka'/>
            <MemberPhoto name='gumis'/>
        </div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='kaho'/>
            <MemberPhoto name='karo'/>
            <MemberPhoto name='kartofelek'/>
        </div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='lin'/>
            <MemberPhoto name='missmorrow'/>
            <MemberPhoto name='natsu'/>
        </div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='nazar'/>
            <MemberPhoto name='potterka'/>
            <MemberPhoto name='tonayo'/>
        </div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='vivid'/>
            <MemberPhoto name='wero'/>
            <MemberPhoto name='xell'/>
        </div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='yoshi'/>
            <MemberPhoto name='yume'/>
            <MemberPhoto name='zannah'/>
        </div>
    </div>)
}

export default MembersGallery;