import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { Language, questJanKenPonNextEN, questJanKenPonNextPL, questJanKenPonNextRoundEN, questJanKenPonNextRoundPL } from '../../../constants';
import { JanKenPonSymbol, rockPaperScissors } from '../../quests';
import QuestJanKenPonScoreBoard from '../QuestJanKenPonScoreBoard/QuestJanKenPonScoreBoard';
import JanKenPonChooseSymbolBoard from './JanKenPonChooseSymbolBoard/JanKenPonChooseSymbolBoard';
import JanKenPonPaimonBoard from './JanKenPonPaimonBoard/JanKenPonPaimonBoard';
import './QuestJanKenPonGame.scss'

interface QuestJanKenPonGameProps {
    lang: Language,
    paimonScore: number,
    playerScore: number,
    setPaimonScore: (score: number) => void,
    setPlayerScore: (score: number) => void,
    setIsGameFinished: (isFinished: boolean) => void,
}

function QuestJanKenPonGame(props: QuestJanKenPonGameProps) {
    const [selectedSymbol, setSelectedSymbol] = useState<JanKenPonSymbol | undefined>(undefined);
    const [paimonSymbol, setPaimonSymbol] = useState<JanKenPonSymbol | undefined>(undefined);
    const [isPaimonWinning, setIsPaimonWinning] = useState(false);

    useEffect(() => {
        if (selectedSymbol !== undefined) {
            const symbols = [JanKenPonSymbol.paper, JanKenPonSymbol.rock, JanKenPonSymbol.scissors]
            const randomPaimonSymbol = symbols[Math.floor(Math.random() * symbols.length)];
            setPaimonSymbol(randomPaimonSymbol);
            const didPlayerWin = rockPaperScissors(selectedSymbol, randomPaimonSymbol);
            setIsPaimonWinning(didPlayerWin === 0)
            if (didPlayerWin === 1) {
                props.setPlayerScore(props.playerScore + 1)
            }
            if (didPlayerWin === 0) {
                props.setPaimonScore(props.paimonScore + 1)
            }
        }
    }, [selectedSymbol])

    return (<div className='questJanKenPonBody'>
        <QuestJanKenPonScoreBoard lang={props.lang} paimonScore={props.paimonScore} playerScore={props.playerScore} />
        <JanKenPonChooseSymbolBoard selectedSymbol={selectedSymbol} setSelectedSymbol={setSelectedSymbol} lang={props.lang} />
        <JanKenPonPaimonBoard paimonSymbol={paimonSymbol} isWinning={isPaimonWinning} />
        {(props.paimonScore > props.playerScore && props.paimonScore >= 3) || (props.playerScore > props.paimonScore && props.playerScore >= 3) 
        ? (<Button size='large' disabled={selectedSymbol === undefined} type="primary" className='questJanKenPonButtonBox' onClick={() => {
            props.setIsGameFinished(true)
        }}>{props.lang === Language.PL ? questJanKenPonNextPL : questJanKenPonNextEN}</Button>) 
        : (<Button size='large' disabled={selectedSymbol === undefined} type="primary" className='questJanKenPonButtonBox' onClick={() => {
            setSelectedSymbol(undefined);
            setPaimonSymbol(undefined);
        }}>{props.lang === Language.PL ? questJanKenPonNextRoundPL : questJanKenPonNextRoundEN}</Button>)
         }
    </div>)
}

export default QuestJanKenPonGame;