import React from 'react';
import { Language, questJanKenPonScoreEN, questJanKenPonScorePL } from '../../../constants';
import './QuestJanKenPonScoreBoard.scss';
import player from '../../../assets/graphics/quests/jankenpon/player-score.webp'
import paimon from '../../../assets/graphics/quests/jankenpon/paimon-score.webp'

interface QuestJanKenPonScoreBoardProps {
    lang: Language,
    paimonScore: number,
    playerScore: number
}

function QuestJanKenPonScoreBoard(props: QuestJanKenPonScoreBoardProps) {
    return (<div className='questJanKenPonScoreBoardBody'>
        <div className='questJanKenPonScoreBoardInnerBody'>
            <img src={player} alt="Traveler" className='questJanKenPonScoreBoardEmoji'/>
            <div className='questJanKenPonScoreBoardScoreBox'>
                <div>{props.lang === Language.PL ? questJanKenPonScorePL : questJanKenPonScoreEN}</div>
                <div className='questJanKenPonScoreBoardScoreNumbersBox'>
                    <div className='questJanKenPonScoreBoardScoreNumber'>{props.playerScore}</div>
                    <div>:</div>
                    <div className='questJanKenPonScoreBoardScoreNumber'>{props.paimonScore}</div>
                </div>
            </div>
            <img src={paimon} alt="Paimon" className='questJanKenPonScoreBoardEmoji'/>
        </div>
    </div>)
}

export default QuestJanKenPonScoreBoard;