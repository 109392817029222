import { Button, Radio, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Language, poem1answer1part1EN, poem1answer1part1PL, poem1answer1part2EN, poem1answer1part2PL, poem1answer2part1EN, poem1answer2part1PL, poem1answer2part2EN, poem1answer2part2PL, poem1answer3part1EN, poem1answer3part1PL, poem1answer3part2EN, poem1answer3part2PL, poem1correctAnswer, poem1verse1EN, poem1verse1PL, poem1verse2EN, poem1verse2PL, poem1verse3EN, poem1verse3PL, poem1verse4EN, poem1verse4PL, questMistrzSubmitAnswerEN, questMistrzSubmitAnswerPL, QuestProgress } from '../../../../../constants';
import FillGap from '../../FillGap/FillGap';
import VentiCompleted from '../../../results/VentiCompleted/VentiCompleted';
import VentiFailed from '../../../results/VentiFailed/VentiFailed';

interface Poem1Props {
    lang: Language,
    setQuestProgress: (progress: QuestProgress) => void,
}

function Poem1(props: Poem1Props) {
    const [selectedAnswer, setSelectedAnswer] = useState<undefined | number>(undefined)
    const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false)
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(false)

    useEffect(() => {
        if (selectedAnswer !== undefined) {
            setIsAnswerCorrect(selectedAnswer === poem1correctAnswer)
            props.setQuestProgress(selectedAnswer === poem1correctAnswer ? QuestProgress.completed : QuestProgress.failed)
        }
    }, [isAnswerSubmitted])

    return (<div className='poemBody'>
        <div className='poem'>
            <p className='poemVerse'>{props.lang === Language.PL ? poem1verse1PL : poem1verse1EN}</p>
            <p className='poemVerse'>{props.lang === Language.PL ? poem1verse2PL : poem1verse2EN}</p>
            <p className='poemVerse'>{props.lang === Language.PL ? poem1verse3PL : poem1verse3EN}</p>
            <p className='poemVerse'>{props.lang === Language.PL ? poem1verse4PL : poem1verse4EN}</p>
            {!isAnswerSubmitted && <p className='poemVerse'>...</p>}
            {isAnswerSubmitted && selectedAnswer === 1 && <p className='poemVerse poemIncorrect'>{props.lang === Language.PL ? poem1answer1part1PL : poem1answer1part1EN}</p>}
            {isAnswerSubmitted && selectedAnswer === 1 && <p className='poemVerse poemIncorrect'>{props.lang === Language.PL ? poem1answer1part2PL : poem1answer1part2EN}</p>}
            {isAnswerSubmitted && selectedAnswer === 2 && <p className='poemVerse poemCorrect'>{props.lang === Language.PL ? poem1answer2part1PL : poem1answer2part1EN}</p>}
            {isAnswerSubmitted && selectedAnswer === 2 && <p className='poemVerse poemCorrect'>{props.lang === Language.PL ? poem1answer2part2PL : poem1answer2part2EN}</p>}
            {isAnswerSubmitted && selectedAnswer === 3 && <p className='poemVerse poemIncorrect'>{props.lang === Language.PL ? poem1answer3part1PL : poem1answer3part1EN}</p>}
            {isAnswerSubmitted && selectedAnswer === 3 && <p className='poemVerse poemIncorrect'>{props.lang === Language.PL ? poem1answer3part2PL : poem1answer3part2EN}</p>}
        </div>
        {!isAnswerSubmitted && <FillGap lang={props.lang} />}
        {!isAnswerSubmitted && <Radio.Group onChange={(e) => setSelectedAnswer(e.target.value)} value={selectedAnswer} size='large' className='poemAnswers'>
            <Space direction="vertical">
                <Radio value={1}>
                    <div className="poemAnswerVerses">
                        <div className='poemVerse'>{props.lang === Language.PL ? poem1answer1part1PL : poem1answer1part1EN}</div>
                        <div className='poemVerse'>{props.lang === Language.PL ? poem1answer1part2PL : poem1answer1part2EN}</div>
                    </div></Radio>
                <Radio value={2}>
                    <div className="poemAnswerVerses">
                        <div className='poemVerse'>{props.lang === Language.PL ? poem1answer2part1PL : poem1answer2part1EN}</div>
                        <div className='poemVerse'>{props.lang === Language.PL ? poem1answer2part2PL : poem1answer2part2EN}</div>
                    </div></Radio>
                <Radio value={3}>
                    <div className="poemAnswerVerses">
                        <div className='poemVerse'>{props.lang === Language.PL ? poem1answer3part1PL : poem1answer3part1EN}</div>
                        <div className='poemVerse'>{props.lang === Language.PL ? poem1answer3part2PL : poem1answer3part2EN}</div>
                    </div></Radio>
            </Space>
        </Radio.Group>}
        {!isAnswerSubmitted && <Button size='large'
            type="primary"
            disabled={selectedAnswer === undefined}
            onClick={() => setIsAnswerSubmitted(true)}>
            {props.lang === Language.PL ? questMistrzSubmitAnswerPL : questMistrzSubmitAnswerEN}</Button>}
        {isAnswerSubmitted && isAnswerCorrect && <VentiCompleted lang={props.lang}/>}
        {isAnswerSubmitted && !isAnswerCorrect && <VentiFailed lang={props.lang}
            correctAnswerPart1={props.lang === Language.PL ? poem1answer2part1PL : poem1answer2part1EN}
            correctAnswerPart2={props.lang === Language.PL ? poem1answer2part2PL : poem1answer2part2EN} />}
    </div>)
}

export default Poem1;