import React, { useEffect, useState } from 'react';
import QuestMenuButton from '../../components/quest-menu-button/QuestMenuButton';
import { Language, mobileEN, mobilePL, questGoBackToMenuEN, questGoBackToMenuPL, questKwiatyDowodMilosciCompletedEN, questKwiatyDowodMilosciCompletedPL, questKwiatyDowodMilosciFailedEN, questKwiatyDowodMilosciFailedPL, QuestProgress } from '../../constants';
import { quests } from '../quests';
import './QuestKwiatyDowodMilosci.scss'
import naviaCompleted from '../../assets/graphics/quests/kwiaty/naviaCompleted.webp'
import naviaFailed from '../../assets/graphics/quests/kwiaty/naviaFailed.webp'
import QuestKwiatyInfo from './QuestKwiatyInfo/QuestKwiatyInfo';
import KwiatyGuessingScreen from './KwiatyGuessingScreen/KwiatyGuessingScreen';
import { Button } from 'antd';
import Summary from './Summary/Summary';
import { useNavigate } from 'react-router-dom';

interface QuestKwiatyDowodMilosciProps {
    lang: Language,
    questProgress: QuestProgress,
    setQuestProgress: (progress: QuestProgress) => void,
}

function QuestKwiatyDowodMilosci(props: QuestKwiatyDowodMilosciProps) {
    const quest = quests.get(11);
    const [isInfoVisible, setInfoVisibility] = useState(true)
    const [selectedSetNumber, setSelectedSetNumber] = useState<number>()
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedSetNumber(Math.floor(Math.random() * 2))
    }, [])

    return (<div className='questSzkolenieGrzybiarzaBody'>
        <QuestMenuButton
            location={props.lang === Language.PL ? mobilePL : mobileEN}
            progress={props.questProgress}
            region={quest?.region}
            title={props.lang === Language.PL ? quest?.namePL : quest?.nameEN} />
        {props.questProgress === QuestProgress.inProgress &&
            (isInfoVisible ? (<QuestKwiatyInfo lang={props.lang} closeInfo={() => setInfoVisibility(false)}/>) : (<KwiatyGuessingScreen lang={props.lang} selectedSetNumber={selectedSetNumber || 0} setQuestProgress={props.setQuestProgress}/>))}
        {props.questProgress === QuestProgress.completed &&
            (<div className='questKwiatyDowodMilosciSpeechBubbleBox'>
                <div className="questKwiatyDowodMilosciSpeechBubble">
                    <p className='questKwiatyDowodMilosciSpeechBubbleText'>
                        {props.lang === Language.PL ? questKwiatyDowodMilosciCompletedPL : questKwiatyDowodMilosciCompletedEN}
                    </p>
                </div>
                <img src={naviaCompleted} alt="Navia" className='questKwiatyDowodMilosciEmoji' />
            </div>
            )}
        {props.questProgress === QuestProgress.failed &&
            (<div className='questKwiatyDowodMilosciSpeechBubbleBox'>
                <div className="questKwiatyDowodMilosciSpeechBubble">
                    <p className='questKwiatyDowodMilosciSpeechBubbleText'>
                        {props.lang === Language.PL ? questKwiatyDowodMilosciFailedPL : questKwiatyDowodMilosciFailedEN}
                    </p>
                </div>
                <img src={naviaFailed} alt="Navia" className='questKwiatyDowodMilosciEmoji' />
            </div>
            )}
        {(props.questProgress === QuestProgress.completed || props.questProgress === QuestProgress.failed) && <Button size='large' type="primary"
            className="genericStandQuestPasswordButton" onClick={() => navigate('/questMenu')}>{props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button>}
        {(props.questProgress === QuestProgress.completed || props.questProgress === QuestProgress.failed) && <Summary lang={props.lang} selectedSet={selectedSetNumber || 0} />}

    </div>)
}

export default QuestKwiatyDowodMilosci;