import React, { useEffect, useState } from 'react'
import QuestMenuButton from '../../components/quest-menu-button/QuestMenuButton';
import { Language, mobileEN, mobilePL, questGoBackToMenuEN, questGoBackToMenuPL, questKuCzciFailedEN, questKuCzciFailedPL, QuestProgress } from '../../constants'
import { getSyllableCountEN, getSyllableCountPL, quests } from '../quests';
import Editor from './Editor/Editor';
import Info from './Info/Info';
import Preview from './Preview/Preview';
import './QuestKuCzciPieciuKasen.scss'
import raiden from '../../assets/graphics/quests/kuczcipieciukasen/raidenFailed.webp'
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

interface QuestKuCzciPieciuKasenProps {
    lang: Language,
    questProgress: QuestProgress,
    setQuestProgress: (progress: QuestProgress) => void,
}

function QuestKuCzciPieciuKasen(props: QuestKuCzciPieciuKasenProps) {
    const quest = quests.get(7);
    const [isInfoVisible, setInfoVisibility] = useState(true);
    const [isEditorVisible, setEditorVisibility] = useState(false);
    const [poem, setPoem] = useState<undefined | string>(undefined)
    const [isPreviewVisible, setIsPreviewVisible] = useState(false)
    const [syllablesCount, setSyllablesCount] = useState([0, 0, 0])
    const navigate = useNavigate();

    useEffect(() => {
        if (poem !== undefined && !isEditorVisible) {
            const count = props.lang === Language.PL ? getSyllableCountPL(poem) : getSyllableCountEN(poem)
            setSyllablesCount(count);
            if (count[0] === 5 && count[1] === 7 && count[2] === 5) {
                props.setQuestProgress(QuestProgress.completed)
            }
        }
    }, [isEditorVisible, poem])

    return (<div className='questKuCzciBody'>
        <QuestMenuButton
            location={props.lang === Language.PL ? mobilePL : mobileEN}
            progress={props.questProgress}
            region={quest?.region}
            title={props.lang === Language.PL ? quest?.namePL : quest?.nameEN} />
        {isInfoVisible && props.questProgress !== QuestProgress.failed && <Info lang={props.lang} setInfoVisibility={() => {
            setInfoVisibility(false)
            setEditorVisibility(true)
        }} />}
        {isEditorVisible && props.questProgress !== QuestProgress.failed && <Editor lang={props.lang} poem={poem} setPoem={setPoem} openPreview={() => {
            setIsPreviewVisible(true)
            setEditorVisibility(false)
        }} />}
        {isPreviewVisible && props.questProgress !== QuestProgress.failed && <Preview lang={props.lang} giveUp={() => props.setQuestProgress(QuestProgress.failed)} poem={poem ? poem : ''} syllablesCount={syllablesCount} goBackToEditing={() => {
            setEditorVisibility(true)
            setIsPreviewVisible(false)
        }} />}
        {props.questProgress === QuestProgress.failed && <div className='questKuCzciFailedBody'>
            <img src={raiden} alt="Raiden" className="questKuCzciEmoji" />
            <p className='questKuCzciFailedText'>{props.lang === Language.PL ? questKuCzciFailedPL : questKuCzciFailedEN}</p>
            <Button
                size='large'
                type="primary"
                onClick={() => navigate('/questMenu')}>{props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button>
        </div>}
    </div>)
}

export default QuestKuCzciPieciuKasen;