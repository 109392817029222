import React from 'react';
import { Language, questGoBackToMenuEN, questGoBackToMenuPL, questJanKenPonCompletedTextEN, questJanKenPonCompletedTextPL } from '../../../../../constants';
import QuestJanKenPonScoreBoard from '../../../QuestJanKenPonScoreBoard/QuestJanKenPonScoreBoard';
import './QuestJanKenPonCompleted.scss';
import paimon from '../../../../../assets/graphics/quests/jankenpon/paimon-lost.png'
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

interface QuestJanKenPonCompletedProps {
    lang: Language,
    paimonScore: number,
    playerScore: number,
}

function QuestJanKenPonCompleted(props: QuestJanKenPonCompletedProps) {
    const navigate = useNavigate();
    
    return (<div className='questJanKenPonCompletedBody'>
        <QuestJanKenPonScoreBoard paimonScore={props.paimonScore} playerScore={props.playerScore} lang={props.lang} />
        <img src={paimon} alt="Paimon" className='questJanKenPonCompletedEmoji'/>
        <p className='questJanKenPonCompletedText'>{props.lang === Language.PL ? questJanKenPonCompletedTextPL : questJanKenPonCompletedTextEN}</p>
        <Button size='large' type="primary" onClick={() => navigate('/questMenu')}>
            {props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button>
    </div>)
}

export default QuestJanKenPonCompleted;