import React, { useEffect, useState } from 'react';
import QuestMenuButton from '../../components/quest-menu-button/QuestMenuButton';
import { Language, mobileEN, mobilePL, QuestProgress, questSzkolenieGrzybiarzaCompletedEN, questSzkolenieGrzybiarzaCompletedPL, questSzkolenieGrzybiarzaConfirmEN, questSzkolenieGrzybiarzaConfirmPL, questSzkolenieGrzybiarzaFailedEN, questSzkolenieGrzybiarzaFailedPL, questSzkolenieGrzybiarzaInfoTextEN, questSzkolenieGrzybiarzaInfoTextPL } from '../../constants';
import { quests, szkolenieGrzybiarzaAnswers } from '../quests';
import './QuestSzkolenieGrzybiarza.scss'
import tighnariInfo from '../../assets/graphics/quests/szkoleniegrzybiarza/tighnariInfo.webp'
import tighnariCompleted from '../../assets/graphics/quests/szkoleniegrzybiarza/tighnariCompleted.webp'
import tighnariFailed from '../../assets/graphics/quests/szkoleniegrzybiarza/tighnariFailed.webp'
import QuestSzkolenieGrzybiarzaMushroomSet from './QuestSzkolenieGrzybiarzaMushroomSet/QuestSzkolenieGrzybiarzaMushroomSet';

interface QuestSzkolenieGrzybiarzaProps {
    lang: Language,
    questProgress: QuestProgress,
    setQuestProgress: (progress: QuestProgress) => void,
}

function QuestSzkolenieGrzybiarza(props: QuestSzkolenieGrzybiarzaProps) {
    const quest = quests.get(3);
    const [selectedAnswer, setSelectedAnswer] = useState<number | undefined>(undefined)
    const [selectedSet, setSelectedSet] = useState(Math.floor(Math.random() * 3) + 1);

    useEffect(() => {
        if (selectedAnswer !== undefined) {
            if (selectedAnswer === szkolenieGrzybiarzaAnswers.get(selectedSet)) {
                props.setQuestProgress(QuestProgress.completed)
            } else {
                props.setQuestProgress(QuestProgress.failed)
            }
        }
    }, [selectedAnswer])

    return (<div className='questSzkolenieGrzybiarzaBody'>
        <QuestMenuButton
            location={props.lang === Language.PL ? mobilePL : mobileEN}
            progress={props.questProgress}
            region={quest?.region}
            title={props.lang === Language.PL ? quest?.namePL : quest?.nameEN} />
        {props.questProgress === QuestProgress.inProgress &&
            (<div className='questSzkolenieGrzybiarzaSpeechBubbleBox'>
                <div className="questSzkolenieGrzybiarzaSpeechBubble">
                    <p className='questSzkolenieGrzybiarzaSpeechBubbleText'>
                        {props.lang === Language.PL ? questSzkolenieGrzybiarzaInfoTextPL : questSzkolenieGrzybiarzaInfoTextEN}
                    </p>
                </div>
                <img src={tighnariInfo} alt="Tighnari" className='questSzkolenieGrzybiarzaEmoji' />
            </div>
            )}
        {props.questProgress === QuestProgress.completed &&
            (<div className='questSzkolenieGrzybiarzaSpeechBubbleBox'>
                <div className="questSzkolenieGrzybiarzaSpeechBubble">
                    <p className='questSzkolenieGrzybiarzaSpeechBubbleText'>
                        {props.lang === Language.PL ? questSzkolenieGrzybiarzaCompletedPL : questSzkolenieGrzybiarzaCompletedEN}
                    </p>
                </div>
                <img src={tighnariCompleted} alt="Tighnari" className='questSzkolenieGrzybiarzaEmoji' />
            </div>
            )}
        {props.questProgress === QuestProgress.failed &&
            (<div className='questSzkolenieGrzybiarzaSpeechBubbleBox'>
                <div className="questSzkolenieGrzybiarzaSpeechBubble">
                    <p className='questSzkolenieGrzybiarzaSpeechBubbleText'>
                        {props.lang === Language.PL ? questSzkolenieGrzybiarzaFailedPL : questSzkolenieGrzybiarzaFailedEN}
                    </p>
                </div>
                <img src={tighnariFailed} alt="Tighnari" className='questSzkolenieGrzybiarzaEmoji' />
            </div>
            )}
        <QuestSzkolenieGrzybiarzaMushroomSet
            selectedAnswer={selectedAnswer}
            setSelectedAnswer={setSelectedAnswer}
            selectedSet={selectedSet}
            correctAnswer={szkolenieGrzybiarzaAnswers.get(selectedSet)}
            lang={props.lang} />
    </div>)
}

export default QuestSzkolenieGrzybiarza;