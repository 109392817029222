import React, { useState } from 'react';
import PLFlag from '../../assets/graphics/ui/polishFlag.webp';
import ENFlag from '../../assets/graphics/ui/englishFlag.webp';
import './LanguageScreen.scss';
import { Language, languageChangeAlertTextEN, languageChangeAlertTextPL, selectLanguageTextEN, selectLanguageTextPL } from '../../constants';
import { Alert } from 'antd';

interface LanguageScreenProps {
    lang: Language,
    selectLanguage: (lang: Language) => void,
}

function LanguageScreen(props: LanguageScreenProps) {
    const [isAlertVisible, changeAlertVisibility] = useState(false);

    const showAlert = () => {
        changeAlertVisibility(true);
        setTimeout(() => changeAlertVisibility(false), 2000)
    }

    return (<div className='languageBody'>
        {isAlertVisible && <Alert showIcon message={props.lang === Language.PL ? languageChangeAlertTextPL : languageChangeAlertTextEN} type="success" className='languageChangeAlert' />}
        <div className='languageText'>{props.lang === Language.PL ? selectLanguageTextPL : selectLanguageTextEN}</div>
        <div className='languageFlags'>
            <img src={PLFlag} alt="PL" className={props.lang === Language.PL ? 'flag selectedLanguageFlag' : 'flag'} onClick={() => {
                props.selectLanguage(Language.PL);
                showAlert();
            }} />
            <img src={ENFlag} alt="EN" className={props.lang === Language.EN ? 'flag selectedLanguageFlag' : 'flag'} onClick={() => {
                props.selectLanguage(Language.EN);
                showAlert();
            }} />
        </div>
    </div>)
}

export default LanguageScreen;