import React, { useState } from 'react';
import './QuestSzkolenieGrzybiarzaMushroomSet.scss';
import set1Img1 from '../../../assets/graphics/quests/szkoleniegrzybiarza/set1/1.jpg'
import set1Img2 from '../../../assets/graphics/quests/szkoleniegrzybiarza/set1/2.jpg'
import set1Img3 from '../../../assets/graphics/quests/szkoleniegrzybiarza/set1/3.jpg'
import set1Img4 from '../../../assets/graphics/quests/szkoleniegrzybiarza/set1/4.jpg'
import set2Img1 from '../../../assets/graphics/quests/szkoleniegrzybiarza/set2/1.jpg'
import set2Img2 from '../../../assets/graphics/quests/szkoleniegrzybiarza/set2/2.jpg'
import set2Img3 from '../../../assets/graphics/quests/szkoleniegrzybiarza/set2/3.jpg'
import set2Img4 from '../../../assets/graphics/quests/szkoleniegrzybiarza/set2/4.jpg'
import set3Img1 from '../../../assets/graphics/quests/szkoleniegrzybiarza/set3/1.jpg'
import set3Img2 from '../../../assets/graphics/quests/szkoleniegrzybiarza/set3/2.jpg'
import set3Img3 from '../../../assets/graphics/quests/szkoleniegrzybiarza/set3/3.jpg'
import set3Img4 from '../../../assets/graphics/quests/szkoleniegrzybiarza/set3/4.jpg'
import { Language, questGoBackToMenuEN, questGoBackToMenuPL, questSzkolenieGrzybiarzaConfirmEN, questSzkolenieGrzybiarzaConfirmPL } from '../../../constants';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

interface QuestSzkolenieGrzybiarzaMushroomSetProps {
    selectedSet: number,
    selectedAnswer: number | undefined,
    setSelectedAnswer: (answer: number | undefined) => void,
    correctAnswer: number | undefined,
    lang: Language,
}

function QuestSzkolenieGrzybiarzaMushroomSet(props: QuestSzkolenieGrzybiarzaMushroomSetProps) {
    const [answer, setAnswer] = useState<number | undefined>(undefined)
    const navigate = useNavigate();

    function mushroomOnClick(mushroomNumber: number): void {
        if (!props.selectedAnswer) {
            setAnswer(mushroomNumber);
        }
    }

    return (<div className='questSzkolenieGrzybiarzaMushroomBody'>
        {props.selectedSet === 1 && <div className='questSzkolenieGrzybiarzaMushroomImgBox'>
            <img src={set1Img1} alt="answer1" className={'questSzkolenieGrzybiarzaMushroomImg' + (!props.selectedAnswer ? ' selectable' : '') + (answer === 1 ? ' selected' : '') + ((props.selectedAnswer && props.correctAnswer === 1) ? ' correct' : '') + ((props.selectedAnswer && props.selectedAnswer === 1 && props.selectedAnswer !== props.correctAnswer) ? ' incorrect' : '')} onClick={() => mushroomOnClick(1)} />
            <img src={set1Img2} alt="answer2" className={'questSzkolenieGrzybiarzaMushroomImg' + (!props.selectedAnswer ? ' selectable' : '') + (answer === 2 ? ' selected' : '') + ((props.selectedAnswer && props.correctAnswer === 2) ? ' correct' : '') + ((props.selectedAnswer && props.selectedAnswer === 2 && props.selectedAnswer !== props.correctAnswer) ? ' incorrect' : '')} onClick={() => mushroomOnClick(2)} />
            <img src={set1Img3} alt="answer3" className={'questSzkolenieGrzybiarzaMushroomImg' + (!props.selectedAnswer ? ' selectable' : '') + (answer === 3 ? ' selected' : '') + ((props.selectedAnswer && props.correctAnswer === 3) ? ' correct' : '') + ((props.selectedAnswer && props.selectedAnswer === 3 && props.selectedAnswer !== props.correctAnswer) ? ' incorrect' : '')} onClick={() => mushroomOnClick(3)} />
            <img src={set1Img4} alt="answer4" className={'questSzkolenieGrzybiarzaMushroomImg' + (!props.selectedAnswer ? ' selectable' : '') + (answer === 4 ? ' selected' : '') + ((props.selectedAnswer && props.correctAnswer === 4) ? ' correct' : '') + ((props.selectedAnswer && props.selectedAnswer === 4 && props.selectedAnswer !== props.correctAnswer) ? ' incorrect' : '')} onClick={() => mushroomOnClick(4)} />
        </div>}
        {props.selectedSet === 2 && <div className='questSzkolenieGrzybiarzaMushroomImgBox'>
            <img src={set2Img1} alt="answer1" className={'questSzkolenieGrzybiarzaMushroomImg' + (!props.selectedAnswer ? ' selectable' : '') + (answer === 1 ? ' selected' : '') + ((props.selectedAnswer && props.correctAnswer === 1) ? ' correct' : '') + ((props.selectedAnswer && props.selectedAnswer === 1 && props.selectedAnswer !== props.correctAnswer) ? ' incorrect' : '')} onClick={() => mushroomOnClick(1)} />
            <img src={set2Img2} alt="answer2" className={'questSzkolenieGrzybiarzaMushroomImg' + (!props.selectedAnswer ? ' selectable' : '') + (answer === 2 ? ' selected' : '') + ((props.selectedAnswer && props.correctAnswer === 2) ? ' correct' : '') + ((props.selectedAnswer && props.selectedAnswer === 2 && props.selectedAnswer !== props.correctAnswer) ? ' incorrect' : '')} onClick={() => mushroomOnClick(2)} />
            <img src={set2Img3} alt="answer3" className={'questSzkolenieGrzybiarzaMushroomImg' + (!props.selectedAnswer ? ' selectable' : '') + (answer === 3 ? ' selected' : '') + ((props.selectedAnswer && props.correctAnswer === 3) ? ' correct' : '') + ((props.selectedAnswer && props.selectedAnswer === 3 && props.selectedAnswer !== props.correctAnswer) ? ' incorrect' : '')} onClick={() => mushroomOnClick(3)} />
            <img src={set2Img4} alt="answer4" className={'questSzkolenieGrzybiarzaMushroomImg' + (!props.selectedAnswer ? ' selectable' : '') + (answer === 4 ? ' selected' : '') + ((props.selectedAnswer && props.correctAnswer === 4) ? ' correct' : '') + ((props.selectedAnswer && props.selectedAnswer === 4 && props.selectedAnswer !== props.correctAnswer) ? ' incorrect' : '')} onClick={() => mushroomOnClick(4)} />
        </div>}
        {props.selectedSet === 3 && <div className='questSzkolenieGrzybiarzaMushroomImgBox'>
            <img src={set3Img1} alt="answer1" className={'questSzkolenieGrzybiarzaMushroomImg' + (!props.selectedAnswer ? ' selectable' : '') + (answer === 1 ? ' selected' : '') + ((props.selectedAnswer && props.correctAnswer === 1) ? ' correct' : '') + ((props.selectedAnswer && props.selectedAnswer === 1 && props.selectedAnswer !== props.correctAnswer) ? ' incorrect' : '')} onClick={() => mushroomOnClick(1)} />
            <img src={set3Img2} alt="answer2" className={'questSzkolenieGrzybiarzaMushroomImg' + (!props.selectedAnswer ? ' selectable' : '') + (answer === 2 ? ' selected' : '') + ((props.selectedAnswer && props.correctAnswer === 2) ? ' correct' : '') + ((props.selectedAnswer && props.selectedAnswer === 2 && props.selectedAnswer !== props.correctAnswer) ? ' incorrect' : '')} onClick={() => mushroomOnClick(2)} />
            <img src={set3Img3} alt="answer3" className={'questSzkolenieGrzybiarzaMushroomImg' + (!props.selectedAnswer ? ' selectable' : '') + (answer === 3 ? ' selected' : '') + ((props.selectedAnswer && props.correctAnswer === 3) ? ' correct' : '') + ((props.selectedAnswer && props.selectedAnswer === 3 && props.selectedAnswer !== props.correctAnswer) ? ' incorrect' : '')} onClick={() => mushroomOnClick(3)} />
            <img src={set3Img4} alt="answer4" className={'questSzkolenieGrzybiarzaMushroomImg' + (!props.selectedAnswer ? ' selectable' : '') + (answer === 4 ? ' selected' : '') + ((props.selectedAnswer && props.correctAnswer === 4) ? ' correct' : '') + ((props.selectedAnswer && props.selectedAnswer === 4 && props.selectedAnswer !== props.correctAnswer) ? ' incorrect' : '')} onClick={() => mushroomOnClick(4)} />
        </div>}
        {!props.selectedAnswer && <Button
            size='large'
            type="primary"
            className="questSzkolenieGrzybiarzaConfirmButton"
            onClick={() => props.setSelectedAnswer(answer)}
            disabled={answer === undefined}>{props.lang === Language.PL ? questSzkolenieGrzybiarzaConfirmPL : questSzkolenieGrzybiarzaConfirmEN}</Button>}
        {props.selectedAnswer && <Button
            size='large'
            type="primary"
            className="questSzkolenieGrzybiarzaConfirmButton"
            onClick={() => navigate('/questMenu')}>{props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button>}
    </div>)
}

export default QuestSzkolenieGrzybiarzaMushroomSet;