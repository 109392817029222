import React, { useEffect, useState } from 'react'
import QuestMenuButton from '../../components/quest-menu-button/QuestMenuButton';
import { Language, mobileEN, mobilePL, QuestProgress } from '../../constants';
import { quests } from '../quests';
import './QuestJanKenPon.scss'
import QuestJanKenPonGame from './QuestJanKenPonGame/QuestJanKenPonGame';
import QuestJanKenPonCompleted from './QuestJanKenPonGame/QuestJanKenPonResult/QuestJanKenPonCompleted/QuestJanKenPonCompleted';
import QuestJanKenPonFailed from './QuestJanKenPonGame/QuestJanKenPonResult/QuestJanKenPonFailed/QuestJanKenPonFailed';
import QuestJanKenPonInstructions from './QuestJanKenPonInstructions/QuestJanKenPonInstructions';
import QuestJanKenPonScoreBoard from './QuestJanKenPonScoreBoard/QuestJanKenPonScoreBoard';

interface QuestJanKenPonProps {
    lang: Language,
    questProgress: QuestProgress,
    setQuestProgress: (progress: QuestProgress) => void,
}

function QuestJanKenPon(props: QuestJanKenPonProps) {
    const quest = quests.get(2);
    const [isInstructionVisible, setIsInstructionVisible] = useState(true);
    const [paimonScore, setPaimonScore] = useState(0)
    const [playerScore, setPlayerScore] = useState(0)
    const [isGameFinished, setIsGameFinished] = useState(false);

    useEffect(() => {
        if (isGameFinished) {
            if (paimonScore > playerScore && paimonScore >= 3) {
                props.setQuestProgress(QuestProgress.failed)
            } else {
                props.setQuestProgress(QuestProgress.completed)
            }
        }
    }, [isGameFinished])

    return (<div className='questJanKenPonBody'>
        <QuestMenuButton
            location={props.lang === Language.PL ? mobilePL : mobileEN}
            progress={props.questProgress}
            region={quest?.region}
            title={props.lang === Language.PL ? quest?.namePL : quest?.nameEN} />
        {isInstructionVisible && <QuestJanKenPonInstructions lang={props.lang} setIsInstructionVisible={setIsInstructionVisible} />}
        {!isInstructionVisible && !isGameFinished &&
            <QuestJanKenPonGame
                lang={props.lang}
                paimonScore={paimonScore}
                setPaimonScore={setPaimonScore}
                playerScore={playerScore}
                setPlayerScore={setPlayerScore}
                setIsGameFinished={setIsGameFinished} />}
                {isGameFinished && (paimonScore > playerScore && paimonScore >= 3) && <QuestJanKenPonFailed lang={props.lang} playerScore={playerScore} paimonScore={paimonScore} />}
                {isGameFinished && (playerScore > paimonScore && playerScore >= 3) && <QuestJanKenPonCompleted lang={props.lang} playerScore={playerScore} paimonScore={paimonScore}/>}

    </div>)
}

export default QuestJanKenPon;