import React from 'react';
import { Steps } from 'antd';
import './CalendarScreen.scss'
import kaveh from '../../assets/graphics/emojis/kaveh1.webp';
import { calendarTextEN, calendarTextPL, Language } from '../../constants';

interface CalendarProps {
    lang: Language,
}

function CalendarScreen(props: CalendarProps) {
    const { Step } = Steps;
    return (<div className='calendarScreenBody'>
        <div className='calendarHeader'>{props.lang === Language.PL ? calendarTextPL : calendarTextEN}</div>
        <Steps
            progressDot
            current={4}
            className="calendarSteps"
            style={{ fontFamily: 'Lato' }}
        >
            <Step
                title={
                    <a href='https://www.genshin-meet.com/'>Genshin Meet</a>
                }
                subTitle="Kraków"
                description="5-7.05.2023"
            />
            <Step
                title={
                    <a href='https://magnificon.conrego.com/'>Magnificon EXPO</a>
                }
                subTitle="Kraków"
                description="19-21.05.2023"
            />
            <Step
                title={
                    <a href='https://pyrkon.pl/'>Pyrkon</a>
                }
                subTitle="Poznań"
                description="16-18.06.2023"
            />
            <Step
                title={
                    <a href='https://linktr.ee/animeconpl'>AnimeCon</a>
                }
                subTitle="Poznań"
                description="28-29.10.2023"
            />
            <Step
                title={
                    <a href='https://magnificon.conrego.com/'>Magnificon Winter EXPO</a>
                }
                subTitle="Kraków"
                description="02-03.12.2023"
            />
        </Steps>
        <img src={kaveh} alt="Kaveh" className="calendarEmoji" />
    </div>)
}

export default CalendarScreen;