import React from 'react';
import { Language, testPoszukiwaczaInstructionsBubbleTextEN, testPoszukiwaczaInstructionsBubbleTextPL, testPoszukiwaczaInstructionsStartTestEN, testPoszukiwaczaInstructionsStartTestPL } from '../../../constants';
import './QuestTestPoszukiwaczaInstructions.scss'
import keqing from '../../../assets/graphics/emojis/keqing1.webp';
import { Button } from 'antd';

interface QuestTestPoszukiwaczaInstructionsProps {
    lang: Language,
    setAreInstructionsVisible: () => void,
}

function QuestTestPoszukiwaczaInstructions(props: QuestTestPoszukiwaczaInstructionsProps) {
    return (<div>
        <div className="questTestPoszukiwaczaInstructionsEmojiBox">
            <img src={keqing} alt="Keqing" className="questTestPoszukiwaczaInstructionsEmoji" />
            <div className="questTestPoszukiwaczaInstructionsEmojiSpeechBubble">
                <p className='questTestPoszukiwaczaInstructions-speech-bubble-text'>
                    {props.lang === Language.PL ? testPoszukiwaczaInstructionsBubbleTextPL : testPoszukiwaczaInstructionsBubbleTextEN}</p>
            </div>
        </div>
        <div className='questTestPoszukiwaczaInstructionsStartGameBox'><Button size='large' type="primary" onClick={() => props.setAreInstructionsVisible()}>
            {props.lang === Language.PL ? testPoszukiwaczaInstructionsStartTestPL : testPoszukiwaczaInstructionsStartTestEN}
            </Button></div>
    </div>)
}

export default QuestTestPoszukiwaczaInstructions;