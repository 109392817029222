import React from 'react';
import './QuestGamerIntroductionScreen.scss'
import itto from '../../assets/graphics/emojis/itto1.webp'
import { introductionButtonEN, introductionButtonPL, introductionHeaderEN, introductionHeaderPL, introductionInputEN, introductionInputPL, Language } from '../../constants';
import { Button, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

interface QuestGamerIntroductionProps {
    lang: Language,
    travelersName: string | undefined,
    setTravelersName: (name: string | undefined) => void,
}

function QuestGamerIntroductionScreen(props: QuestGamerIntroductionProps) {
    const navigate = useNavigate();

    return (<div className="introductionBody">
        <img src={itto} className="introductionEmoji" alt="Itto" />
        <p className='introductionHeaderText'>{props.lang === Language.PL ? introductionHeaderPL : introductionHeaderEN}</p>
        <Input size="large" 
            className='introductionInput' 
            value={props.travelersName}
            onChange={(e) => {
                props.setTravelersName(e.target.value);
            }}
            placeholder={props.lang === Language.PL ? introductionInputPL : introductionInputEN} />
        <Button size='large' type="primary" disabled={!props.travelersName} onClick={() => navigate('/questMenu')}>{props.lang === Language.PL ? introductionButtonPL : introductionButtonEN}</Button>
    </div>)
}

export default QuestGamerIntroductionScreen;