import React from 'react';
import { Language, questMistrziUczenBeginEN, questMistrziUczenBeginPL, questMistrziUczenInfo1EN, questMistrziUczenInfo1PL, questMistrziUczenInfo2EN, questMistrziUczenInfo2PL } from '../../../constants';
import './QuestInfo.scss';
import venti1 from '../../../assets/graphics/quests/mistrziuczen/venti1.webp'
import venti2 from '../../../assets/graphics/quests/mistrziuczen/venti2.webp'
import { Button } from 'antd';

interface QuestInfoProps {
    lang: Language,
    setInfoVisibility: (visibility: boolean) => void,
}

function QuestInfo(props: QuestInfoProps) {
    return (<div className='questMistrzInfoBody'>
        <div className='questMistrzVevti1Box'>
            <img src={venti1} className="questMistrzEmoji" alt="Venti"/>
            <div className="questListSpeechBubbleVenti1">
                <p className='questMistrz-speech-bubble-text'>{props.lang === Language.PL ? questMistrziUczenInfo1PL : questMistrziUczenInfo1EN}</p>
            </div>
        </div>
        <div className='questMistrzVevti2Box'>
            <div className="questListSpeechBubbleVenti2">
                <p className='questMistrz-speech-bubble-text'>{props.lang === Language.PL ? questMistrziUczenInfo2PL : questMistrziUczenInfo2EN}</p>
            </div>
            <img src={venti2} className="questMistrzEmoji" alt="Venti"/>
        </div>
        <Button size='large' 
                    type="primary" 
                    onClick={() => props.setInfoVisibility(false)}>
                        {props.lang === Language.PL ? questMistrziUczenBeginPL : questMistrziUczenBeginEN}</Button>
    </div>)
}

export default QuestInfo;