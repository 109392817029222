import React from 'react'
import { JanKenPonSymbol } from '../../../quests';
import './JanKenPonPaimonBoard.scss'
import paimonUndefined from '../../../../assets/graphics/quests/jankenpon/paimon-confident.webp'
import paimonRockWin from '../../../../assets/graphics/quests/jankenpon/paimon-win-kamien.webp'
import paimonPaperWin from '../../../../assets/graphics/quests/jankenpon/paimon-win-papier.webp'
import paimonScissorsWin from '../../../../assets/graphics/quests/jankenpon/paimon-win-nozyce.webp'
import paimonRockLost from '../../../../assets/graphics/quests/jankenpon/paimon-lose-kamien.png'
import paimonPaperLost from '../../../../assets/graphics/quests/jankenpon/paimon-lose-papier.png'
import paimonScissorsLost from '../../../../assets/graphics/quests/jankenpon/paimon-lose-nozyce.png'

interface JanKenPonPaimonBoardProps {
    paimonSymbol: JanKenPonSymbol | undefined,
    isWinning: boolean,
}

function JanKenPonPaimonBoard(props: JanKenPonPaimonBoardProps) {
    return (<div className='janKenPonPaimonBoardBody'>
        {props.paimonSymbol === undefined && <img src={paimonUndefined} alt="Paimon" className='janKenPonPaimonBoardEmoji'/>}
        {props.paimonSymbol === JanKenPonSymbol.rock && props.isWinning && <img src={paimonRockWin} alt="Paimon Rock" className='janKenPonPaimonBoardEmoji'/>}
        {props.paimonSymbol === JanKenPonSymbol.paper && props.isWinning &&<img src={paimonPaperWin} alt="Paimon Paper" className='janKenPonPaimonBoardEmoji'/>}
        {props.paimonSymbol === JanKenPonSymbol.scissors && props.isWinning && <img src={paimonScissorsWin} alt="Paimon Scissors" className='janKenPonPaimonBoardEmoji'/>}
        {props.paimonSymbol === JanKenPonSymbol.rock && !props.isWinning && <img src={paimonRockLost} alt="Paimon Rock" className='janKenPonPaimonBoardEmoji'/>}
        {props.paimonSymbol === JanKenPonSymbol.paper && !props.isWinning &&<img src={paimonPaperLost} alt="Paimon Paper" className='janKenPonPaimonBoardEmoji'/>}
        {props.paimonSymbol === JanKenPonSymbol.scissors && !props.isWinning && <img src={paimonScissorsLost} alt="Paimon Scissors" className='janKenPonPaimonBoardEmoji'/>}
    </div>)
}

export default JanKenPonPaimonBoard;