import React from 'react';
import './BottomBar.scss'
import { AiFillInstagram, AiFillFacebook} from "react-icons/ai"

function BottomBar() {
    return (<div className="wholeBottomBar">
        <div className='bottomBarDisclaimerBody'>
        Genshin Impact is owned by HoYoverse | Allowance is made for “fair use” under Section 107 of the Copyright Act.
        </div>
        <div className='bottomBarBody'>
        <div className='bottomBarLinkBox'>
            <AiFillInstagram className="bottomBarIcon"/>
            <a href='https://www.instagram.com/project_genshin/'>instagram.com/project_genshin</a>
        </div>
        <div className='bottomBarLinkBox'>
            <AiFillFacebook className="bottomBarIcon"/>
            <a href='https://www.facebook.com/projectgenshin'>facebook.com/projectgenshin</a>
        </div>
    </div>
    </div>)
}

export default BottomBar;