import React from 'react'
import { Language, questMistrzFillGapEN, questMistrzFillGapPL } from '../../../../constants';
import './FillGap.scss'

interface FillGapProps {
    lang: Language,
}

function FillGap(props: FillGapProps) {
    return (<div className='questMistrzFillGap'>{props.lang === Language.PL ? questMistrzFillGapPL : questMistrzFillGapEN}</div>)
}

export default FillGap;