import React, { useState } from 'react'
import { Language, questKuCzciEditorEnterHaikuPlaceholderEN, questKuCzciEditorEnterHaikuPlaceholderPL, questKuCzciEditorInfo1EN, questKuCzciEditorInfo1PL, questKuCzciEditorInfo2EN, questKuCzciEditorInfo2PL, questKuCzciEditorModalExampleEN, questKuCzciEditorModalExamplePL, questKuCzciEditorModalInfoEN, questKuCzciEditorModalInfoPL, questKuCzciEditorSubmitEN, questKuCzciEditorSubmitPL, questKuCzciPreviewButtonEN, questKuCzciPreviewButtonPL } from '../../../constants'
import './Editor.scss'
import yae from '../../../assets/graphics/quests/kuczcipieciukasen/yaeInfo2.webp'
import { Button, Modal } from 'antd'
import TextArea from 'antd/es/input/TextArea'

interface EditorProps {
    lang: Language,
    poem: string | undefined,
    setPoem: (poem: string | undefined) => void,
    openPreview: () => void, 
}

function Editor(props: EditorProps) {
    const [isExampleVisible, setIsExampleVisible] = useState(false)

    return (<div className='editorBody'>
        <img src={yae} alt="Yae Miko" className='yaeEmoji' />
        <Button size='large'
            type="primary"
            onClick={() => setIsExampleVisible(true)}>
            {props.lang === Language.PL ? questKuCzciPreviewButtonPL : questKuCzciPreviewButtonEN}</Button>
        <p className="editorInfo1">{props.lang === Language.PL ? questKuCzciEditorInfo1PL : questKuCzciEditorInfo1EN}</p>
        <span className="editorInfo2">{props.lang === Language.PL ? questKuCzciEditorInfo2PL : questKuCzciEditorInfo2EN}</span>
        <TextArea rows={3} value={props.poem} onChange={(e) => props.setPoem(e.target.value)} placeholder={props.lang === Language.PL ? questKuCzciEditorEnterHaikuPlaceholderPL : questKuCzciEditorEnterHaikuPlaceholderEN} className='editorTextArea' />
        <Button size='large'
            type="primary"
            disabled={props.poem === undefined || props.poem.split('\n').length !== 3 || props.poem.split('\n')[2] === ''}
            onClick={() => props.openPreview()}>
            {props.lang === Language.PL ? questKuCzciEditorSubmitPL : questKuCzciEditorSubmitEN}</Button>
        <Modal open={isExampleVisible}
            centered={true}
            onCancel={() => setIsExampleVisible(false)}
            footer={null}
            bodyStyle={{ fontFamily: 'Lato', fontSize: '1.2rem', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
                <div>
                    <div>{props.lang === Language.PL ? questKuCzciEditorModalInfoPL : questKuCzciEditorModalInfoEN}</div>
                    <div className='modalExampleText'>"{props.lang === Language.PL ? questKuCzciEditorModalExamplePL : questKuCzciEditorModalExampleEN}"</div>
                </div>
            </Modal>
    </div>)
}

export default Editor;