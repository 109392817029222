import React from 'react';
import { Language, questGoBackToMenuEN, questGoBackToMenuPL, questJanKenPonFailedTextEN, questJanKenPonFailedTextPL } from '../../../../../constants';
import QuestJanKenPonScoreBoard from '../../../QuestJanKenPonScoreBoard/QuestJanKenPonScoreBoard';
import './QuestJanKenPonFailed.scss'
import paimon from '../../../../../assets/graphics/quests/jankenpon/paimon-win.webp'
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

interface QuestJanKenPonFailedProps {
    lang: Language,
    paimonScore: number,
    playerScore: number,
}

function QuestJanKenPonFailed(props: QuestJanKenPonFailedProps) {
    const navigate = useNavigate();
    
    return (<div className='questJanKenPonFailedBody'>
        <QuestJanKenPonScoreBoard paimonScore={props.paimonScore} playerScore={props.playerScore} lang={props.lang} />
        <img src={paimon} alt="Paimon" className='questJanKenPonFailedEmoji'/>
        <p className='questJanKenPonFailedText'>{props.lang === Language.PL ? questJanKenPonFailedTextPL : questJanKenPonFailedTextEN}</p>
        <Button size='large' type="primary" onClick={() => navigate('/questMenu')}>
            {props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button>
    </div>)
}

export default QuestJanKenPonFailed;