import { Button, Radio, Space } from 'antd';
import React from 'react'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Language, questGoBackToMenuEN, questGoBackToMenuPL } from '../../../constants';
import { TestPoszukiwaczaQuestion } from '../../quests';
import './QuestTestPoszukiwaczaAnswers.scss'

interface QuestTestPoszukiwaczaAnswersProps {
    lang: Language,
    questions: TestPoszukiwaczaQuestion[],
    answers: Array<undefined>,
}

function QuestTestPoszukiwaczaAnswers(props: QuestTestPoszukiwaczaAnswersProps) {
    return (<div className='testPoszukiwaczaQuestionAnswersBody'>
        <div>
            <div>
                <div className='testPoszukiwaczaQuestionTitle'>{props.lang === Language.PL ? props.questions[0].titlePL : props.questions[0].titleEN}</div>
                <Radio.Group value={props.answers[0]}>
                    <Space direction="vertical">
                        <Radio value={1} className={props.questions[0].correct === 1 ? "testPoszukiwaczaQuestionAnswerCorrect" : "testPoszukiwaczaQuestionAnswerIncorrect"}>
                            <div className='testPoszukiwaczaQuestionAnswerBox'>
                                {props.questions[0].answer1}
                                {props.questions[0].correct === 1 ?
                                    <AiOutlineCheck size={20} className="testPoszukiwaczaQuestionAnswerCorrectIcon" /> :
                                    <AiOutlineClose size={20} className="testPoszukiwaczaQuestionAnswerIncorrectIcon" />}
                            </div>
                        </Radio>
                        <Radio value={2} className={props.questions[0].correct === 2 ? "testPoszukiwaczaQuestionAnswerCorrect" : "testPoszukiwaczaQuestionAnswerIncorrect"}>
                            <div className='testPoszukiwaczaQuestionAnswerBox'>
                                {props.questions[0].answer2}
                                {props.questions[0].correct === 2 ?
                                    <AiOutlineCheck size={20} className="testPoszukiwaczaQuestionAnswerCorrectIcon" /> :
                                    <AiOutlineClose size={20} className="testPoszukiwaczaQuestionAnswerIncorrectIcon" />}
                            </div>
                        </Radio>
                        <Radio value={3} className={props.questions[0].correct === 3 ? "testPoszukiwaczaQuestionAnswerCorrect" : "testPoszukiwaczaQuestionAnswerIncorrect"}>
                            <div className='testPoszukiwaczaQuestionAnswerBox'>
                                {props.questions[0].answer3}
                                {props.questions[0].correct === 3 ?
                                    <AiOutlineCheck size={20} className="testPoszukiwaczaQuestionAnswerCorrectIcon" /> :
                                    <AiOutlineClose size={20} className="testPoszukiwaczaQuestionAnswerIncorrectIcon" />}
                            </div>
                        </Radio>
                    </Space>
                </Radio.Group>
            </div>
            <div>
                <div className='testPoszukiwaczaQuestionTitle'>{props.lang === Language.PL ? props.questions[1].titlePL : props.questions[0].titleEN}</div>
                <Radio.Group value={props.answers[1]}>
                    <Space direction="vertical">
                        <Radio value={1} className={props.questions[1].correct === 1 ? "testPoszukiwaczaQuestionAnswerCorrect" : "testPoszukiwaczaQuestionAnswerIncorrect"}>
                            <div className='testPoszukiwaczaQuestionAnswerBox'>
                                {props.questions[1].answer1}
                                {props.questions[1].correct === 1 ?
                                    <AiOutlineCheck size={20} className="testPoszukiwaczaQuestionAnswerCorrectIcon" /> :
                                    <AiOutlineClose size={20} className="testPoszukiwaczaQuestionAnswerIncorrectIcon" />}
                            </div>
                        </Radio>
                        <Radio value={2} className={props.questions[1].correct === 2 ? "testPoszukiwaczaQuestionAnswerCorrect" : "testPoszukiwaczaQuestionAnswerIncorrect"}>
                            <div className='testPoszukiwaczaQuestionAnswerBox'>
                                {props.questions[1].answer2}
                                {props.questions[1].correct === 2 ?
                                    <AiOutlineCheck size={20} className="testPoszukiwaczaQuestionAnswerCorrectIcon" /> :
                                    <AiOutlineClose size={20} className="testPoszukiwaczaQuestionAnswerIncorrectIcon" />}
                            </div>
                        </Radio>
                        <Radio value={3} className={props.questions[1].correct === 3 ? "testPoszukiwaczaQuestionAnswerCorrect" : "testPoszukiwaczaQuestionAnswerIncorrect"}>
                            <div className='testPoszukiwaczaQuestionAnswerBox'>
                                {props.questions[1].answer3}
                                {props.questions[1].correct === 3 ?
                                    <AiOutlineCheck size={20} className="testPoszukiwaczaQuestionAnswerCorrectIcon" /> :
                                    <AiOutlineClose size={20} className="testPoszukiwaczaQuestionAnswerIncorrectIcon" />}
                            </div>
                        </Radio>
                    </Space>
                </Radio.Group>
            </div>
            <div>
                <div className='testPoszukiwaczaQuestionTitle'>{props.lang === Language.PL ? props.questions[2].titlePL : props.questions[0].titleEN}</div>
                <Radio.Group value={props.answers[2]}>
                    <Space direction="vertical">
                        <Radio value={1} className={props.questions[2].correct === 1 ? "testPoszukiwaczaQuestionAnswerCorrect" : "testPoszukiwaczaQuestionAnswerIncorrect"}>
                            <div className='testPoszukiwaczaQuestionAnswerBox'>
                                {props.questions[2].answer1}
                                {props.questions[2].correct === 1 ?
                                    <AiOutlineCheck size={20} className="testPoszukiwaczaQuestionAnswerCorrectIcon" /> :
                                    <AiOutlineClose size={20} className="testPoszukiwaczaQuestionAnswerIncorrectIcon" />}
                            </div>
                        </Radio>
                        <Radio value={2} className={props.questions[2].correct === 2 ? "testPoszukiwaczaQuestionAnswerCorrect" : "testPoszukiwaczaQuestionAnswerIncorrect"}>
                            <div className='testPoszukiwaczaQuestionAnswerBox'>
                                {props.questions[2].answer2}
                                {props.questions[2].correct === 2 ?
                                    <AiOutlineCheck size={20} className="testPoszukiwaczaQuestionAnswerCorrectIcon" /> :
                                    <AiOutlineClose size={20} className="testPoszukiwaczaQuestionAnswerIncorrectIcon" />}
                            </div>
                        </Radio>
                        <Radio value={3} className={props.questions[2].correct === 3 ? "testPoszukiwaczaQuestionAnswerCorrect" : "testPoszukiwaczaQuestionAnswerIncorrect"}>
                            <div className='testPoszukiwaczaQuestionAnswerBox'>
                                {props.questions[2].answer3}
                                {props.questions[2].correct === 3 ?
                                    <AiOutlineCheck size={20} className="testPoszukiwaczaQuestionAnswerCorrectIcon" /> :
                                    <AiOutlineClose size={20} className="testPoszukiwaczaQuestionAnswerIncorrectIcon" />}
                            </div>
                        </Radio>
                    </Space>
                </Radio.Group>
            </div>
            <div>
                <div className='testPoszukiwaczaQuestionTitle'>{props.lang === Language.PL ? props.questions[3].titlePL : props.questions[3].titleEN}</div>
                <Radio.Group value={props.answers[3]}>
                    <Space direction="vertical">
                        <Radio value={1} className={props.questions[3].correct === 1 ? "testPoszukiwaczaQuestionAnswerCorrect" : "testPoszukiwaczaQuestionAnswerIncorrect"}>
                            <div className='testPoszukiwaczaQuestionAnswerBox'>
                                {props.questions[3].answer1}
                                {props.questions[3].correct === 1 ?
                                    <AiOutlineCheck size={20} className="testPoszukiwaczaQuestionAnswerCorrectIcon" /> :
                                    <AiOutlineClose size={20} className="testPoszukiwaczaQuestionAnswerIncorrectIcon" />}
                            </div>
                        </Radio>
                        <Radio value={2} className={props.questions[3].correct === 2 ? "testPoszukiwaczaQuestionAnswerCorrect" : "testPoszukiwaczaQuestionAnswerIncorrect"}>
                            <div className='testPoszukiwaczaQuestionAnswerBox'>
                                {props.questions[3].answer2}
                                {props.questions[3].correct === 2 ?
                                    <AiOutlineCheck size={20} className="testPoszukiwaczaQuestionAnswerCorrectIcon" /> :
                                    <AiOutlineClose size={20} className="testPoszukiwaczaQuestionAnswerIncorrectIcon" />}
                            </div>
                        </Radio>
                        <Radio value={3} className={props.questions[3].correct === 3 ? "testPoszukiwaczaQuestionAnswerCorrect" : "testPoszukiwaczaQuestionAnswerIncorrect"}>
                            <div className='testPoszukiwaczaQuestionAnswerBox'>
                                {props.questions[3].answer3}
                                {props.questions[3].correct === 3 ?
                                    <AiOutlineCheck size={20} className="testPoszukiwaczaQuestionAnswerCorrectIcon" /> :
                                    <AiOutlineClose size={20} className="testPoszukiwaczaQuestionAnswerIncorrectIcon" />}
                            </div>
                        </Radio>
                    </Space>
                </Radio.Group>
            </div>
        </div>
        <Link to="/questMenu"><Button size='large' type="primary" className='testPoszukiwaczaQuestionAnswerButton'>{props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button></Link>
    </div>)
}

export default QuestTestPoszukiwaczaAnswers;