import { QuestDescription, QuestProgress, Region, StandQuestDescription } from "../constants";

export const quests = new Map<number, QuestDescription>([
  [1, { namePL: "Test Poszukiwacza Przygód", nameEN: "Adventurer's Test", region: Region.monstadt }],
  [2, { namePL: "Jan Ken Pon", nameEN: "Jan Ken Pon", region: Region.inazuma }],
  [3, { namePL: "Szkolenie Grzybiarza", nameEN: "Mushroom Picker Training", region: Region.sumeru }],
  [4, { namePL: "Perły Sangonomiyi", nameEN: "Sangonomiya's Pearls", region: Region.inazuma }],
  [5, { namePL: "Wymiana Językowa", nameEN: "Language Exchange", region: Region.monstadt }],
  [6, { namePL: "Mistrz i Uczeń", nameEN: "Master and Apprentice", region: Region.monstadt }],
  [7, { namePL: "Ku Czci Pięciu Kasen", nameEN: "In Honor of the Five Kasen", region: Region.inazuma }],
  [8, { namePL: "Zapisane W Gwiazdach", nameEN: "Written In The Stars", region: Region.sumeru }],
  [9, { namePL: "Abyss Order W Natarciu", nameEN: "Abyss Order is coming", region: Region.liyue }],
  [10, { namePL: "Zagubiona Paczka", nameEN: "Lost Package", region: Region.inazuma }],
  [11, { namePL: "Kwiaty W Dowód Miłości", nameEN: "Flowery Token Of Love", region: Region.fontaine }],
]);

export const standQuests = new Map<number, StandQuestDescription>([
  [1, { namePL: "Adepti Domain", nameEN: "Adepti Domain", region: Region.liyue, descriptionPL: 'Ad astra abyssosque! Pojawiło się nowe miejsce wymagające zbadania. By jednak tam dotrzeć, musisz zbudować sobie pomost prowadzący na drugą stronę wąwozu. Pomost składa się z trzech elementów, jednak miejsce jest zaklęte i niewidzialne moce przeszkadzają w wypełnieniu zadania. Aby przedostać się przez most podejdź na stoisko Adventure Guild i wygraj z prowadzącym w kółko i krzyżyk.', descriptionEN: 'Ad astra abyssosque! A new domain has appeared that requires investigation! In order to get there, you need to create a bridge linking to the other side of the gorge. The crossing is made up from three pieces, but the whole area has been cursed and invisible forces make finishing this quest hard. To pass through the bridge come to the Adventure Guild and win theTic Tac Toe game with the guardian.' }],
  [2, { namePL: "Chochlik drukarski", nameEN: "A printing imp", region: Region.inazuma, descriptionPL: 'Ad astra abyssosque! Do nowej powieści Zhenyu, ilustrowanej przez Calxa, wkradły się błędy! Proszę, pomóż edytorom je odnaleźć, by uchronić Yae Publishing House przed kompromitacją. Podejdź do stoiska Adventure Guild i wskaż wszystkie różnice na widocznych obrazkach.', descriptionEN: 'Ad astra abyssosque! Somehow, there are  mistakes in the new Zhenyu novel that was illustrated by Calax! Please help the editors find it in order to shield Yae Publishing House from discredit. Go to Adventure Guild and point out all the differences between the images.' }],
  [3, { namePL: "Uśmiech proszę!", nameEN: "Smile please!", region: Region.monstadt, descriptionPL: 'Ad astra abyssosque! Za pomocą wspaniałego urządzenia z Fontaine, czyli aparatu, wykonaj pamiątkowe zdjęcie z naszymi dekoracjami! Mile widziane selfie. Wstaw proszę zdjęcie na instastory (lub inne social media), tagując nasz projekt. Podejdź do stoiska Adventure Guild, aby pokazać zdjęcie.', descriptionEN: 'Ad astra abyssosque! Using the marvelous Fontaine device - the camera - please take a memorable photo with our decorations! Selfies are more than welcome. Add the picture to your insta story (or other social media), tagging our project. Go to Adventure Guild to show the photo.' }],
  [4, { namePL: "Poradnik alchemiczny", nameEN: "Alchemy Guide", region: Region.monstadt, descriptionPL: 'Ad astra abyssosque! Timaeus, znany alchemik z Mondstadt, szykuje podręcznik alchemiczny dla młodych adeptów tej znakomitej sztuki. Bardzo mu pomożesz, podając przy stoisku Adventure Guild dwie alternatywne nazwy dla wody.', descriptionEN: 'Ad astra abyssosque! The well known Mondstadian alchemist Timaeus is making an alchemists guide for young adepts of the craft.  You will greatly help him by coming up with two alternative names for water. Tell these names to the person in Adventure Guild.' }],
  [5, { namePL: "Dobry znak", nameEN: "Good Sign", region: Region.liyue, descriptionPL: 'Ad astra abyssosque! Zhihua jest wielkim romantykiem, niestety wciąż samotnym. Poprosił o znalezienie dowodów na to, że czeka go jeszcze w życiu miłość. Znajdź dwa znaki mogące zwiastować, że zbliża się coś dobrego. Zrób zdjęcia tych znaków i pokaż je na stanowisku Adventurers’ Guild.', descriptionEN: 'Ad astra abyssosque! Zhihua is a great romantic soul, but sadly - he is still single and lonely. He tasked you with finding proof that in his life, he is still destined to find love. Find two signs that herald that something good is coming. Take pictures of said signs and show it to the Adventures Guild workers.' }],
  [6, { namePL: "Trening Rycerzy Favoniusa", nameEN: "Favounius Knight's Training", region: Region.monstadt, descriptionPL: 'Ad astra abyssosque! Z powodu wzmożonej aktywności slime’ów, Rycerze Favoniusa zobowiązali się zadbać o dobrą kondycję mieszkańców Mondstadt. Podstawowe umiejętności obronne mogą przydać się każdemu! Odwiedź stanowisko Adventurers’ Guild i pokaż tam swoje ulubione ruchy bojowe!', descriptionEN: "Ad astra abyssosque! Due to rising slime activity, the knights of Favionious took up a job of teaching the citizens of Mondstradt some self defense. Basic fighting moves will be helpful for everyone! Come by the Adventurers' Guild and show us your best fighting skills!" }],
  [7, { namePL: "Mistrzostwa świata z Arataki Itto", nameEN: "World Championship with Arataki Itto", region: Region.inazuma, descriptionPL: 'Ad astra abyssosque! Ataraki Itto ponownie urządza mistrzostwa Inazumy w walkach Onikabuto. Nie jest on jednak zbyt dobrym rysownikiem i większość plakatów o odbywającym się konkursie zostaje zupełnie przeoczona przez przechodniów. Dlatego zwrócił się do Gildii z prośbą o pomoc! Wymyśl hasło reklamowe lub narysuj szkic plakatu zachęcającego wszystkich do przybycia na zawody. Potrzebne materiały możesz otrzymać na stoisku Adventurers’ Guild.', descriptionEN: "Ad astra abyssosque! Ataraki Itto is yet again organising the Inazuma championship in Onikabuto fighting. Sadly, he is not the best artist and most of his posters about the competition are completely ignored by the passersby. That’s why he asked Adventurers’ Guild for aid! Come up with a slogan or draw a poster encouraging everybody to see the championship! All necessary materials can be obtained in the Adventurers' Guild booth." }],
  [8, { namePL: "Bohater light novel", nameEN: "Light novel protagonist", region: Region.inazuma, descriptionPL: 'Ad astra abyssosque! Yae Publishing House poszukuje inspiracji dla stworzenia nowego protagonisty w ich kolejnym cyklu powieściowym. Powiedz Podróżniku, kto jest twoim ulubionym mieszkańcem Teyvatu? Proszę, zrób sobie zdjęcie z tą postacią i przynieś pokazać przy stanowisku Adventurers’ Guild.', descriptionEN: "Ad astra abyssosque! Yae Publishing House is looking for inspiration in the creation of their new novel protagonist. Traveler, do say who is your favorite Teyvat dweller? Please take a picture with that character and show it at the Adventurer's Guild booth." }],
  [9, { namePL: "Dada domu", nameEN: "Dada domu", region: Region.monstadt, descriptionPL: 'Ad astra abyssosque! “Dada domu” oznacza po hilichurliańsku dobry taniec. Właśnie tańcem Hilichurle wyrażają radość! I ty zatańcz jak Hilichurl - udaj się na stoisko Adventurers’ Guild i pokaż nam co potrafisz.', descriptionEN: "Ad astra abyssosque! “Dada domu” means in Hirichul “good dance”. With exactly that, Hirichuls show joy! Please show your Hirichul dancing moves at the Adventurers’ Guild booth! " }],
  [10, { namePL: "Astrologiczne rozważania", nameEN: "Astrological considerations", region: Region.monstadt, descriptionPL: 'Ad astra abyssosque! W Teyvat przyszłość można odczytać, patrząc w niebo… Odwiedź stanowisko Adventurers’ Guild i połącz gwiazdy w odpowiedniej kolejności, aby odczytać kształt nowej konstelacji.', descriptionEN: "Ad astra abyssosque! In Teyvat, the future can be predicted by looking at the sky… Visit Adventurers’ Guild booth and match the stars in the correct order to read the shape of a new constellation! " }],
  [11, { namePL: "Nieoszlifowany diament", nameEN: "Unpolished diamond", region: Region.liyue, descriptionPL: 'Ad astra abyssosque! Shitou prowadzi w Liyue Harbor sklep z drogocennymi minerałami. Zaprasza wszystkich do wzięcia udziału w jego grze, polegającej na odnalezieniu kryształu. Udaj się na stoisko Adventurers’ Guild, by wziąć udział w wyzwaniu.', descriptionEN: "Ad astra abyssosque! Shitou has a precious gem shop in Liyue Harbor. He invites everyone to take part in his game of crystal hunting. Go to the Adventurers' Guild booth in order to take part in this challenge!" }],
  [12, { namePL: "Kłopotliwy mechanizm", nameEN: "Troublesome mechanism", region: Region.sumeru, descriptionPL: 'Ad astra abyssosque! Gildia odnotowała pojawienie się nowego tajemniczego mechanizmu na obrzeżach miasta. Proszę, wyrusz tam i go zbadaj! Wygląda na to, że żeby przejście się otworzyło, należy umieścić wszystkie kulki po tej samej stronie mechanizmu. Odwiedź Adventurers’ Guild, by otrzymać szczegóły misji.', descriptionEN: "Ad astra abyssosque! The guild has noted an appearance of a new, mysterious mechanism on the outskirts of the city. Please travel there to study this curiosity. It looks that in order to open a crossing you need to place all the balls on the same site of the mechanism. Go to the Adventurers’ Guild in order to get details of this mission." }],
  [13, { namePL: "Kulisy Pracy Generała Mahamatry", nameEN: "The unknown side of General Mahamatra's job", region: Region.sumeru, descriptionPL: 'Ad astra abyssosque! Cyno prowadzi notes, w którym zapisuje najlepsze żarty i potrzebuje nowych propozycji. Odwiedź Adventurers’ Guild i opowiedz nam dowcip.', descriptionEN: "Ad astra abyssosque! Cyno runs a notebook, in which he stores all of his best jokes. Currently he looks for some more... foreign inspirations. Go to the Adventurers’ Guild and tell us your best joke." }],
  [14, { namePL: "Mistrzu, pójdziemy na obiad?", nameEN: "Master, shall we go to dinner?", region: Region.liyue, descriptionPL: 'Ad astra abyssosque! Przybysz z odległego kraju próbuje nauczyć się jeść za pomocą pałeczek. Pomożesz mu, pokazując, jak się ich używa. Przyjdź do Adventure Guild i w ramach prezentacji, przenieś za pomocą pałeczek 20 orzechów z jednego naczynia do drugiego.', descriptionEN: "Ad astra abyssosque! A newcomer from a distant land tries to learn to eat with chopsticks. You have to help him by showing how to use them properly! Go to the Adventurers’ Guild  and as a demonstration, use chopsticks to transfer 20 nuts from one bowl to another." }],
  [15, { namePL: "Wypalona świeca", nameEN: "Burned candle", region: Region.liyue, descriptionPL: 'Ad astra abyssosque! Wśród największych szych Fatui nastąpiło poruszenie: czas przygotować pogrzeb dla Signiory. Jak jednak połączyć to z innymi obowiązkami? Pomóż im w uczczeniu pamięci poległej towarzyszki: narysuj projekt nagrobka dla Signiory lub stwórz znicz z rzeczy znalezionych na konwencie i przyjdź pokazać go do Adventure Guild.', descriptionEN: "Ad astra abyssosque! There was a stir among Fatui's greatest brass: it was time to prepare a funeral for Signiora. But how to combine it with other responsibilities? Help them honor the memory of their fallen comrade: draw a design for a tombstone for Signiora or create a candle/urn from things found at the convention and come show it to the Adventure Guild." }],
  [16, { namePL: "Miraże", nameEN: "Mirages", region: Region.inazuma, descriptionPL: 'Ad astra abyssosque! Dziwne urządzenie Fatui stworzyło miraż świata łudząco podobny do tego prawdziwego. Kilka elementów jednak zdaje się być nie na swoim miejscu. Przyjdź do Adveture Guild i wskaż różnicę między obrazkami.', descriptionEN: "Ad astra abyssosque! Fatui's strange device created a mirage of a world confusingly similar to the real one. However, several elements seem to be out of place. Come to the Adventure Guild and point out the difference between the pictures." }],
  [17, { namePL: "Zagubiony klucz", nameEN: "Find the key", region: Region.monstadt, descriptionPL: 'Ad astra abyssosque! Ach, podróżnik taki jak ty z pewnością posiada sokoli wzrok! Upuściliśmy klucz gdzieś w tej okolicy. Czy pomożesz nam go odnaleźć? Przyjdź do Adventure Guild i pomóź nam oszukać zaginiony klucz.', descriptionEN: "Ad astra abyssosque! Ah, a traveler like you certainly has eagle eyes! We dropped the key somewhere around here. Can you help us find it? Come to Adventure Guild and help us find the missing key." }],
  [18, { namePL: "Asystent magika", nameEN: "Magician's assistant", region: Region.fontaine, descriptionPL: 'Ad astra abyssosque! Lyney poszukuje pomocy przy jednej ze swoich magicznych sztuczek. Udowodnij mu, że w sztuce iluzji nie masz sobie równych! Zaprezentuj w Adventurers’ Guild dowolny magiczny trick, który ma szansę oczarować widownię. (Forma przedstawienia dowolna)', descriptionEN: "Ad astra abyssosque! Lyney is looking for help with one of his magic tricks. Prove to him that you are unrivaled in the art of illusion! Perform any magic trick at the Adventurers' Guild that has a chance to charm the audience. (Any form of presentation.)" }],
  [19, { namePL: "Wielki problem z bąblami", nameEN: "Big bubble problem", region: Region.sumeru, descriptionPL: 'Ad astra abyssosque! Badacz z Sumeru stara się wynaleźć nowy lek, do którego zamierza użyć ekstraktu ze slime’ów. Nie ma jednak szans poradzić sobie bez pomocy doświadczonego wojownika! Pomóż mu w badaniach nad slime’ami i narysuj nowy gatunek slime, a następnie przyjdź go nam pokazać w Adventure Guild.', descriptionEN: "Ad astra abyssosque! A researcher from Sumeru is trying to invent a new medicine for which he intends to use slime extract. However, there is no way to cope without the help of an experienced warrior! Help him with slime research and draw a new species of slime, then come show it to us at Adventure Guild." }],
  [20, { namePL: "Pamięć doskonała", nameEN: "Perfect memory", region: Region.fontaine, descriptionPL: 'Ad astra abyssosque! Odblokuj tajemniczy mechanizm, zapamiętując ułożenie broni bądź potężnych artefaktów! Przyjdź do Adventure Guild, wskaż poprawnie trzy pary identycznych przedmiotów podczas gry w memory. Możesz się pomylić 2 razy.', descriptionEN: "Ad astra abyssosque! Unlock the mysterious mechanism by remembering the arrangement of weapons or powerful artifacts! Come to Adventure Guild, correctly identify three pairs of identical objects in a game of memory. You can be wrong twice." }],
  [21, { namePL: "Orzeźwiająca Fonta, Fontanna orzeźwienia", nameEN: "Refreshing Fonta, a Font of Refreshment", region: Region.fontaine, descriptionPL: 'Ad astra abyssosque! Heinry z zespołu tworzącego Fontę, modny napój produkowany w Fontaine, potrzebuje twojej pomocy. Pracuje właśnie nad nowymi smakami Fonty. Zrób zdjęcia 5 rzeczy, które mogłyby stać się nowymi smakami popularnego napoju i pokaż je w Adventurers Guild.', descriptionEN: "Ad astra abyssosque! Heinry - of the team behind Fonta, the trendy Fontainian drink - needs your help. He’s currently trying to invent new Fonta flavors. Take photos of 5 things that could become new flavors of a popular drink and show the in Adventurers's Guild" }],
  [22, { namePL: "Sokole oko", nameEN: "Marksmanship", region: Region.monstadt, descriptionPL: 'Ad astra abyssosque! Mieszkańców miasta zaniepokoiły slime’y, krążące w okolicach bramy miejskiej. Przyjdź do Adventures Guild i traf wszystkie 3 bronią wypożyczoną od Katheryne, by uwolnić miastowych od zmartwienia.', descriptionEN: "Ad astra abyssosque! The town's inhabitants are alarmed by slimes circling the city gates. Come to the Adventurers Guild and hit all 3 with a weapon borrowed from Katheryne to put the townsfolk’s troubled minds to rest." }],
  [23, { namePL: "Ich dzieciństwo", nameEN: "Their Childhood", region: Region.liyue, descriptionPL: 'Ad astra abyssosque! Trójka dzieciaków nudzi się niezwykle i poszukuje kompana do zabawy w chowanego. Przyjdź do Adventures Guild i odnajdź całą trójkę!', descriptionEN: "Ad astra abyssosque! Three kids, bored out of their minds, look for a playmate for hide-and-seek. Come to the Adventurers' Guild and find all three of them!" }],
  [24, { namePL: "Forteca Meropide", nameEN: "Fortress of Meropide", region: Region.fontaine, descriptionPL: 'Ad astra abyssosque! Cater potrzebuje kogoś, kto przeszmugluje małą paczkę do więzienia. Czy podejmiesz się tego zadania? Przyjdź do Adventures Guild i przejdź labirynt.', descriptionEN: "Ad astra abyssosque! Cater needs someone to smuggle a small package into the prison. Will you take up this task? Come to the Adventurers' Guild and complete the maze." }],
  [25, { namePL: "Trening magika", nameEN: "Magician Training", region: Region.fontaine, descriptionPL: 'Ad astra abyssosque! Freminet ćwiczy się w sztuczkach magicznych, by móc wesprzeć swoje ukochane rodzeństwo w ich występach scenicznych. Przyjdź do Adventures Guild i pomóż mu w treningu, grając w trzy kubki.', descriptionEN: "Ad astra abyssosque! Freminet is practicing magic tricks so that he can support his beloved siblings in their stage performances. Come to the Adventurers' Guild and help him train by playing three cups." }],
  [26, { namePL: "Uwaga! Przeciążenie", nameEN: "Caution! Overload", region: Region.sumeru, descriptionPL: 'Ad astra abyssosque! Jeden ze studentów Akademiyi nie jest w stanie poradzić sobie w swojej pracy badawczej. Zajmuje się elementami, takimi jak Pyro czy Electro. Podczas jego badań dochodzi jednak do nagłych reakcji pomiędzy elementami. Przyjdź do Adventures Guild i pomóż mu rozwiązać problem, łącząc na tablicy te same elementy w taki sposób, by linie ich łączące nie przecinały się.', descriptionEN: "Ad astra abyssosque! One of the Akademiya students is unable to finish his research work. He studies elements such as Pyro and Electro. However, during his research, sudden reactions between elements occur. Come to the Adventurers' Guild and help him solve the problem by connecting the same elements on the board so that the lines connecting them do not intersect." }],
  [27, { namePL: "Zatopione kosztowności", nameEN: "Sunken valuables", region: Region.liyue, descriptionPL: 'Ad astra abyssosque! Kapitan Beidou wyławia zatopiony skarb pewnej ważnej persony z Liyue. Niestety jej nawigator się rozchorował. Przyjdź do Adventures Guild i zastąp go, pomagając wyznaczyć drogę dla statku.', descriptionEN: "Ad astra abyssosque! Captain Beidou fished out a sunken treasure of someone important from Liyue. Unfortunately, her navigator fell ill. Come to the Adventurers' Guild, replace him and guide the ship." }],
  [28, { namePL: "Nadzwyczajny ekstremalny wyścig żuków", nameEN: "Extraordinary Extreme Beetle Race", region: Region.inazuma, descriptionPL: 'Ad astra abyssosque! Itto szykuje kolejne zawody, w których będą mogły zmierzyć się onikabuto – ogromne żuki znane w Inazumie! Przygotuj się do zawodów! W ramach treningu weź udział w wyścigu onikabuto – odwiedź Adventurers’ Guild i zapytaj o szczegóły!', descriptionEN: "Ad astra abyssosque! Itto is preparing another round of the Onikabuto tournament - a battle of giant Inazuman beetles! Prepare for the contest: take part in a training round of beetle racing. Details at the Adventurers' Guild!" }],
  [29, { namePL: "Harmonia rytmu", nameEN: "Harmony of Rhythm", region: Region.fontaine, descriptionPL: 'Ad astra abyssosque! Ekspedycja badająca ruiny pozostawione przez Remurian natrafiła na zagadkę. Przyjdź do Adventures Guild i pomóż im ją rozwiązać, naśladując dokładnie rytm, który usłyszysz.', descriptionEN: "Ad astra abyssosque! The expedition in charge of Remurian ruins encountered a riddle. Come to the Adventurers' Guild and help them solve it by imitating the rhythm you hear." }],
  // [20, { namePL: "", nameEN: "", region: Region.fontaine, descriptionPL: 'Ad astra abyssosque! ', descriptionEN: "Ad astra abyssosque! " }],
])

export type DrawnQuest = {
  id: number,
  progress: QuestProgress,
  isMobile: boolean,
}

export type TestPoszukiwaczaQuestion = {
  titleEN: string;
  titlePL: string;
  answer1: string;
  answer2: string;
  answer3: string;
  correct: number;
}

export const testPoszukiwaczaQuestionsMap: Map<number, TestPoszukiwaczaQuestion> = new Map([
  [1, {
    titleEN: "What is Lisa's official title?",
    titlePL: "Jaki tytuł nosi Lisa?",
    answer1: "Knight of Four Winds",
    answer2: "Librarian of the Knight of Favonius",
    answer3: "Witch of purple roses",
    correct: 2
  }],
  [2, {
    titleEN: "What universe does Aloy come from?",
    titlePL: "Z jakiego świata pochodzi Aloy?",
    answer1: "The Last of Us",
    answer2: "The Outlander",
    answer3: "Horizon Zero Dawn",
    correct: 3
  }],
  [3, {
    titleEN: "What level-up material does Kazuha use?",
    titlePL: "Jaka roślina jest potrzebna do wylevelowania Kazuhy?",
    answer1: "Sea Ganoderma",
    answer2: "Dendrobium",
    answer3: "Violetgrass",
    correct: 1
  }],
  [4, {
    titleEN: "Which of these plants is unique to the Mondstadt region?",
    titlePL: "Która z tych roślin jest wyjątkowa dla regionu Mondstadt?",
    answer1: "Silk Flower",
    answer2: "Valberry",
    answer3: "Sweet Flower",
    correct: 2
  }],
  [5, {
    titleEN: "Which of these characters cannot Plunging Attack while using their Elemental Skill?",
    titlePL: "Która z tych postaci nie może wykonać Plunging Attack podczas używania swojego Elemental Skill?",
    answer1: "Ayato",
    answer2: "Chongyun",
    answer3: "Noelle",
    correct: 1
  }],
  [6, {
    titleEN: "What is Yoimiya's surname?",
    titlePL: "Jakie nazwisko nosi Yoimiya?",
    answer1: "Naganohara",
    answer2: "Ryuunosuke",
    answer3: "Nakamura",
    correct: 1
  }],
  [7, {
    titleEN: "Which of these characters can't heal?",
    titlePL: "Która z tych postaci nie potrafi leczyć?",
    answer1: "Noelle",
    answer2: "Yun Jin",
    answer3: "Mika",
    correct: 2
  }],
  [8, {
    titleEN: "Which of the following characters is unable to summon a shield?",
    titlePL: "Która z wymienionych postaci nie jest w stanie przywołać tarczy?",
    answer1: "Beidou",
    answer2: "Yanfei",
    answer3: "Razor",
    correct: 3
  }],
  [9, {
    titleEN: "Which person the Traveler has encountered has already disappeared from the lands of Teyvat?",
    titlePL: "Która osoba, z napotkanych przez Travelera, zniknęła już z ziem Teyvatu?",
    answer1: "Ellin",
    answer2: "Teppei",
    answer3: "Bolai",
    correct: 2
  }],
  [10, {
    titleEN: "Which of these characters is not from Inazuma?",
    titlePL: "Która z wymienionych postaci nie pochodzi z Inazumy?",
    answer1: "Scaramuche",
    answer2: "Kazuha",
    answer3: "Chongyun",
    correct: 3
  }],
  [11, {
    titleEN: "Which of the following elements is Boreas not immune to?",
    titlePL: "Na który z podanych elementów Boreas nie jest odporny?",
    answer1: "Anemo",
    answer2: "Cryo",
    answer3: "Pyro",
    correct: 3
  }],
  [12, {
    titleEN: "What is Keqing's official title as Qixing with Liyue?",
    titlePL: "Jaki oficjalny tytuł nosi Keqing jako Qixing z Liyue?",
    answer1: "Adepti",
    answer2: "Yuheng",
    answer3: "Lapis Lazuri",
    correct: 2
  }],
  [13, {
    titleEN: "What is the name of the cat that lives in the Temple of Asase?",
    titlePL: "Jak nazywa się kot mieszkający w Świątyni Asase?",
    answer1: "Neko",
    answer2: "Miko",
    answer3: "Prince",
    correct: 1
  }],
  [14, {
    titleEN: "In which of the following locations can you find a flower called Cecilia?",
    titlePL: "W której z wymienionych lokacji można odnaleźć kwiat zwany Cecilia?",
    answer1: "Starsnatch Cliff",
    answer2: "Wolvendom",
    answer3: "Windrise",
    correct: 1
  }],
  [15, {
    titleEN: "Who is the director of Wangsheng Funeral Home?",
    titlePL: "Kto jest dyrektorem Zakładu Pogrzebowego Wangsheng?",
    answer1: "Yae Miko",
    answer2: "Hu Tao",
    answer3: "Zhongli",
    correct: 2
  }],
  [16, {
    titleEN: "Who is the second Fatui Harbingers?",
    titlePL: "Kto jest drugim spośród Fatui Harbingers?",
    answer1: "Pantalone",
    answer2: "Signora",
    answer3: "Dottore",
    correct: 3
  }],
  [17, {
    titleEN: "Which Archon created Mora?",
    titlePL: "Który Archon stworzył Morę?",
    answer1: "Pyro Archon",
    answer2: "Dendro Archon",
    answer3: "Geo Archon",
    correct: 3
  }],
  [18, {
    titleEN: "What is the name Hydro Hypostasis?",
    titlePL: "Jak brzmi imię Hydro Hypostasis?",
    answer1: "He",
    answer2: "Aleph",
    answer3: "Beth",
    correct: 1
  }],
  [19, {
    titleEN: "How many members does Bennett's Adventure Team currently have?",
    titlePL: "Ile członków posiada obecnie Adventure Team Bennetta?",
    answer1: "4",
    answer2: "2",
    answer3: "1",
    correct: 3
  }],
  [20, {
    titleEN: "Which of these plants will you not find growing naturally in Liyue?",
    titlePL: "Której z tych roślin nie znajdziesz rosnących naturalnie w Liyue?",
    answer1: "Glaze Lily",
    answer2: "Silk Flower",
    answer3: "Dendrobium",
    correct: 3
  }],
]);

export function drawTestPoszukiwaczaQuestions(questionsMap: Map<number, TestPoszukiwaczaQuestion>): TestPoszukiwaczaQuestion[] {
  const questionIds = Array.from(questionsMap.keys()); // get all question IDs
  const randomQuestionIds = shuffleArray(questionIds).slice(0, 4); // shuffle IDs and take first 3
  const randomQuestions = randomQuestionIds.map(id => questionsMap.get(id)!); // get questions from IDs
  return randomQuestions;
}

function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function wymianaJezykowaIsAnswerCorrect(answer: string | undefined): boolean {
  if (answer === undefined) { return false }
  const cleanedAnswer = answer.replace(/[^a-zA-Z]/g, '').toLowerCase();

  return cleanedAnswer === 'olah';
}

export enum JanKenPonSymbol {
  rock = 'rock',
  paper = 'paper',
  scissors = 'scissors'
}

export function rockPaperScissors(player1Symbol: string, player2Symbol: string): number {
  if (player1Symbol === player2Symbol) {
    return -1; // tie
  } else if (
    (player1Symbol === JanKenPonSymbol.rock && player2Symbol === JanKenPonSymbol.scissors) ||
    (player1Symbol === JanKenPonSymbol.paper && player2Symbol === JanKenPonSymbol.rock) ||
    (player1Symbol === JanKenPonSymbol.scissors && player2Symbol === JanKenPonSymbol.paper)
  ) {
    return 1; // player 1 wins
  } else {
    return 0; // player 1 loses
  }
}

export const szkolenieGrzybiarzaAnswers = new Map<number, number>([[1, 3], [2, 2], [3, 1]]);

function isVowelPL(char: string): boolean {
  const vowels = ['a', 'ą', 'e', 'ę', 'i', 'o', 'ó', 'u', 'y'];
  return vowels.includes(char.toLowerCase());
}

export function getSyllableCountPL(poem: string): number[] {
  const lines = poem.trim().split('\n');
  const syllableCounts = lines.map((line) => {
    let syllables = 0;
    let prevVowel = false;
    line.split('').forEach((char) => {
      const currVowel = isVowelPL(char);
      if (prevVowel && !currVowel) {
        syllables++;
      }
      prevVowel = currVowel;
    });
    if (prevVowel) {
      syllables++;
    }
    return syllables;
  });
  return syllableCounts;
}

export function getSyllableCountEN(poem: string): number[] {
  const lines = poem.trim().split('\n');
  const syllableCounts = lines.map((line) => {
    let syllables = 0;
    const words = line.split(' ');
    words.forEach((word) => {
      syllables += countSyllablesInWord(word);
    });
    return syllables;
  });
  return syllableCounts;
}

function countSyllablesInWord(word: string): number {
  word = word.toLowerCase().replace(/[^a-z]/g, ''); // remove non-alphabetic characters and convert to lowercase
  if (word.length <= 3) {
    return 1;
  }
  let syllables = 0;
  const vowels = ['a', 'e', 'i', 'o', 'u', 'y'];
  const letters = word.split('');
  let prevVowel = false;
  for (let i = 0; i < letters.length; i++) {
    const letter = letters[i];
    const currVowel = vowels.includes(letter);
    if (currVowel && !prevVowel) {
      syllables++;
    }
    prevVowel = currVowel;
  }
  if (word.endsWith('e')) {
    syllables--;
  }
  if (word.endsWith('le') && syllables > 1) {
    syllables--;
  }
  if (syllables === 0) {
    syllables = 1;
  }
  return syllables;
}

export type Constellation = {
  option1: string,
  option2: string,
  option3: string,
  correctAnswer: number,
}

export type ConstellationSet = Constellation[]

export const constellationSets: ConstellationSet[] = [
  [
    { option1: "YaoYao", option2: "Baizhu", option3: "Collei", correctAnswer: 3 },
    { option1: "Noelle", option2: "Ningguang", option3: "YunJin", correctAnswer: 2 },
    { option1: "Shenhe", option2: "Ayaka", option3: "Kaeya", correctAnswer: 2 }
  ],
  [
    { option1: "Kaveh", option2: "Alhaitham", option3: "Tighnari", correctAnswer: 2 },
    { option1: "Sucrose", option2: "Jean", option3: "Wanderer", correctAnswer: 1 },
    { option1: "Yanfei", option2: "Klee", option3: "Yoimiya", correctAnswer: 3 }
  ],
  [
    { option1: "Xingqiu", option2: "Yelan", option3: "Mona", correctAnswer: 2 },
    { option1: "YunJin", option2: "Ningguang", option3: "Noelle", correctAnswer: 1 },
    { option1: "Faruzan", option2: "Jean", option3: "Xiao", correctAnswer: 3 }
  ],
]

export type FlowerBoquet = {
  option1: string,
  option2: string,
  option3: string,
  option4: string,
  correctAnswer: number,
}
export type FlowersSet = FlowerBoquet[]

export const flowerSets: FlowersSet[] = [
  [
    { option1: "Shenhe", option2: "Qiqi", option3: "Ayaka", option4: "Hu Tao", correctAnswer: 2 },
    { option1: "Ganyu", option2: "Zhongli", option3: "YunJin", option4: "Yanfei", correctAnswer: 1 },
    { option1: "Diluc", option2: "Kaeya", option3: "Bennett", option4: "Jean", correctAnswer: 3 }
  ],
  [
    { option1: "Jean", option2: "Kaeya", option3: "Albedo", option4: "Klee", correctAnswer: 3 },
    { option1: "Shenhe", option2: "Xiao", option3: "Yanfei", option4: "Xingqiu", correctAnswer: 4 },
    { option1: "Nilou", option2: "Kaveh", option3: "Nahida", option4: "Collei", correctAnswer: 2 }
  ],
]

export const pyroMagePassword = "pyromage15"
export const hydroMagePassword = "hydromage23"
export const electroMagePassword = "electromage8"
export const cryoMagePassword = "cryomage39"

export const pyroMageHintPL = "Znajdziesz go w okolicach areny na hali wiosek"
export const pyroMageHintEN = "You'll find it near the Arena in the Village Hall Nr 3"
export const hydroMageHintPL = "Znajdziesz go na jednej z kolumn w Hali nr 3"
export const hydroMageHintEN = "You'll find it on one of the columns in Hall 3"
export const electroMageHintPL = "Znajdziesz go w okolicach tablicy ogłoszeń przy Adventure Guild"
export const electroMageHintEN = "You'll find it near the notice board next to the Adventure Guild"
export const cryoMageHintPL = "Znajdziesz go przy wiosce League of Legends"
export const cryoMageHintEN = "You'll find it near the League of Legends village"

export type PackageStory = {
  descriptionPL: string,
  descriptionEN: string,
  option1: string,
  option2: string,
  option3: string,
  correctAnswer: number,
}

export const lostPackageStories: PackageStory[] = [
  {
    descriptionPL: "To paczka dla osoby kochającej śpiew i grę na instrumentach, niestety uczulonej na koty.",
    descriptionEN: "This is a package for a person who loves to sing and play music on his instruments. How unfortunate they are allergic to cats...",
    option1: "Barbara",
    option2: "Venti",
    option3: "YunJin",
    correctAnswer: 2,
  },
  {
    descriptionPL: "To paczka dla młodo wyglądającej damy z prawniczym wykształceniem, której siostra pracuje w świątyni.",
    descriptionEN: "It's the package for a young lady with attorney education, whose sister works at a Shrine.",
    option1: "Yae Miko",
    option2: "Yoimiya",
    option3: "Kuki Shinobu",
    correctAnswer: 3,
  },
  {
    descriptionPL: "To paczka dla osoby kochającej książki, będącej autorem „A Legend of Sword”.",
    descriptionEN: "This package is addressed for a bookworm, who wrote the masterpiece called „Legend of Sword.”",
    option1: "Zhongli",
    option2: "Xingqiu",
    option3: "Chongyun",
    correctAnswer: 2,
  },
  {
    descriptionPL: "To paczka dla osoby pochodzącej ze znanego rodu, która za dziecka jako zwierzątko posiadała żółwia.",
    descriptionEN: "This package is from a hier of a well-known Mondstadt family. They once were an owner of a pet tortoise when they were just a child.",
    option1: "Diluc",
    option2: "Albedo",
    option3: "Klee",
    correctAnswer: 1,
  },
]

function selectRandomNumbers(numbers: number[]): number[] {
  const selectedNumbers: number[] = [];
  const randomIndices: number[] = [];
  while (randomIndices.length < 2) {
    const randomIndex = Math.floor(Math.random() * numbers.length);
    if (!randomIndices.includes(randomIndex)) {
      randomIndices.push(randomIndex);
    }
  }
  for (const index of randomIndices) {
    selectedNumbers.push(numbers[index]);
  }

  return selectedNumbers;
}

export function drawQuests(): DrawnQuest[] {
  const today = new Date()
  const drawnQuests = []

  if (today.getDate() === 6) { // sobota
    drawnQuests.push({ id: 3, progress: QuestProgress.inProgress, isMobile: true }) // szkolenie
    drawnQuests.push({ id: 1, progress: QuestProgress.inProgress, isMobile: true }) // test poszukiwacza
    const selectedNumbers = selectRandomNumbers([3, 22, 26, 16]);
    drawnQuests.push({ id: selectedNumbers[0], progress: QuestProgress.inProgress, isMobile: false })
    drawnQuests.push({ id: selectedNumbers[1], progress: QuestProgress.inProgress, isMobile: false })
    return drawnQuests
  } else {
    if (today.getDate() === 7) { // niedziela
      drawnQuests.push({ id: 11, progress: QuestProgress.inProgress, isMobile: true }) // kwiaty
      drawnQuests.push({ id: 4, progress: QuestProgress.inProgress, isMobile: true }) // perly
      const selectedNumbers = selectRandomNumbers([21, 14, 20, 10]);
      drawnQuests.push({ id: selectedNumbers[0], progress: QuestProgress.inProgress, isMobile: false })
      drawnQuests.push({ id: selectedNumbers[1], progress: QuestProgress.inProgress, isMobile: false })
      return drawnQuests
    } else {
        // piatek i test
        drawnQuests.push({ id: 5, progress: QuestProgress.inProgress, isMobile: true }) // wymiana
        drawnQuests.push({ id: 8, progress: QuestProgress.inProgress, isMobile: true }) // zapisane w gwiazdach
        const selectedNumbers = selectRandomNumbers([24, 1, 9, 7]);
        drawnQuests.push({ id: selectedNumbers[0], progress: QuestProgress.inProgress, isMobile: false })
        drawnQuests.push({ id: selectedNumbers[1], progress: QuestProgress.inProgress, isMobile: false })
        return drawnQuests
      }
    }
}