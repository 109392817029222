import React from 'react';
import './QuestTestPoszukiwaczaCompleted.scss'
import yoimiya from '../../../assets/graphics/emojis/yoimiya1.webp'
import { Button } from 'antd';
import { Language, questGoBackToMenuEN, questGoBackToMenuPL, testPoszukiwaczaCompletedEN, testPoszukiwaczaCompletedPL, testPoszukiwaczaViewAnswersEN, testPoszukiwaczaViewAnswersPL } from '../../../constants';
import { Link } from 'react-router-dom';

interface QuestTestPoszukiwaczaCompletedProps {
    lang: Language,
    setIsAnswersPreviewVisible: () => void,
}

function QuestTestPoszukiwaczaCompleted(props: QuestTestPoszukiwaczaCompletedProps) {
    return (<div>
        <div className='questTestPoszukiwaczaCompletedEmojiBox'>
            <div className="questTestPoszukiwaczaCompletedSpeechBubble">
                <p className='questTestPoszukiwaczaCompletedSpeechBubbleText'>{props.lang === Language.PL ? testPoszukiwaczaCompletedPL : testPoszukiwaczaCompletedEN}</p>
            </div>
            <img src={yoimiya} className="questTestPoszukiwaczaCompletedEmoji" alt='Yoimiya'/>
        </div>
        <div className='questTestPoszukiwaczaCompletedButtonBox'>
            <Button size='large' type="primary" className='questTestPoszukiwaczaCompletedButton' onClick={() => props.setIsAnswersPreviewVisible()}>
                {props.lang === Language.PL ? testPoszukiwaczaViewAnswersPL : testPoszukiwaczaViewAnswersEN}
                </Button>
                <Link to="/questMenu"><Button size='large' type="primary">{props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button></Link>
        </div>
    </div>)
}

export default QuestTestPoszukiwaczaCompleted;