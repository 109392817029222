import { Button } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QuestMenuButton from '../../components/quest-menu-button/QuestMenuButton';
import { Language, mobileEN, mobilePL, questGoBackToMenuEN, questGoBackToMenuPL, QuestProgress } from '../../constants';
import { quests } from '../quests';
import PoemScreen from './PoemScreen/PoemScreen';
import QuestInfo from './QuestInfo/QuestInfo';
import './QuestMistrzIUczen.scss';

interface QuestMistrzIUczenProps {
    lang: Language,
    questProgress: QuestProgress,
    setQuestProgress: (progress: QuestProgress) => void,
}

function QuestMistrzIUczen(props: QuestMistrzIUczenProps) {
    const quest = quests.get(6);
    const [isInfoVisible, setInfoVisibility] = useState(true);
    const navigate = useNavigate();

    return (<div className='questMistrzBody'>
        <QuestMenuButton
            location={props.lang === Language.PL ? mobilePL : mobileEN}
            progress={props.questProgress}
            region={quest?.region}
            title={props.lang === Language.PL ? quest?.namePL : quest?.nameEN} />
            {isInfoVisible && props.questProgress === QuestProgress.inProgress && <QuestInfo lang={props.lang} setInfoVisibility={setInfoVisibility}/>}
            {!isInfoVisible && <PoemScreen lang={props.lang} setQuestProgress={props.setQuestProgress}/>}
            {!isInfoVisible && props.questProgress !== QuestProgress.inProgress && <Button
            size='large'
            type="primary"
            className="questSzkolenieGrzybiarzaConfirmButton"
            onClick={() => navigate('/questMenu')}>{props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button>}
    </div>)
}

export default QuestMistrzIUczen;