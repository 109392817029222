import React from "react"
import './Summary.scss'
import { Language, questWrittenInTheStarsCorrectAnswersEN, questWrittenInTheStarsCorrectAnswersPL } from "../../../constants"
import set1Img1 from '../../../assets/graphics/quests/writteninthestars/set1/const1.webp'
import set1Img2 from '../../../assets/graphics/quests/writteninthestars/set1/const2.webp'
import set1Img3 from '../../../assets/graphics/quests/writteninthestars/set1/const3.webp'
import set2Img1 from '../../../assets/graphics/quests/writteninthestars/set2/const1.webp'
import set2Img2 from '../../../assets/graphics/quests/writteninthestars/set2/const2.webp'
import set2Img3 from '../../../assets/graphics/quests/writteninthestars/set2/const3.webp'
import set3Img1 from '../../../assets/graphics/quests/writteninthestars/set3/const1.webp'
import set3Img2 from '../../../assets/graphics/quests/writteninthestars/set3/const2.webp'
import set3Img3 from '../../../assets/graphics/quests/writteninthestars/set3/const3.webp'
import { constellationSets } from "../../quests"

interface SummaryProps {
    lang: Language,
    selectedSet: number,
}

function Summary(props: SummaryProps) {
    return (<div className="questSummaryConstellationHeader">
        <p>{props.lang == Language.PL ? questWrittenInTheStarsCorrectAnswersPL : questWrittenInTheStarsCorrectAnswersEN}</p>
        <div className="questSummaryConstellationBox">
            <img src={props.selectedSet === 0 ? set1Img1 : (props.selectedSet === 1 ? set2Img1 : set3Img1)} alt="Constellation 1" className="questSummaryConstellationImg"/>
            <div className="questSummaryConstellationTitle">{constellationSets[props.selectedSet][0].correctAnswer === 1 ? constellationSets[props.selectedSet][0].option1 : (constellationSets[props.selectedSet][0].correctAnswer === 2 ? constellationSets[props.selectedSet][0].option2 : constellationSets[props.selectedSet][0].option3)}</div>
        </div>
        <div className="questSummaryConstellationBox">
            <img src={props.selectedSet === 0 ? set1Img2 : (props.selectedSet === 1 ? set2Img2 : set3Img2)} alt="Constellation 2" className="questSummaryConstellationImg"/>
            <div className="questSummaryConstellationTitle">{constellationSets[props.selectedSet][1].correctAnswer === 1 ? constellationSets[props.selectedSet][1].option1 : (constellationSets[props.selectedSet][1].correctAnswer === 2 ? constellationSets[props.selectedSet][1].option2 : constellationSets[props.selectedSet][1].option3)}</div>
        </div>
        <div className="questSummaryConstellationBox">
            <img src={props.selectedSet === 0 ? set1Img3 : (props.selectedSet === 1 ? set2Img3 : set3Img3)} alt="Constellation 3" className="questSummaryConstellationImg"/>
            <div className="questSummaryConstellationTitle">{constellationSets[props.selectedSet][2].correctAnswer === 1 ? constellationSets[props.selectedSet][2].option1 : (constellationSets[props.selectedSet][2].correctAnswer === 2 ? constellationSets[props.selectedSet][2].option2 : constellationSets[props.selectedSet][2].option3)}</div>
        </div>
    </div>)
}

export default Summary

