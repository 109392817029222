import React, { useEffect, useState } from "react";
import './QuestLostPackage.scss'
import { Language, QuestProgress, mobileEN, mobilePL, questGoBackToMenuEN, questGoBackToMenuPL, questLostPackageCompletedEN, questLostPackageCompletedPL, questLostPackageDescriptionEN, questLostPackageDescriptionPL, questLostPackageFailedEN, questLostPackageFailedPL, questLostPackagePackageBelongsToEN, questLostPackagePackageBelongsToPL, questLostPackageSubmitAnswerEN, questLostPackageSubmitAnswerPL } from "../../constants";
import { lostPackageStories, quests } from "../quests";
import kiraraDescription from '../../assets/graphics/emojis/kirara1.webp'
import kiraraFailed from '../../assets/graphics/emojis/kirara2.webp'
import kiraraCompleted from '../../assets/graphics/emojis/kirara3.webp'
import QuestMenuButton from "../../components/quest-menu-button/QuestMenuButton";
import { Button, Radio, Space } from "antd";
import { useNavigate } from "react-router-dom";

interface QuestLostPackageProps {
    lang: Language,
    questProgress: QuestProgress,
    setQuestProgress: (questProgress: QuestProgress) => void,
}

function QuestLostPackage(props: QuestLostPackageProps) {
    const quest = quests.get(10);
    const [selectedPackageNumber, setSelectedPackageNumber] = useState<number>()
    const [selectedOption, setSelectedOption] = useState<number>()
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedPackageNumber(Math.floor(Math.random() * 4))
    }, [])

    return (<div className="questLostPackageBody">
        <QuestMenuButton
            location={props.lang === Language.PL ? mobilePL : mobileEN}
            progress={props.questProgress}
            region={quest?.region}
            title={props.lang === Language.PL ? quest?.namePL : quest?.nameEN} />
        {props.questProgress === QuestProgress.inProgress && <div className='questLostPackageSpeechBubbleBox'>
            <div className="questLostPackageSpeechBubble">
                <p className='questLostPackageSpeechBubbleText'>
                    {props.lang === Language.PL ? questLostPackageDescriptionPL : questLostPackageDescriptionEN}
                </p>
            </div>
            <img src={kiraraDescription} alt="Kirara" className='questLostPackageEmoji' />
        </div>}
        {props.questProgress === QuestProgress.completed && <div className='questLostPackageSpeechBubbleBox'>
            <div className="questLostPackageSpeechBubble">
                <p className='questLostPackageSpeechBubbleText'>
                    {props.lang === Language.PL ? questLostPackageCompletedPL : questLostPackageCompletedEN}
                </p>
            </div>
            <img src={kiraraCompleted} alt="Kirara" className='questLostPackageEmoji' />
        </div>}
        {props.questProgress === QuestProgress.failed && <div className='questLostPackageSpeechBubbleBox'>
            <div className="questLostPackageSpeechBubble">
                <p className='questLostPackageSpeechBubbleText'>
                    {props.lang === Language.PL ? questLostPackageFailedPL : questLostPackageFailedEN}
                </p>
            </div>
            <img src={kiraraFailed} alt="Kirara" className='questLostPackageEmoji' />
        </div>}
        <p className="questLostPackageDescription">"{props.lang === Language.PL ? lostPackageStories[selectedPackageNumber || 0].descriptionPL : lostPackageStories[selectedPackageNumber || 0].descriptionEN}"</p>
        <p className="questLostPackageAdressedTo">{props.lang === Language.PL ? questLostPackagePackageBelongsToPL : questLostPackagePackageBelongsToEN}</p>
        {props.questProgress === QuestProgress.inProgress && <Radio.Group onChange={(e) => setSelectedOption(e.target.value)} value={selectedOption}>
            <Space direction="vertical">
                <Radio value={1} className="questLostPackageRadio">{lostPackageStories[selectedPackageNumber || 0].option1}</Radio>
                <Radio value={2} className="questLostPackageRadio">{lostPackageStories[selectedPackageNumber || 0].option2}</Radio>
                <Radio value={3} className="questLostPackageRadio">{lostPackageStories[selectedPackageNumber || 0].option3}</Radio>
            </Space>
        </Radio.Group>}
        {props.questProgress !== QuestProgress.inProgress && <div className="questLostPackageRecipient">{lostPackageStories[selectedPackageNumber || 0].correctAnswer === 1 ? lostPackageStories[selectedPackageNumber || 0].option1 : (lostPackageStories[selectedPackageNumber || 0].correctAnswer === 2 ? lostPackageStories[selectedPackageNumber || 0].option2 : lostPackageStories[selectedPackageNumber || 0].option3) }</div>}
        {props.questProgress === QuestProgress.inProgress && <Button size='large' type="primary" disabled={!selectedOption}
        className="genericStandQuestPasswordButton" onClick={() => {
            if (selectedOption === lostPackageStories[selectedPackageNumber || 0].correctAnswer) {
                props.setQuestProgress(QuestProgress.completed)
            } else {
                props.setQuestProgress(QuestProgress.failed)
            }
        }}>{props.lang === Language.PL ? questLostPackageSubmitAnswerPL : questLostPackageSubmitAnswerEN}</Button>}
         {props.questProgress !== QuestProgress.inProgress && <Button size='large' type="primary"
            className="genericStandQuestPasswordButton" onClick={() => navigate('/questMenu')}>{props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button>}
    </div>)
}

export default QuestLostPackage