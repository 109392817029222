import React from 'react';
import QuestMenuButton from '../../components/quest-menu-button/QuestMenuButton';
import { Language, mobileEN, mobilePL, questListAyakaEN, questListAyakaPL, questListAyato1EN, questListAyato1PL, questListAyato2EN, questListAyato2PL, questListAyato3EN, questListAyato3PL, questListScreenCompletedAndRedeemedEN, questListScreenCompletedAndRedeemedPL, questListScreenCompletedEN, questListScreenCompletedPL, questListScreenFailedEN, questListScreenFailedPL, questListScreenPartiallyFailedEN, questListScreenPartiallyFailedPL, QuestProgress, Region, standEN, standPL } from '../../constants';
import ayato from '../../assets/graphics/emojis/ayato2.webp'
import ayaka from '../../assets/graphics/emojis/ayaka1.webp'
import layla from '../../assets/graphics/emojis/layla1.webp'
import sucrose from '../../assets/graphics/emojis/sucrose1.webp'
import nahida from '../../assets/graphics/emojis/nahida1.webp'
import nilou from '../../assets/graphics/emojis/nilou1.webp'
import './QuestListScreen.scss'
import { DrawnQuest, quests, standQuests } from '../../quests/quests';
import { useNavigate } from 'react-router-dom';

interface QuestListScreenProps {
    lang: Language,
    travelersName: string | undefined,
    drawnQuests: DrawnQuest[],
    setSelectedQuest: (quest: DrawnQuest) => void,
    isRewardRedeemed: boolean,
}

function QuestListScreen(props: QuestListScreenProps) {
    const navigate = useNavigate();
    
    if (props.drawnQuests.length) {
        return (<div className='questListBody'>
        {props.drawnQuests.some(quest => quest.progress === QuestProgress.inProgress) && <div className='questListAyatoBox'>
            <div className="questListSpeechBubbleAyato">
                <p className='questList-speech-bubble-text'>
                    <span className='questListSpeechBubbleAyatoWelcomeText'>{props.lang === Language.PL ? questListAyato1PL : questListAyato1EN} {props.travelersName}!</span><br />
                    {props.lang === Language.PL ? questListAyato2PL : questListAyato2EN}
                    <b>{props.lang === Language.PL ? questListAyato3PL : questListAyato3EN}</b></p>
            </div>
            <img src={ayato} className="questListEmoji" alt="ayato" />
        </div>}
        <div>
            <QuestMenuButton progress={props.drawnQuests[0].progress} title={props.lang === Language.PL ? quests.get(props.drawnQuests[0].id)?.namePL : quests.get(props.drawnQuests[0].id)?.nameEN} location={props.lang === Language.PL ? mobilePL : mobileEN} region={quests.get(props.drawnQuests[0].id)?.region} setSelectedQuest={() => props.setSelectedQuest(props.drawnQuests[0])} />
            <QuestMenuButton progress={props.drawnQuests[1].progress} title={props.lang === Language.PL ? quests.get(props.drawnQuests[1].id)?.namePL : quests.get(props.drawnQuests[1].id)?.nameEN} location={props.lang === Language.PL ? mobilePL : mobileEN} region={quests.get(props.drawnQuests[1].id)?.region} setSelectedQuest={() => props.setSelectedQuest(props.drawnQuests[1])} />
            <QuestMenuButton progress={props.drawnQuests[2].progress} title={props.lang === Language.PL ? standQuests.get(props.drawnQuests[2].id)?.namePL : standQuests.get(props.drawnQuests[2].id)?.nameEN} location={props.lang === Language.PL ? standPL : standEN} region={standQuests.get(props.drawnQuests[2].id)?.region} setSelectedQuest={() => props.setSelectedQuest(props.drawnQuests[2])} />
            <QuestMenuButton progress={props.drawnQuests[3].progress} title={props.lang === Language.PL ? standQuests.get(props.drawnQuests[3].id)?.namePL : standQuests.get(props.drawnQuests[3].id)?.nameEN} location={props.lang === Language.PL ? standPL : standEN} region={standQuests.get(props.drawnQuests[3].id)?.region} setSelectedQuest={() => props.setSelectedQuest(props.drawnQuests[3])} />
        </div>
        {props.drawnQuests.some(quest => quest.progress === QuestProgress.inProgress) && <div className='questListAyakaBox'>
            <img src={ayaka} className="questListEmoji" alt="Ayaka" />
            <div className="questListSpeechBubbleAyaka">
                <p className='questList-speech-bubble-text'>{props.lang === Language.PL ? questListAyakaPL : questListAyakaEN}</p>
            </div>
        </div>}
        {/* więcej niż 1 quest sfailowany: */}
        {!(props.drawnQuests.some(quest => quest.progress === QuestProgress.inProgress)) && props.drawnQuests.filter(quest => quest.progress === QuestProgress.failed).length > 1 && <div className='questListAyakaBox'>
            <img src={layla} className="questListEmoji" alt="Layla" />
            <div className="questListSpeechBubbleAyaka">
                <p className='questList-speech-bubble-text'>{props.lang === Language.PL ? questListScreenFailedPL : questListScreenFailedEN}</p>
            </div>
        </div>}
        {/* 4 questy zaliczone i nagroda nieodebrana: */}
        {!(props.drawnQuests.some(quest => quest.progress === QuestProgress.inProgress)) && props.drawnQuests.filter(quest => quest.progress === QuestProgress.failed).length === 0 && !props.isRewardRedeemed && <div className='questListAyakaBox' onClick={() => navigate('/rewards')}>
            <img src={sucrose} className="questListEmoji" alt="Sucrose" />
            <div className="questListSpeechBubbleAyaka">
                <p className='questList-speech-bubble-text'>{props.lang === Language.PL ? questListScreenCompletedPL : questListScreenCompletedEN}</p>
            </div>
        </div>}
         {/* 3/4 questy zaliczone i nagroda nieodebrana: */}
         {!(props.drawnQuests.some(quest => quest.progress === QuestProgress.inProgress)) && props.drawnQuests.filter(quest => quest.progress === QuestProgress.failed).length === 1 && !props.isRewardRedeemed && <div className='questListAyakaBox' onClick={() => navigate('/rewards')}>
            <img src={nilou} className="questListEmoji" alt="Nilou" />
            <div className="questListSpeechBubbleAyaka">
                <p className='questList-speech-bubble-text'>{props.lang === Language.PL ? questListScreenPartiallyFailedPL : questListScreenPartiallyFailedEN}</p>
            </div>
        </div>}
        {/* 3-4 questy zaliczone i nagroda odebrana: */}
        {!(props.drawnQuests.some(quest => quest.progress === QuestProgress.inProgress)) && props.drawnQuests.filter(quest => quest.progress === QuestProgress.failed).length < 2 && props.isRewardRedeemed && <div className='questListAyakaBox'>
            <img src={nahida} className="questListEmoji" alt="Nahida" />
            <div className="questListSpeechBubbleAyaka">
                <p className='questList-speech-bubble-text'>{props.lang === Language.PL ? questListScreenCompletedAndRedeemedPL : questListScreenCompletedAndRedeemedEN}</p>
            </div>
        </div>}
    </div>)
    } else {
        return (<div></div>)
    }
}

export default QuestListScreen;