import React from 'react'
import './QuestInfo.scss'
import { Language, questWrittenInTheStarsInfoEN, questWrittenInTheStarsInfoPL, questWrittenInTheStarsStartQuestEN, questWrittenInTheStarsStartQuestPL } from '../../../constants'
import layla from '../../../assets/graphics/emojis/layla2.webp'
import { Button } from 'antd'

interface QuestInfoProps {
    lang: Language,
    closeInfo: () => void,
}

function QuestInfo(props: QuestInfoProps) {
    return (<div className='questWrittenInTheStarsInfoBody'>
        <div className='questWrittenInTheStarsInfoSpeechBubbleBox'>
            <div className="questWrittenInTheStarsInfoSpeechBubble">
                <p className='questWrittenInTheStarsInfoSpeechBubbleText'>
                    {props.lang === Language.PL ? questWrittenInTheStarsInfoPL : questWrittenInTheStarsInfoEN}
                </p>
            </div>
            <img src={layla} alt="Layla" className='questWrittenInTheStarsInfoEmoji' />
        </div>
        <Button size='large' type="primary" 
        className="genericStandQuestPasswordButton" onClick={() => props.closeInfo()}>{props.lang === Language.PL ? questWrittenInTheStarsStartQuestPL : questWrittenInTheStarsStartQuestEN}</Button>
    </div>)
}

export default QuestInfo