import React from 'react';
import { Language, perlySangonomiyiBeginScanningEN, perlySangonomiyiBeginScanningPL, perlySangonomiyiInfo1EN, perlySangonomiyiInfo1PL, perlySangonomiyiInfo2EN, perlySangonomiyiInfo2PL } from '../../../constants';
import './MapScreen.scss';
import kokomiInfo from '../../../assets/graphics/quests/perlysangonomiyi/kokomiInfo.webp';
import map from '../../../assets/graphics/quests/perlysangonomiyi/map.jpg'
import { Button } from 'antd';

interface MapScreenProps {
    lang: Language,
    disableMap: () => void,
}

function MapScreen(props: MapScreenProps) {
    return (<div className='perlySangonomiyiMapBody'>
        <div className='perlySangonomiyiSpeechBubbleBox'>
            <div className="perlySangonomiyiSpeechBubble">
                <p className='perlySangonomiyiSpeechBubbleText'>
                    {props.lang === Language.PL ? perlySangonomiyiInfo1PL : perlySangonomiyiInfo1EN}
                </p>
            </div>
            <img src={kokomiInfo} alt="Kokomi" className='perlySangonomiyiEmoji' />
        </div>
        <img src={map} alt="map" className='perlySangonomiyiImg'/>
        <p className='perlySangonomiyiMapText'>{props.lang === Language.PL ? perlySangonomiyiInfo2PL : perlySangonomiyiInfo2EN}</p>
        <Button
            size='large'
            type="primary"
            className="questSzkolenieGrzybiarzaConfirmButton"
            onClick={() => props.disableMap()}>{props.lang === Language.PL ? perlySangonomiyiBeginScanningPL : perlySangonomiyiBeginScanningEN}</Button>
    </div>)
}

export default MapScreen;