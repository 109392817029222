import React, { useEffect, useState } from 'react';
import QuestMenuButton from '../../components/quest-menu-button/QuestMenuButton';
import { Language, mobileEN, mobilePL, QuestProgress } from '../../constants';
import { quests } from '../quests';
import MapScreen from './MapScreen/MapScreen';
import './QuestPerlySangonomiyi.scss'
// import { QrReader } from 'react-qr-reader';
import QrCodeReader from './QrCodeReader/QrCodeReader';
import QuestPerlySangonomiyiFailed from './results/QuestPerlySangonomiyiFailed/QuestPerlySangonomiyiFailed';
import QuestPerlySangonomiyiCompleted from './results/QuestPerlySangonomiyiCompleted/QuestPerlySangonomiyiCompleted';

interface QuestPerlySangonomiyiProps {
    lang: Language,
    questProgress: QuestProgress,
    setQuestProgress: (progress: QuestProgress) => void,
}

function QuestPerlySangonomiyi(props: QuestPerlySangonomiyiProps) {
    const [isMapVisible, setIsMapVisible] = useState(true);
    const quest = quests.get(4);
    const [password, setPassword] = useState<string>('');

    useEffect(() => {
        if (password === "sangonomiya") {
            props.setQuestProgress(QuestProgress.completed)
        }
    }, [password])

    return (<div className='questPerlySangonomiyiBody'>
         <QuestMenuButton
            location={props.lang === Language.PL ? mobilePL : mobileEN}
            progress={props.questProgress}
            region={quest?.region}
            title={props.lang === Language.PL ? quest?.namePL : quest?.nameEN} />
        {isMapVisible && props.questProgress === QuestProgress.inProgress && <MapScreen lang={props.lang} disableMap={() => setIsMapVisible(false)} />}
        {!isMapVisible && props.questProgress === QuestProgress.inProgress && <QrCodeReader lang={props.lang} setPassword={setPassword} password={password} goBackToMap={() => setIsMapVisible(true)} cancelQuest={() => props.setQuestProgress(QuestProgress.failed)}/>}
        {props.questProgress === QuestProgress.failed && <QuestPerlySangonomiyiFailed lang={props.lang} />}
        {props.questProgress === QuestProgress.completed && <QuestPerlySangonomiyiCompleted lang={props.lang} />}
    </div>)
}

export default QuestPerlySangonomiyi;