import React from 'react'
import { Language, questMistrzFailedPart1EN, questMistrzFailedPart1PL, questMistrzFailedPart2EN, questMistrzFailedPart2PL } from '../../../../constants'
import './VentiFailed.scss'
import venti from '../../../../assets/graphics/quests/mistrziuczen/ventiFailed.webp'

interface VentiFailedProps {
    lang: Language,
    correctAnswerPart1: string,
    correctAnswerPart2: string,
}

function VentiFailed(props: VentiFailedProps) {
    return (<div className='questMistrzCompletedSpeechBubbleBox'>
    <div className="questMistrzFailedSpeechBubble">
        <div className='questMistrzFailedSpeechBubbleText'>
            {props.lang === Language.PL ? questMistrzFailedPart1PL : questMistrzFailedPart1EN}
        </div>
        <div className='questMistrzFailedSpeechBubbleText poemAnswerCorrect'>
            "{props.correctAnswerPart1}
        </div>
        <div className='questMistrzFailedSpeechBubbleText poemAnswerCorrect'>
            {props.correctAnswerPart2}"
        </div>
        <div className='questMistrzFailedSpeechBubbleText'>
            {props.lang === Language.PL ? questMistrzFailedPart2PL : questMistrzFailedPart2EN}
        </div>
    </div>
    <img src={venti} alt="Venti" className='questMistrzCompletedEmoji' />
</div>)
}

export default VentiFailed;