import { Button, Radio, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { Language, poem2answer1part1EN, poem2answer1part1PL, poem2answer1part2EN, poem2answer1part2PL, poem2answer2part1EN, poem2answer2part1PL, poem2answer2part2EN, poem2answer2part2PL, poem2answer3part1EN, poem2answer3part1PL, poem2answer3part2EN, poem2answer3part2PL, poem2correctAnswer, poem2verse1EN, poem2verse1PL, poem2verse2EN, poem2verse2PL, poem2verse3EN, poem2verse3PL, poem2verse4EN, poem2verse4PL, questMistrzSubmitAnswerEN, questMistrzSubmitAnswerPL, QuestProgress } from '../../../../../constants'
import VentiCompleted from '../../../results/VentiCompleted/VentiCompleted'
import VentiFailed from '../../../results/VentiFailed/VentiFailed'
import FillGap from '../../FillGap/FillGap'

interface Poem2Props {
    lang: Language,
    setQuestProgress: (progress: QuestProgress) => void,
}

function Poem2(props: Poem2Props) {
    const [selectedAnswer, setSelectedAnswer] = useState<undefined | number>(undefined)
    const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false)
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(false)
    
    useEffect(() => {
        if (selectedAnswer !== undefined) {
            setIsAnswerCorrect(selectedAnswer === poem2correctAnswer)
            props.setQuestProgress(selectedAnswer === poem2correctAnswer ? QuestProgress.completed : QuestProgress.failed)
        }
    }, [isAnswerSubmitted])
    
    return (<div className='poemBody'>
    <div className='poem'>
        <p className='poemVerse'>{props.lang === Language.PL ? poem2verse1PL : poem2verse1EN}</p>
        <p className='poemVerse'>{props.lang === Language.PL ? poem2verse2PL : poem2verse2EN}</p>
        {!isAnswerSubmitted && <p className='poemVerse'>...</p>}
        {!isAnswerSubmitted && <FillGap lang={props.lang} />}
        {isAnswerSubmitted && selectedAnswer === 1 && <p className='poemVerse poemIncorrect'>{props.lang === Language.PL ? poem2answer1part1PL : poem2answer1part1EN}</p>}
        {isAnswerSubmitted && selectedAnswer === 1 && <p className='poemVerse poemIncorrect'>{props.lang === Language.PL ? poem2answer1part2PL : poem2answer1part2EN}</p>}
        {isAnswerSubmitted && selectedAnswer === 2 && <p className='poemVerse poemIncorrect'>{props.lang === Language.PL ? poem2answer2part1PL : poem2answer2part1EN}</p>}
        {isAnswerSubmitted && selectedAnswer === 2 && <p className='poemVerse poemIncorrect'>{props.lang === Language.PL ? poem2answer2part2PL : poem2answer2part2EN}</p>}
        {isAnswerSubmitted && selectedAnswer === 3 && <p className='poemVerse poemCorrect'>{props.lang === Language.PL ? poem2answer3part1PL : poem2answer3part1EN}</p>}
        {isAnswerSubmitted && selectedAnswer === 3 && <p className='poemVerse poemCorrect'>{props.lang === Language.PL ? poem2answer3part2PL : poem2answer3part2EN}</p>}
    
        <p className='poemVerse'>{props.lang === Language.PL ? poem2verse3PL : poem2verse3EN}</p>
        <p className='poemVerse'>{props.lang === Language.PL ? poem2verse4PL : poem2verse4EN}</p>
        </div>
    
    {!isAnswerSubmitted && <Radio.Group onChange={(e) => setSelectedAnswer(e.target.value)} value={selectedAnswer} size='large' className='poemAnswers'>
        <Space direction="vertical">
            <Radio value={1}>
                <div className="poemAnswerVerses">
                    <div className='poemVerse'>{props.lang === Language.PL ? poem2answer1part1PL : poem2answer1part1EN}</div>
                    <div className='poemVerse'>{props.lang === Language.PL ? poem2answer1part2PL : poem2answer1part2EN}</div>
                </div></Radio>
            <Radio value={2}>
                <div className="poemAnswerVerses">
                    <div className='poemVerse'>{props.lang === Language.PL ? poem2answer2part1PL : poem2answer2part1EN}</div>
                    <div className='poemVerse'>{props.lang === Language.PL ? poem2answer2part2PL : poem2answer2part2EN}</div>
                </div></Radio>
            <Radio value={3}>
                <div className="poemAnswerVerses">
                    <div className='poemVerse'>{props.lang === Language.PL ? poem2answer3part1PL : poem2answer3part1EN}</div>
                    <div className='poemVerse'>{props.lang === Language.PL ? poem2answer3part2PL : poem2answer3part2EN}</div>
                </div></Radio>
        </Space>
    </Radio.Group>}
    {!isAnswerSubmitted && <Button size='large'
        type="primary"
        disabled={selectedAnswer === undefined}
        onClick={() => setIsAnswerSubmitted(true)}>
        {props.lang === Language.PL ? questMistrzSubmitAnswerPL : questMistrzSubmitAnswerEN}</Button>}
    {isAnswerSubmitted && isAnswerCorrect && <VentiCompleted lang={props.lang}/>}
    {isAnswerSubmitted && !isAnswerCorrect && <VentiFailed lang={props.lang}
        correctAnswerPart1={props.lang === Language.PL ? poem2answer3part1PL : poem2answer3part1EN}
        correctAnswerPart2={props.lang === Language.PL ? poem2answer3part2PL : poem2answer3part2EN} />}
</div>)
}

export default Poem2;