import React, { useState } from 'react';
import './QuestRulesScreen.scss'
import tighnari from '../../assets/graphics/emojis/tighnari3.webp'
import { Button } from 'antd';
import { Language, rules1EN, rules1PL, rules2EN, rules2PL, rules3EN, rules3PL, rules4EN, rules4PL, rules5EN, rules5PL, rules6EN, rules6PL, rulesHeaderTextEN, rulesHeaderTextPL, rulesStartGameEN, rulesStartGamePL, rulesTighnariPart1DailyQuestsEN, rulesTighnariPart1DailyQuestsPL, rulesTighnariPart1EN, rulesTighnariPart1PL, rulesTighnariPart1ReadMoreEN, rulesTighnariPart1ReadMorePL, rulesTighnariPart1WhatAreEN, rulesTighnariPart1WhatArePL, rulesTighnariPart2EN, rulesTighnariPart2GoodLuckEN, rulesTighnariPart2GoodLuckPL, rulesTighnariPart2PL, rulesTighnariPart2ReadAgainEN, rulesTighnariPart2ReadAgainPL } from '../../constants';
import { Link } from 'react-router-dom';

interface QuestRulesScreenProps {
    lang: Language,
}

function QuestRulesScreen(props: QuestRulesScreenProps) {
    const [isTheFisrtInfoVisible, setFirstInfoVisibility] = useState(true);

    return (<div className='questRulesBody'>
        <div className='questRulesTighnariBox'>
            <div className="questRulesSpeechBubble" onClick={() => setFirstInfoVisibility(!isTheFisrtInfoVisible)}>
                {isTheFisrtInfoVisible &&
                    <p className='questRulesSpeechBubbleText'>
                        {props.lang === Language.PL ? rulesTighnariPart1WhatArePL : rulesTighnariPart1WhatAreEN}
                        <b>{props.lang === Language.PL ? rulesTighnariPart1DailyQuestsPL : rulesTighnariPart1DailyQuestsEN}</b>?<br />
                        {props.lang === Language.PL ? rulesTighnariPart1PL : rulesTighnariPart1EN}<br />
                        <b className='blinkingText'>{props.lang === Language.PL ? rulesTighnariPart1ReadMorePL : rulesTighnariPart1ReadMoreEN}</b></p>}
                {!isTheFisrtInfoVisible &&
                    <p className='questRulesSpeechBubbleText'>
                        {props.lang === Language.PL ? rulesTighnariPart2PL : rulesTighnariPart2EN}<br />
                        <b>{props.lang === Language.PL ? rulesTighnariPart2GoodLuckPL : rulesTighnariPart2GoodLuckEN}</b><br />
                        <b className='blinkingText'>{props.lang === Language.PL ? rulesTighnariPart2ReadAgainPL : rulesTighnariPart2ReadAgainEN}</b>
                    </p>}
            </div>
            <img src={tighnari} alt="Tighnari" className="questRulesEmoji" />
        </div>
        <div className='questRules'>
            <div className='questRulesHeaderText'>{props.lang === Language.PL ? rulesHeaderTextPL : rulesHeaderTextEN}</div>
            <div className='questRulesText'>
                <ol>
                    <li>{props.lang === Language.PL ? rules1PL : rules1EN}</li>
                    <li>{props.lang === Language.PL ? rules2PL : rules2EN}</li>
                    <li>{props.lang === Language.PL ? rules3PL : rules3EN}</li>
                    <li>{props.lang === Language.PL ? rules4PL : rules4EN}</li>
                    <li>{props.lang === Language.PL ? rules5PL : rules5EN}</li>
                    <li>{props.lang === Language.PL ? rules6PL : rules6EN}</li>
                </ol>
            </div>
            <Link to="/questIntroduction"><Button size='large' type="primary">{props.lang === Language.PL ? rulesStartGamePL : rulesStartGameEN}</Button></Link>
        </div>
    </div>)
}

export default QuestRulesScreen;