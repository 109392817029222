import React from 'react';
import { Language, questMistrzCompletedEN, questMistrzCompletedPL } from '../../../../constants';
import './VentiCompleted.scss';
import venti from '../../../../assets/graphics/quests/mistrziuczen/ventiCompleted.webp'

interface VentiCompletedProps {
    lang: Language
}

function VentiCompleted(props: VentiCompletedProps) {
    return (<div className='questMistrzCompletedSpeechBubbleBox'>
    <div className="questMistrzCompletedSpeechBubble">
        <p className='questMistrzCompletedSpeechBubbleText'>
            {props.lang === Language.PL ? questMistrzCompletedPL : questMistrzCompletedEN}
        </p>
    </div>
    <img src={venti} alt="Venti" className='questMistrzCompletedEmoji' />
</div>)
}

export default VentiCompleted;