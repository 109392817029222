import React, { useState } from "react"
import './AbyssMage.scss'
import { Language, enterPasswordEN, enterPasswordPL, questAbyssOrderFindMageEN, questAbyssOrderFindMagePL, questAbyssOrderHintEN, questAbyssOrderHintPL } from "../../../constants"
import { Button, Input, Modal } from "antd"
import { QrScanner } from "@yudiel/react-qr-scanner"
import { BsFillCheckSquareFill } from "react-icons/bs"

interface AbyssMageProps {
    lang: Language,
    password: string,
    imgSrc: string,
    confirmFindingMage: () => void,
    hint: string,
    isFound: boolean,
    name: string,
}

function AbyssMage(props: AbyssMageProps) {
    const [isModalVisible, setModalVisibility] = useState(false)

    return (<>
    <div className="questAbyssOrderMageBox" onClick={() => { if (!props.isFound) { setModalVisibility(true) } }}>
        <img src={props.imgSrc} alt={props.name} className="questAbyssOrderMageImg"/>
        {!props.isFound && <Button size='large' type="primary" className="genericStandQuestPasswordButton" onClick={() => setModalVisibility(true)}>{props.lang === Language.PL ? questAbyssOrderFindMagePL : questAbyssOrderFindMageEN}</Button>}
        {props.isFound && <BsFillCheckSquareFill size={28} fill="#5db148"/>}
    </div>
    <Modal open={isModalVisible} 
        footer={null}
        centered={true}
        bodyStyle={{ fontFamily: 'Lato', fontSize: '1.2rem', display: 'flex', flexDirection: 'column', alignItems: 'center'}}
        onCancel={() => setModalVisibility(false)}>
        <p>{props.lang === Language.PL ? questAbyssOrderHintPL : questAbyssOrderHintEN} {props.hint}</p>
        <QrScanner
          onDecode={(result) => { if (props.password === result) { setModalVisibility(false); props.confirmFindingMage() } }}
          onError={(error) => console.log(error?.message)}
        />
        <Input placeholder={props.lang === Language.PL ? enterPasswordPL : enterPasswordEN} className="questAbyssOrderMageInput" onChange={(e) => { if (props.password === e.target.value) { setModalVisibility(false); props.confirmFindingMage() } }}/>
      </Modal>
    </>)
}

export default AbyssMage