import React, { useState } from 'react'
import { Language, modalNoEN, modalNoPL, modalYesEN, modalYesPL, questGoBackToMenuEN, questGoBackToMenuPL, questKuCzciPreviewCompletedEN, questKuCzciPreviewCompletedPL, questKuCzciPreviewGiveUpButtonEN, questKuCzciPreviewGiveUpButtonPL, questKuCzciPreviewGiveUpTextEN, questKuCzciPreviewGiveUpTextPL, questKuCzciPreviewGoBackToEditingEN, questKuCzciPreviewGoBackToEditingPL, questKuCzciPreviewInfoEN, questKuCzciPreviewInfoPL, questKuCzciPreviewWrongEN, questKuCzciPreviewWrongPL } from '../../../constants'
import './Preview.scss'
import yae from '../../../assets/graphics/quests/kuczcipieciukasen/yaeCompleted.webp'
import sara from '../../../assets/graphics/quests/kuczcipieciukasen/saraIncomplete.webp'
import { Button, Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import { getSyllableCountEN, getSyllableCountPL } from '../../quests'

interface PreviewProps {
    lang: Language,
    poem: string,
    syllablesCount: number[],
    goBackToEditing: () => void,
    giveUp: () => void,
}

function Preview(props: PreviewProps) {
    const navigate = useNavigate();
    const poemDivided = props.poem.split('\n')
    const [isModalVisible, setModalVisibility] = useState(false)

    return (<div className='previewBody'>
        <div className='previewInfo'>{props.lang === Language.PL ? questKuCzciPreviewInfoPL : questKuCzciPreviewInfoEN}</div>
        <div>
            <div className='poemLineBox'>
                <div className={props.syllablesCount[0] === 5 ? 'syllablesCount correct' : 'syllablesCount incorrect'}>{props.syllablesCount[0]}</div>
                <div className={props.syllablesCount[0] === 5 ? 'poemLine correct' : 'poemLine incorrect'}>{poemDivided[0]}</div>
            </div>
            <div className='poemLineBox'>
                <div className={props.syllablesCount[1] === 7 ? 'syllablesCount correct' : 'syllablesCount incorrect'}>{props.syllablesCount[1]}</div>
                <div className={props.syllablesCount[1] === 7 ? 'poemLine correct' : 'poemLine incorrect'}>{poemDivided[1]}</div>
            </div>
            <div className='poemLineBox'>
                <div className={props.syllablesCount[2] === 5 ? 'syllablesCount correct' : 'syllablesCount incorrect'}>{props.syllablesCount[2]}</div>
                <div className={props.syllablesCount[2] === 5 ? 'poemLine correct' : 'poemLine incorrect'}>{poemDivided[2]}</div>
            </div>
        </div>
        {props.syllablesCount[0] === 5 && props.syllablesCount[1] === 7 && props.syllablesCount[2] === 5 && <div className='previewBody'>
            <img src={yae} alt="Yae Miko" className='previewEmoji' />
            <p className='previewInfo'>{props.lang === Language.PL ? questKuCzciPreviewCompletedPL : questKuCzciPreviewCompletedEN}</p>
            <Button
                size='large'
                type="primary"
                className="questSzkolenieGrzybiarzaConfirmButton"
                onClick={() => navigate('/questMenu')}>{props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button>
        </div>}
        {(props.syllablesCount[0] !== 5 || props.syllablesCount[1] !== 7 || props.syllablesCount[2] !== 5) && <div className='previewBody'>
            <img src={sara} alt="Sara" className='previewEmoji' />
            <p className='previewInfo'>{props.lang === Language.PL ? questKuCzciPreviewWrongPL : questKuCzciPreviewWrongEN}</p>
            <div className='previewButtonsBox'>
                <Button
                    size='large'
                    type="primary"
                    className='goBackButton'
                    onClick={() => props.goBackToEditing()}>{props.lang === Language.PL ? questKuCzciPreviewGoBackToEditingPL : questKuCzciPreviewGoBackToEditingEN}</Button>
                <Button
                    size='large'
                    type="primary"
                    onClick={() => setModalVisibility(true)}>{props.lang === Language.PL ? questKuCzciPreviewGiveUpButtonPL : questKuCzciPreviewGiveUpButtonEN}</Button>
            </div>
        </div>}
        <Modal open={isModalVisible}
            centered={true}
            onCancel={() => setModalVisibility(false)}
            onOk={() => props.giveUp()}
            okText={props.lang === Language.PL ? modalYesPL : modalYesEN}
            cancelText={props.lang === Language.PL ? modalNoPL : modalNoEN}
            cancelButtonProps={{ size: 'large'}}
            okButtonProps={{ size: 'large'}}
            bodyStyle={{ fontFamily: 'Lato', fontSize: '1.2rem', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <p>{props.lang === Language.PL ? questKuCzciPreviewGiveUpTextPL : questKuCzciPreviewGiveUpTextEN}</p>
        </Modal>
    </div>)
}

export default Preview;