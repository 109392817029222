import React, { useState } from "react";
import './Constellation.scss'
import { Language, questWrittenInTheStarsConfirmEN, questWrittenInTheStarsConfirmPL, questWrittenInTheStarsGivenConstellationEN, questWrittenInTheStarsGivenConstellationPL } from "../../../constants";
import { Button, Radio, Space } from "antd";

interface ConstellationProps {
    lang: Language,
    img: string,
    option1: string,
    option2: string,
    option3: string,
    onOptionSelect: (option: number) => void,
}

function Constellation(props: ConstellationProps) {
    const [selectedOption, setSelectedOption] = useState<number>()

    return (<div className="questWrittenInTheStarsConstellationBody">
        <img src={props.img} alt="constellation" className="questWrittenInTheStarsConstellationImg"/>
        <p>{props.lang === Language.PL ? questWrittenInTheStarsGivenConstellationPL : questWrittenInTheStarsGivenConstellationEN}</p>
        <Radio.Group onChange={(e) => setSelectedOption(e.target.value)} value={selectedOption}>
            <Space direction="vertical">
                <Radio value={1}>{props.option1}</Radio>
                <Radio value={2}>{props.option2}</Radio>
                <Radio value={3}>{props.option3}</Radio>
            </Space>
        </Radio.Group>
        <Button size='large' type="primary" disabled={!selectedOption}
        className="genericStandQuestPasswordButton" onClick={() => {
            props.onOptionSelect(selectedOption || 0)
            setSelectedOption(undefined)
        }}>{props.lang === Language.PL ? questWrittenInTheStarsConfirmPL : questWrittenInTheStarsConfirmEN}</Button>
    </div>)
}

export default Constellation