import React from 'react'
import './QuestPerlySangonomiyiCompleted.scss'
import pearls from '../../../../assets/graphics/quests/perlysangonomiyi/pearls.webp'
import kokomi from '../../../../assets/graphics/quests/perlysangonomiyi/kokomiCompleted.webp'
import { Button } from 'antd'
import { Language, perlySangonomiyiCompletedEN, perlySangonomiyiCompletedPL, questGoBackToMenuEN, questGoBackToMenuPL } from '../../../../constants'
import { useNavigate } from 'react-router-dom'

interface QuestPerlySangonomiyiCompletedProps {
    lang: Language,
}

function QuestPerlySangonomiyiCompleted(props: QuestPerlySangonomiyiCompletedProps) {
    const navigate = useNavigate();
    
    return (<div className='questPerlyCompletedBody'>
        <img src={pearls} alt="Pearls" className='questPerlyCompletedPearls'/>
        <img src={kokomi} alt="Kokomi" className='questPerlyCompletedKokomi'/>
        <p className='questPerlyCompletedText'>{props.lang === Language.PL ? perlySangonomiyiCompletedPL : perlySangonomiyiCompletedEN}</p>
        <Button
            size='large'
            type="primary"
            onClick={() => navigate('/questMenu')}>{props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button>
    </div>)
}

export default QuestPerlySangonomiyiCompleted;