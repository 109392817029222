import React from 'react';
import './MenuButton.scss'
import tartaglia from '../../assets/graphics/emojis/tartaglia1.webp'
import ayato from '../../assets/graphics/emojis/ayato1.webp'
import thoma from '../../assets/graphics/emojis/thoma1.webp'
import kaeya from '../../assets/graphics/emojis/kaeya1.webp'
import { Language, LeftEmojis, menuENDescriptions, menuENTitles, menuPLDescriptions, menuPLTitles, RightEmojis } from '../../constants';
import { Link } from 'react-router-dom';
import { getCookie } from '../../helpers';

interface MenuButtonProps {
    emoji: LeftEmojis | RightEmojis,
    lang: Language,
}

function MenuButton(props: MenuButtonProps) {
    let imageSrc;
    let title;
    let description;
    let path = "/";
    switch (props.emoji) {
        case RightEmojis.Tartaglia:
            imageSrc = tartaglia;
            title = props.lang === Language.PL ? menuPLTitles.Daily : menuENTitles.Daily;
            description = props.lang === Language.PL ? menuPLDescriptions.Daily : menuENDescriptions.Daily;
            path = getCookie("travelersName") ? "/questMenu" : "/questRules";
            break;
        case LeftEmojis.Ayato:
            imageSrc = ayato;
            title = props.lang === Language.PL ? menuPLTitles.About : menuENTitles.About;
            description = props.lang === Language.PL ? menuPLDescriptions.About : menuENDescriptions.About;
            path = "/about"
            break;
        case RightEmojis.Thoma:
            imageSrc = thoma;
            title = props.lang === Language.PL ? menuPLTitles.Instagram : menuENTitles.Instagram;
            description = props.lang === Language.PL ? menuPLDescriptions.Instagram : menuENDescriptions.Instagram;
            break;
        case LeftEmojis.Kaeya:
            imageSrc = kaeya;
            title = props.lang === Language.PL ? menuPLTitles.Calendar : menuENTitles.Calendar;
            description = props.lang === Language.PL ? menuPLDescriptions.Calendar : menuENDescriptions.Calendar;
            path = "/calendar"
            break;
        default:
            break;
    }

    if (props.emoji !== RightEmojis.Thoma) {
        return (<Link to={path} className="link">
            <div className="menuItem">
                <div className="menuButtonBody">
                    <img src={imageSrc} alt={imageSrc} className={(props.emoji === LeftEmojis.Ayato || props.emoji === LeftEmojis.Kaeya) ? "menuEmojiLeft" : "menuEmojiRight"} />
                    <div className="menuButtonTitle">{title}</div>
                    <div className="menuButtonDescription">{description}</div>
                </div>
            </div>
        </Link>)
    }
    else {
        return (
            <a href='https://www.instagram.com/project_genshin/' className='link'>
                <div className="menuItem">
                    <div className="menuButtonBody">
                        <img src={imageSrc} alt='thoma' className={"menuEmojiRight"} />
                        <div className="menuButtonTitle">{title}</div>
                        <div className="menuButtonDescription">{description}</div>
                    </div>
                </div>
            </a>
        )
    }
}

export default MenuButton;