import React, { useEffect, useState } from 'react';
import TopBar from './components/top-bar/TopBar';
import BottomBar from './components/bottom-bar/BottomBar';
import './App.scss';
import MenuScreen from './screens/MenuScreen/MenuScreen';
import { Language, QuestProgress } from './constants';
import { Route, Routes } from 'react-router-dom';
import AboutUsScreen from './screens/AboutUsScreen/AboutUsScreen';
import QuestListScreen from './screens/QuestListScreen/QuestListScreen';
import CalendarScreen from './screens/CalendarScreen/CalendarScreen';
import LanguageScreen from './screens/LanguageScreen/LanguageScreen';
import { ConfigProvider } from 'antd';
import QuestRulesScreen from './screens/QuestRulesScreen/QuestRulesScreen';
import QuestGamerIntroductionScreen from './screens/QuestGamerIntroductionScreen/QuestGamerIntroductionScreen';
import QuestScreen from './screens/QuestScreen/QuestScreen';
import { DrawnQuest, drawQuests } from './quests/quests';
import RedeemRewardScreen from './screens/RedeemRewardScreen/RedeemRewardScreen';
import { getCookie } from './helpers';

function App() {
  const [selectedLanguage, setLanguage] = useState(Language.PL);
  const [travelersName, setTravelersName] = useState<string | undefined>(undefined);
  const [drawnQuests, setDrawnQuests] = useState<DrawnQuest[]>([]);
  const [selectedQuest, setSelectedQuest] = useState<DrawnQuest>()
  const [isRewardRedeemed, setIsRewardRedeemed] = useState(false)

  useEffect(() => {
    // jeśli pierwszy raz jest uzywana
    if (!document.cookie) {
      const quests = drawQuests();
      setDrawnQuests(quests)
      document.cookie = 'quests=' + JSON.stringify(quests)
      document.cookie = "dateGenerated=" + new Date()
      document.cookie = "isRewardReedemed=false"
      document.cookie = "areRulesVisible=true"
    } else {
      // jesli questy wygenerowane byly dzisiaj
      if (new Date(getCookie("dateGenerated")).getDate() === new Date().getDate()) {
        setDrawnQuests(JSON.parse(getCookie("quests")))
        setTravelersName(getCookie("travelersName"))
        if (getCookie("isRewardReedemed") === "false") {
          setIsRewardRedeemed(false)
          document.cookie = "isRewardRedeemed=false"
        } else {
          setIsRewardRedeemed(true)
        }
      } else {
        // jesli questy wygererowane byly wczoraj
        const quests = drawQuests();
        setDrawnQuests(quests)
        document.cookie = 'quests=' + JSON.stringify(quests)
        document.cookie = "dateGenerated=" + new Date()
        document.cookie = "areRulesVisible=true"
        document.cookie = "isRewardRedeemed=false"
      }
    }
  }, [])

  function updateQuestProgress(selectedQuest: DrawnQuest, progress: QuestProgress) {
    if (selectedQuest && drawnQuests.length) {
      const questsCopy = [...drawnQuests]
      questsCopy[questsCopy.findIndex((quest => (quest.id === selectedQuest.id && quest.isMobile === selectedQuest.isMobile)))].progress = progress
      setDrawnQuests(questsCopy)
      document.cookie = "quests=" + JSON.stringify(questsCopy)
      setSelectedQuest({ ...selectedQuest, progress: progress })
    }
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#5B758D',
          colorInfo: '#5B758D',
        },
      }}
    >
      <div className="appBody">
        <TopBar />
        <div className='content'>
          <Routes>
            <Route path="/" element={<MenuScreen lang={selectedLanguage} />} />
            <Route path="/menu" element={<MenuScreen lang={selectedLanguage} />} />
            <Route path="/about" element={<AboutUsScreen lang={selectedLanguage} />} />
            <Route path="/questRules" element={<QuestRulesScreen lang={selectedLanguage} />} />
            <Route path="/questMenu" element={<QuestListScreen isRewardRedeemed={isRewardRedeemed} lang={selectedLanguage} travelersName={travelersName} drawnQuests={drawnQuests} setSelectedQuest={(quest) => setSelectedQuest(quest)} />} />
            <Route path="/questIntroduction" element={<QuestGamerIntroductionScreen lang={selectedLanguage} travelersName={travelersName} setTravelersName={(name) => { setTravelersName(name); document.cookie = "travelersName=" + name}} />} />
            {selectedQuest && <Route path="/quest" element={<QuestScreen lang={selectedLanguage} setQuestProgress={(progress: QuestProgress) => updateQuestProgress(selectedQuest, progress)} quest={selectedQuest} />} />}
            <Route path="/calendar" element={<CalendarScreen lang={selectedLanguage} />} />
            <Route path="/language" element={<LanguageScreen lang={selectedLanguage} selectLanguage={setLanguage} />} />
            <Route path="/rewards" element={<RedeemRewardScreen lang={selectedLanguage} redeemReward={() => { setIsRewardRedeemed(true); document.cookie = "isRewardReedemed=true"; console.log(document.cookie)}} numberOfQuestsCompleted={drawnQuests.filter(quest => quest.progress === QuestProgress.completed).length}/>} />
          </Routes>
        </div>
        <BottomBar />
      </div>
    </ConfigProvider>
  );
}

export default App;
