import { Button, Input, Modal } from 'antd'
import React, { useState } from 'react'
import QuestMenuButton from '../../components/quest-menu-button/QuestMenuButton'
import { checkPasswordEN, checkPasswordPL, completeQuestEN, completeQuestPL, enterPasswordEN, enterPasswordPL, giveUpEN, giveUpPL, Language, modalNoEN, modalNoPL, modalYesEN, modalYesPL, perlySangonomiyiGiveUpModalEN, perlySangonomiyiGiveUpModalPL, qrCodeNotWorkingEN, qrCodeNotWorkingPL, QuestProgress, Region, scanQrCodeEN, scanQrCodePL, standEN, standPL } from '../../constants'
import './GenericStandQuest.scss'
import katherine from '../../assets/graphics/emojis/katherine.webp'
import { standQuests } from '../quests'
import { QrScanner } from '@yudiel/react-qr-scanner'
import { useNavigate } from 'react-router-dom'

interface GenericStandQuestProps {
    lang: Language,
    questProgress: QuestProgress,
    setQuestProgress: (progress: QuestProgress) => void,
    questId: number,
}

function GenericStandQuest(props: GenericStandQuestProps) {
    const [isGiveUpModalVisible, setGiveUpModalVisibility] = useState(false)
    const [isCompleteModalVisible, setCompleteModalVisibility] = useState(false)
    const [inputPassword, setInputPassword] = useState("")
    const navigate = useNavigate();

    const quest = standQuests.get(props.questId);
    return (<div className='genericStandQuestBody'>
        <QuestMenuButton
            location={props.lang === Language.PL ? standPL : standEN}
            progress={props.questProgress}
            region={quest?.region}
            title={props.lang === Language.PL ? quest?.namePL : quest?.nameEN} />
        <div className="genericStandQuestBubbleBox">
            <div className="genericStandQuestBubbleSpeechBubble">
                <p className='genericStandQuestBubbleSpeechBubbleText'>{props.lang === Language.PL ? quest?.descriptionPL : quest?.descriptionEN}</p>
            </div>
            <img src={katherine} alt="Katherine" className='genericStandQuestBubbleEmoji' />
        </div>
        <div className='genericStandQuestButtonBox'>
            <Button size='large' type="primary" className='genericStandQuestButton' onClick={() => setCompleteModalVisibility(true)}>{props.lang === Language.PL ? completeQuestPL : completeQuestEN}</Button>
            <Button size='large' type="primary" onClick={() => setGiveUpModalVisibility(true)}>{props.lang === Language.PL ? giveUpPL : giveUpEN}</Button>
        </div>
        <Modal open={isGiveUpModalVisible} 
        okText={props.lang === Language.PL ? modalYesPL : modalYesEN}
        cancelText={props.lang === Language.PL ? modalNoPL : modalNoEN}
        centered={true}
        bodyStyle={{ fontFamily: 'Lato', fontSize: '1.2rem'}}
        cancelButtonProps={{ size: 'large'}}
        okButtonProps={{ size: 'large'}}
        onOk={() => {
            setGiveUpModalVisibility(false)
            props.setQuestProgress(QuestProgress.failed)
            navigate('/questMenu')
        }} 
        onCancel={() => setGiveUpModalVisibility(false)}>
        <p>{props.lang === Language.PL ? perlySangonomiyiGiveUpModalPL : perlySangonomiyiGiveUpModalEN}</p>
      </Modal>
      <Modal open={isCompleteModalVisible} 
        footer={null}
        centered={true}
        onCancel={() => setCompleteModalVisibility(false)}
        bodyStyle={{ fontFamily: 'Lato', fontSize: '1.2rem'}}>
        <div>
            <p>{props.lang === Language.PL ? scanQrCodePL : scanQrCodeEN}</p>
            <QrScanner
          onDecode={(result) => {
            if (result === "succ") {
                props.setQuestProgress(QuestProgress.completed)
                setCompleteModalVisibility(false)
                navigate('/questMenu')
            }
            if (result === "fail") {
                props.setQuestProgress(QuestProgress.failed)
                setCompleteModalVisibility(false)
                navigate('/questMenu')
            }
          }}
          onError={(error) => console.log(error?.message)}
            />
            <p>{props.lang === Language.PL ? qrCodeNotWorkingPL : qrCodeNotWorkingEN}</p>
            <Input placeholder={props.lang === Language.PL ? enterPasswordPL : enterPasswordEN} onChange={(e) => setInputPassword(e.target.value)} value={inputPassword}/>
            <Button size='large' disabled={inputPassword === ""} type="primary" className="genericStandQuestPasswordButton" onClick={() => {
             if (inputPassword === "succ") {
                props.setQuestProgress(QuestProgress.completed)
                setCompleteModalVisibility(false)
                navigate('/questMenu')
            }
            if (inputPassword === "fail") {
                props.setQuestProgress(QuestProgress.failed)
                setCompleteModalVisibility(false)
                navigate('/questMenu')
            }
        }}>{props.lang === Language.PL ? checkPasswordPL : checkPasswordEN}</Button>
        </div>
      </Modal>
    </div>)
}

export default GenericStandQuest