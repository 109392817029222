export function compareAnswers(
    answer1: number | undefined,
    answer2: number |undefined,
    answer3: number | undefined,
    answer4: number | undefined,
    correctAnswer1: number,
    correctAnswer2: number,
    correctAnswer3: number,
    correctAnswer4: number
  ): boolean {
    let numIncorrect = 0;
  
    if (answer1 !== correctAnswer1) {
      numIncorrect++;
    }
    if (answer2 !== correctAnswer2) {
      numIncorrect++;
    }
    if (answer3 !== correctAnswer3) {
      numIncorrect++;
    }
    if (answer4 !== correctAnswer4) {
      numIncorrect++;
    }
  
    if (numIncorrect >= 2) {
      return false;
    }
  
    return true;
  }

  export function getCookie(name: string) {
    var cname = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i < ca.length; i++){
        var c = ca[i];
        while(c.charAt(0) == ' '){
            c = c.substring(1);
        }
        if(c.indexOf(cname) == 0){
            return c.substring(cname.length, c.length);
        }
    }
    return "";
}