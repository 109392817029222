import React from 'react';
import { Language, QuestProgress } from '../../constants';
import GenericStandQuest from '../../quests/GenericStandQuest/GenericStandQuest';
import QuestJanKenPon from '../../quests/QuestJanKenPon/QuestJanKenPon';
import QuestKuCzciPieciuKasen from '../../quests/QuestKuCzciPieciuKasen/QuestKuCzciPieciuKasen';
import QuestMistrzIUczen from '../../quests/QuestMistrzIUczen/QuestMistrzIUczen';
import QuestPerlySangonomiyi from '../../quests/QuestPerlySangonomiyi/QuestPerlySangonomiyi';
import { DrawnQuest } from '../../quests/quests';
import QuestSzkolenieGrzybiarza from '../../quests/QuestSzkolenieGrzybiarza/QuestSzkolenieGrzybiarza';
import QuestTestPoszukiwaczaScreen from '../../quests/QuestTestPoszukiwacza/QuestTestPoszukiwaczaScreen/QuestTestPoszukiwaczaScreen';
import QuestWymianaJezykowa from '../../quests/QuestWymianaJezykowa/QuestWymianaJezykowa';
import QuestWrittenInTheStars from '../../quests/QuestWrittenInTheStars/QuestWrittenInTheStars';
import QuestAbyssOrder from '../../quests/QuestAbyssOrder/QuestAbyssOrder';
import QuestLostPackage from '../../quests/QuestLostPackage/QuestLostPackage';
import QuestKwiatyDowodMilosci from '../../quests/QuestKwiatyDowodMilosci/QuestKwiatyDowodMilosci';

interface QuestScreenProps {
    lang: Language,
    quest: DrawnQuest,
    setQuestProgress: (progress: QuestProgress) => void,
}

function QuestScreen(props: QuestScreenProps) {
    return (<div>
        {props.quest.isMobile && props.quest.id === 1 && <QuestTestPoszukiwaczaScreen lang={props.lang} setQuestProgress={props.setQuestProgress} questProgress={props.quest.progress}/>}
        {props.quest.isMobile && props.quest.id === 2 && <QuestJanKenPon lang={props.lang} setQuestProgress={props.setQuestProgress} questProgress={props.quest.progress}/>}
        {props.quest.isMobile && props.quest.id === 3 && <QuestSzkolenieGrzybiarza lang={props.lang} setQuestProgress={props.setQuestProgress} questProgress={props.quest.progress}/>}
        {props.quest.isMobile && props.quest.id === 4 && <QuestPerlySangonomiyi lang={props.lang} setQuestProgress={props.setQuestProgress} questProgress={props.quest.progress}/>}
        {props.quest.isMobile && props.quest.id === 5 && <QuestWymianaJezykowa lang={props.lang} setQuestProgress={props.setQuestProgress} questProgress={props.quest.progress}/>}
        {props.quest.isMobile && props.quest.id === 6 && <QuestMistrzIUczen lang={props.lang} setQuestProgress={props.setQuestProgress} questProgress={props.quest.progress}/>}
        {props.quest.isMobile && props.quest.id === 7 && <QuestKuCzciPieciuKasen lang={props.lang} setQuestProgress={props.setQuestProgress} questProgress={props.quest.progress}/>}
        {props.quest.isMobile && props.quest.id === 8 && <QuestWrittenInTheStars lang={props.lang} setQuestProgress={props.setQuestProgress} questProgress={props.quest.progress}/>}
        {props.quest.isMobile && props.quest.id === 9 && <QuestAbyssOrder lang={props.lang} setQuestProgress={props.setQuestProgress} questProgress={props.quest.progress}/>}
        {props.quest.isMobile && props.quest.id === 10 && <QuestLostPackage lang={props.lang} setQuestProgress={props.setQuestProgress} questProgress={props.quest.progress}/>}
        {props.quest.isMobile && props.quest.id === 11 && <QuestKwiatyDowodMilosci lang={props.lang} setQuestProgress={props.setQuestProgress} questProgress={props.quest.progress}/>}
        {!props.quest.isMobile && <GenericStandQuest lang={props.lang} questId={props.quest.id} questProgress={props.quest.progress} setQuestProgress={props.setQuestProgress}/>}
    </div>)
}

export default QuestScreen;