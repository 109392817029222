import { Button } from 'antd';
import React from 'react';
import { Language, questKuCzciBeginWritingEN, questKuCzciBeginWritingPL, questKuCzciInfo1EN, questKuCzciInfo1PL, questKuCzciInfo2EN, questKuCzciInfo2PL } from '../../../constants';
import './Info.scss'
import raiden from '../../../assets/graphics/quests/kuczcipieciukasen/raidenInfo.webp'
import yae from '../../../assets/graphics/quests/kuczcipieciukasen/yaeInfo1.webp'
import tanuki from '../../../assets/graphics/quests/kuczcipieciukasen/tanukiInfo.webp'

interface InfoProps {
    lang: Language,
    setInfoVisibility: () => void,
}

function Info(props: InfoProps) {
    return (<div className='questKuCzciInfoBody'>
        <div className='questKuCzciSpeechBubbleBox'>
            <img src={raiden} className="questKuCzciEmoji" alt="Radien Ei" />
            <div className="questListSpeechBubbleRaiden">
                <p className='questKuCzci-speech-bubble-text'>{props.lang === Language.PL ? questKuCzciInfo1PL : questKuCzciInfo1EN}</p>
            </div>
        </div>
        <div className='questKuCzciSpeechBubbleBox'>
            <div className="questListSpeechBubbleYae">
                <p className='questKuCzci-speech-bubble-text'>{props.lang === Language.PL ? questKuCzciInfo2PL : questKuCzciInfo2EN}</p>
            </div>
            <img src={yae} className="questKuCzciEmoji" alt="Yae Miko" />
        </div>
        <img src={tanuki} className="questKuCzciTanukiEmoji" alt="Tanuki" />
        <Button size='large'
            type="primary"
            onClick={() => props.setInfoVisibility()}>
            {props.lang === Language.PL ? questKuCzciBeginWritingPL : questKuCzciBeginWritingEN}</Button>
    </div>)
}

export default Info;