import React from 'react';
import { aboutUs1EN, aboutUs1PL, aboutUs2EN, aboutUs2PL, aboutUs3EN, aboutUs3PL, aboutUs4EN, aboutUs4PL, aboutUs5EN, aboutUs5PL, aboutUs6EN, aboutUs6PL, aboutUs7EN, aboutUs7PL, aboutUs8EN, aboutUs8PL, Language, whereCanYouFindUsEN, whereCanYouFindUsPL } from '../../constants';
import './AboutUs.scss'
import aboutUs1 from '../../assets/graphics/about us/aboutUs1.jpg';
import aboutUs2 from '../../assets/graphics/about us/aboutUs2.jpg';
import postacie from '../../assets/graphics/about us/postacie.png';
import facebook from '../../assets/graphics/about us/facebook.png'
import instagram from '../../assets/graphics/about us/instagram.png'
import zrzutka from '../../assets/graphics/about us/zrzutka.png'
import { ReactComponent as Logo } from '../../assets/graphics/ui/logo.svg';
import MembersGallery from './MembersGallery/MembersGallery';

interface AboutsUsScreenProps {
    lang: Language,
}

function AboutUsScreen(props: AboutsUsScreenProps) {
    return (<div className='aboutUsBody'>
        <Logo fill="#5B758D" className='projectLogo' />
        <p>{props.lang === Language.PL ? aboutUs1PL : aboutUs1EN}</p>
        <p>{props.lang === Language.PL ? aboutUs2PL : aboutUs2EN}</p>
        <MembersGallery/>
        <p>{props.lang === Language.PL ? aboutUs3PL : aboutUs3EN}</p>
        <img src={aboutUs1} alt="About us" className='aboutUsImg' />
        <p>{props.lang === Language.PL ? aboutUs4PL : aboutUs4EN}</p>
        <p>{props.lang === Language.PL ? aboutUs5PL : aboutUs5EN}</p>
        <img src={aboutUs2} alt="About us" className='aboutUsImg' />
        <p>{props.lang === Language.PL ? aboutUs6PL : aboutUs6EN}</p>
        <p>{props.lang === Language.PL ? aboutUs7PL : aboutUs7EN}</p>
        <p>{props.lang === Language.PL ? aboutUs8PL : aboutUs8EN}</p>
        <div>
            <p className='whereCanYouFindUs'>{props.lang === Language.PL ? whereCanYouFindUsPL : whereCanYouFindUsEN}</p>
            <div className='allSocialMedia'>
                <a href='https://www.facebook.com/projectgenshin/' className='link'>
                    <div className='socialMediaBox'>
                        <img src={facebook} alt="Facebook" className='socialMediaIcon' />
                        <span className='whereCanYouFindUs'>facebook</span>
                    </div>
                </a>
                <a href='https://www.instagram.com/project_genshin' className='link'>
                    <div className='socialMediaBox'>
                        <img src={instagram} alt="Instagram" className='socialMediaIcon' />
                        <span className='whereCanYouFindUs'>instagram</span>
                    </div>
                </a>
            </div>
        </div>
    </div>)
}

export default AboutUsScreen;