import { Button } from 'antd';
import React from 'react';
import { Language, questJanKenPonInstructions1EN, questJanKenPonInstructions1PL, questJanKenPonInstructions2EN, questJanKenPonInstructions2PL, questJanKenPonStartGameEN, questJanKenPonStartGamePL } from '../../../constants';
import './QuestJanKenPonInstructions.scss'
import paimonWelcome from '../../../assets/graphics/quests/jankenpon/paimon-welcome.webp'
import yae from '../../../assets/graphics/emojis/yae1.webp'

interface QuestJanKenPonInstructionsProps {
    lang: Language,
    setIsInstructionVisible: (visibility: boolean) => void,
}

function QuestJanKenPonInstructions(props: QuestJanKenPonInstructionsProps) {
    return (<div className='questJanKenPonInstructionsBody'>
        <div className='questJanKenPonInstructionsPaimonBox'>
            <img src={paimonWelcome} className="questJanKenPonInstructionsPaimonEmoji" alt="Paimon"/>
            <div className="questJanKenPonInstructionsPaimonSpeechBubble">
                <p className='questJanKenPonInstructions-speech-bubble-text'>{props.lang == Language.PL ? questJanKenPonInstructions1PL : questJanKenPonInstructions1EN}</p>
            </div>
        </div>
        <div className='questJanKenPonInstructionsYaeBox'>
            <div className="questJanKenPonInstructionsYaeSpeechBubble">
                <p className='questJanKenPonInstructions-speech-bubble-text'>{props.lang === Language.PL ? questJanKenPonInstructions2PL : questJanKenPonInstructions2EN}</p>
            </div>
            <img src={yae} className="questJanKenPonInstructionsYaeEmoji" alt="Yae Miko"/>
        </div>
        <Button size='large'
            type="primary"
            onClick={() => props.setIsInstructionVisible(false)}>{props.lang === Language.PL ? questJanKenPonStartGamePL : questJanKenPonStartGameEN}</Button>
    </div>)
}

export default QuestJanKenPonInstructions;