import React, { useState } from 'react';
import { Language, QuestProgress } from '../../../constants';
import Poem1 from './poems/Poem1/Poem1';
import Poem2 from './poems/Poem2/Poem2';
import Poem3 from './poems/Poem3/Poem3';
import './PoemScreen.scss';

interface PoemScreenProps {
    lang: Language,
    setQuestProgress: (progress: QuestProgress) => void,
}

function PoemScreen(props: PoemScreenProps) {
    const [selectedAnswer, setSelectedAnswer] = useState<undefined | number>(undefined)
    const [selectedPoem, setSelectedPoem] = useState<number>(Math.floor(Math.random() * 3) + 1)

    return (<div>
        {selectedPoem === 1 && <Poem1 lang={props.lang} setQuestProgress={props.setQuestProgress}/>}
        {selectedPoem === 2 && <Poem2 lang={props.lang} setQuestProgress={props.setQuestProgress}/>}
        {selectedPoem === 3 && <Poem3 lang={props.lang} setQuestProgress={props.setQuestProgress}/>}
    </div>)
}

export default PoemScreen;