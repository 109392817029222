import React from 'react'
import { JanKenPonSymbol } from '../../../quests';
import './JanKenPonChooseSymbolBoard.scss'
import rock from '../../../../assets/graphics/quests/jankenpon/kamien.png'
import rockGrey from '../../../../assets/graphics/quests/jankenpon/kamien-grey.png'
import paper from '../../../../assets/graphics/quests/jankenpon/papier.png'
import paperGrey from '../../../../assets/graphics/quests/jankenpon/papier-grey.png'
import scissors from '../../../../assets/graphics/quests/jankenpon/nozyce.png'
import scissorsGrey from '../../../../assets/graphics/quests/jankenpon/nozyce-grey.png'
import { Language, questJanKenPonChooseSymbolTextEN, questJanKenPonChooseSymbolTextPL, questJanKenPonChosenSymbolTextEN, questJanKenPonChosenSymbolTextPL } from '../../../../constants';

interface JanKenPonChooseSymbolBoardProps {
    selectedSymbol: JanKenPonSymbol | undefined,
    setSelectedSymbol: (symbol: JanKenPonSymbol | undefined) => void,
    lang: Language,
}

function JanKenPonChooseSymbolBoard(props: JanKenPonChooseSymbolBoardProps) {
    return (<div>
        {!props.selectedSymbol && <div className='janKenPonSymbolBoxWithText'>
            <div className='janKenPonChooseSymbolText'>{props.lang === Language.PL ? questJanKenPonChooseSymbolTextPL : questJanKenPonChooseSymbolTextEN}</div>
            <div className='janKenPonSymbolBox'>
                <img src={rock} alt="Rock" className='janKenPonSymbol janKenPonSymbol-selectable' onClick={() => props.setSelectedSymbol(JanKenPonSymbol.rock)} />
                <img src={paper} alt="Paper" className='janKenPonSymbol janKenPonSymbol-selectable' onClick={() => props.setSelectedSymbol(JanKenPonSymbol.paper)} />
                <img src={scissors} alt="Scissors" className='janKenPonSymbol janKenPonSymbol-selectable' onClick={() => props.setSelectedSymbol(JanKenPonSymbol.scissors)} />
            </div>
        </div>}
        {props.selectedSymbol && <div className='janKenPonSymbolBoxWithText'>
            <div className='janKenPonChooseSymbolText'>{props.lang === Language.PL ? questJanKenPonChosenSymbolTextPL : questJanKenPonChosenSymbolTextEN}</div>
            <div className='janKenPonSymbolBox'>
                <img src={props.selectedSymbol === JanKenPonSymbol.rock ? rock : rockGrey} alt="Rock" className='janKenPonSymbol' />
                <img src={props.selectedSymbol === JanKenPonSymbol.paper ? paper : paperGrey} alt="Paper" className='janKenPonSymbol' />
                <img src={props.selectedSymbol === JanKenPonSymbol.scissors ? scissors : scissorsGrey} alt="Scissors" className='janKenPonSymbol' />
            </div>
        </div>}
    </div>)
}

export default JanKenPonChooseSymbolBoard;