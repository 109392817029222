import React from 'react'
import '../../QuestWrittenInTheStars/QuestInfo/QuestInfo.scss'
import { Language, questKwiatyDowodMilosciInfoButtonEN, questKwiatyDowodMilosciInfoButtonPL, questKwiatyDowodMilosciInfoEN, questKwiatyDowodMilosciInfoPL } from '../../../constants'
import naviaInfo from '../../../assets/graphics/quests/kwiaty/naviaInfo.webp'
import { Button } from 'antd'

interface QuestKwiatyInfoProps {
    lang: Language,
    closeInfo: () => void,
}

function QuestKwiatyInfo(props: QuestKwiatyInfoProps) {
    return (<div className='questWrittenInTheStarsInfoBody'>
        <div className='questWrittenInTheStarsInfoSpeechBubbleBox'>
            <div className="questWrittenInTheStarsInfoSpeechBubble">
                <p className='questWrittenInTheStarsInfoSpeechBubbleText'>
                    {props.lang === Language.PL ? questKwiatyDowodMilosciInfoPL : questKwiatyDowodMilosciInfoEN}
                </p>
            </div>
            <img src={naviaInfo} alt="Navia" className='questWrittenInTheStarsInfoEmoji' />
        </div>
        <Button size='large' type="primary" 
        className="genericStandQuestPasswordButton" onClick={() => props.closeInfo()}>{props.lang === Language.PL ? questKwiatyDowodMilosciInfoButtonPL : questKwiatyDowodMilosciInfoButtonEN}</Button>
    </div>)
}

export default QuestKwiatyInfo