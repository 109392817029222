import React, { useEffect, useState } from "react"
import './QuestAbyssOrder.scss'
import { Language, QuestProgress, mobileEN, mobilePL, questAbyssOrderCompletedEN, questAbyssOrderCompletedPL, questAbyssOrderDescriptionEN, questAbyssOrderDescriptionPL, questGoBackToMenuEN, questGoBackToMenuPL } from "../../constants"
import xiao from '../../assets/graphics/emojis/xiao1.webp'
import { cryoMageHintEN, cryoMageHintPL, cryoMagePassword, electroMageHintEN, electroMageHintPL, electroMagePassword, hydroMageHintEN, hydroMageHintPL, hydroMagePassword, pyroMageHintEN, pyroMageHintPL, pyroMagePassword, quests } from "../quests";
import QuestMenuButton from "../../components/quest-menu-button/QuestMenuButton";
import AbyssMage from "./AbyssMage/AbyssMage";
import cryo from '../../assets/graphics/quests/abyssorder/cryo.png'
import pyro from '../../assets/graphics/quests/abyssorder/pyro.png'
import hydro from '../../assets/graphics/quests/abyssorder/hydro.png'
import electro from '../../assets/graphics/quests/abyssorder/electro.png'
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

interface QuestAbyssOrderProps {
    lang: Language,
    questProgress: QuestProgress,
    setQuestProgress: (questProgress: QuestProgress) => void,
}

function QuestAbyssOrder(props: QuestAbyssOrderProps) {
    const quest = quests.get(9);
    const [isPyroFound, setIsPyroFound] = useState(false)
    const [isCryoFound, setIsCryoFound] = useState(false)
    const [isElectroFound, setIsElectroFound] = useState(false)
    const [isHydroFound, setIsHydroFound] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if ([isPyroFound, isCryoFound, isElectroFound, isHydroFound].filter((e) => e === true).length === 3) {
            props.setQuestProgress(QuestProgress.completed)
        }
    }, [isPyroFound, isCryoFound, isElectroFound, isHydroFound])

    return (<div className="questAbyssOrderBody">
        <QuestMenuButton
            location={props.lang === Language.PL ? mobilePL : mobileEN}
            progress={props.questProgress}
            region={quest?.region}
            title={props.lang === Language.PL ? quest?.namePL : quest?.nameEN} />
        {props.questProgress === QuestProgress.inProgress && <div>
            <div className='questAbyssOrderSpeechBubbleBox'>
                <div className="questAbyssOrderSpeechBubble">
                    <p className='questAbyssOrderSpeechBubbleText'>
                        {props.lang === Language.PL ? questAbyssOrderDescriptionPL : questAbyssOrderDescriptionEN}
                    </p>
                </div>
                <img src={xiao} alt="Xiao" className='questAbyssOrderEmoji' />
            </div>
            <div className="questAbyssOrderMageRow">
                <AbyssMage lang={props.lang} password={pyroMagePassword}
                    isFound={isPyroFound}
                    imgSrc={pyro}
                    name="pyro mage"
                    confirmFindingMage={() => setIsPyroFound(true)}
                    hint={props.lang === Language.PL ? pyroMageHintPL : pyroMageHintEN} />
                <AbyssMage lang={props.lang} password={cryoMagePassword}
                    isFound={isCryoFound}
                    imgSrc={cryo}
                    name="cryo mage"
                    confirmFindingMage={() => setIsCryoFound(true)}
                    hint={props.lang === Language.PL ? cryoMageHintPL : cryoMageHintEN} />
            </div>
            <div className="questAbyssOrderMageRow">
                <AbyssMage lang={props.lang} password={electroMagePassword}
                    isFound={isElectroFound}
                    imgSrc={electro}
                    name="electro mage"
                    confirmFindingMage={() => setIsElectroFound(true)}
                    hint={props.lang === Language.PL ? electroMageHintPL : electroMageHintEN} />
                <AbyssMage lang={props.lang} password={hydroMagePassword}
                    isFound={isHydroFound}
                    imgSrc={hydro}
                    name="hydro mage"
                    confirmFindingMage={() => setIsHydroFound(true)}
                    hint={props.lang === Language.PL ? hydroMageHintPL : hydroMageHintEN} />
            </div>
        </div>}
        {props.questProgress === QuestProgress.completed && <div className="questAbyssOrderResultsBox">
            <div className='questAbyssOrderSpeechBubbleBox'>
                <div className="questAbyssOrderSpeechBubble">
                    <p className='questAbyssOrderSpeechBubbleText'>
                        {props.lang === Language.PL ? questAbyssOrderCompletedPL : questAbyssOrderCompletedEN}
                    </p>
                </div>
                <img src={xiao} alt="Xiao" className='questAbyssOrderEmoji' />
            </div>
            <Button size='large' type="primary" onClick={() => navigate('/questMenu')}>{props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button>
        </div>}
    </div>)
}

export default QuestAbyssOrder