import React, { useState } from "react";
import '../../QuestWrittenInTheStars/ConstellationGuessingScreen/ConstellationGuessingScreen.scss'
import { Language, QuestProgress } from "../../../constants";
import { constellationSets, flowerSets } from "../../quests";
import set1Img1 from '../../../assets/graphics/quests/kwiaty/set1/flowers1.png';
import set1Img2 from '../../../assets/graphics/quests/kwiaty/set1/flowers2.png';
import set1Img3 from '../../../assets/graphics/quests/kwiaty/set1/flowers3.png';
import set2Img1 from '../../../assets/graphics/quests/kwiaty/set2/flowers1.png';
import set2Img2 from '../../../assets/graphics/quests/kwiaty/set2/flowers2.png';
import set2Img3 from '../../../assets/graphics/quests/kwiaty/set2/flowers3.png';
import Flowers from "../Flowers/Flowers";


interface KwiatyGuessingScreenProps {
    lang: Language,
    selectedSetNumber: number,
    setQuestProgress: (progress: QuestProgress) => void,
}

function KwiatyGuessingScreen(props: KwiatyGuessingScreenProps) {
    const [progress, setProgress] = useState(0)
    const [score, setScore] = useState(0)
    const flowersSet = flowerSets[props.selectedSetNumber]
    const constellationSet = constellationSets[props.selectedSetNumber]

    return (<div>
        <Flowers lang={props.lang} 
        onOptionSelect={(selectedOption) => {
            if (selectedOption === flowersSet[progress].correctAnswer) {
                setScore(score + 1)
            }
            if (progress === 2) {
                props.setQuestProgress(score + (selectedOption === flowersSet[progress].correctAnswer ? 1 : 0) > 1 ? QuestProgress.completed : QuestProgress.failed)
            } else {
                setProgress(progress + 1)
            }           
        }}
        img={props.selectedSetNumber === 0 ? (progress === 0 ? set1Img1 : (progress === 1 ? set1Img2 : set1Img3)) : (progress === 0 ? set2Img1 : (progress === 1 ? set2Img2 : set2Img3))}
        option1={flowersSet[progress].option1} 
        option2={flowersSet[progress].option2} 
        option3={flowersSet[progress].option3}
        option4={flowersSet[progress].option4}/>
    </div>)
}

export default KwiatyGuessingScreen