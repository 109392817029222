import React, { useState } from 'react';
import { Language, mobileEN, mobilePL, QuestProgress, testPoszukiwaczaSubmitButtonTextEN, testPoszukiwaczaSubmitButtonTextPL } from '../../../constants';
import './QuestTestPoszukiwaczaScreen.scss'
import { Radio, Space } from 'antd';
import { drawTestPoszukiwaczaQuestions, quests, TestPoszukiwaczaQuestion, testPoszukiwaczaQuestionsMap } from '../../quests';
import { Button } from 'antd';
import { compareAnswers } from '../../../helpers';
import QuestMenuButton from '../../../components/quest-menu-button/QuestMenuButton';
import QuestTestPoszukiwaczaFailed from '../QuestTestPoszukiwaczaFailed/QuestTestPoszukiwaczaFailed';
import QuestTestPoszukiwaczaCompleted from '../QuestTestPoszukiwaczaCompleted/QuestTestPoszukiwaczaCompleted';
import QuestTestPoszukiwaczaInstructions from '../QuestTestPoszukiwaczaInstructions/QuestTestPoszukiwaczaInstructions';
import QuestTestPoszukiwaczaAnswers from '../QuestTestPoszukiwaczaAnswers/QuestTestPoszukiwaczaAnswers';

interface QuestTestPoszukiwaczaScreenProps {
    lang: Language,
    questProgress: QuestProgress,
    setQuestProgress: (progress: QuestProgress) => void
}

function QuestTestPoszukiwaczaScreen(props: QuestTestPoszukiwaczaScreenProps) {
    const [questions, setQuestions] = useState<TestPoszukiwaczaQuestion[]>(drawTestPoszukiwaczaQuestions(testPoszukiwaczaQuestionsMap))
    const [answer1, setAnswer1] = useState(undefined);
    const [answer2, setAnswer2] = useState(undefined);
    const [answer3, setAnswer3] = useState(undefined);
    const [answer4, setAnswer4] = useState(undefined);
    const [areAnswerSubmitted, setAreAnswerSubmitted] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [isAnswersPreviewVisible, setIsAnswersPreviewVisible] = useState(false);
    const [areInstructionsVisible, setAreInstructionsVisible] = useState(true);
    const quest = quests.get(1);

    const submitAnswers = () => {
        setAreAnswerSubmitted(true);
        setIsFailed(!compareAnswers(
            answer1,
            answer2,
            answer3,
            answer4,
            questions[0].correct,
            questions[1].correct,
            questions[2].correct,
            questions[3].correct))
        props.setQuestProgress(compareAnswers(
            answer1,
            answer2,
            answer3,
            answer4,
            questions[0].correct,
            questions[1].correct,
            questions[2].correct,
            questions[3].correct) ? QuestProgress.completed : QuestProgress.failed)
    }

    return (<div className='testPoszukiwaczaBody'>
        <QuestMenuButton
            location={props.lang === Language.PL ? mobilePL : mobileEN}
            progress={props.questProgress}
            region={quest?.region}
            title={props.lang === Language.PL ? quest?.namePL : quest?.nameEN} />
        {areInstructionsVisible && <QuestTestPoszukiwaczaInstructions lang={props.lang} setAreInstructionsVisible={() => setAreInstructionsVisible(false)}/>}
        {!areAnswerSubmitted && !areInstructionsVisible &&
            <div>
                <div>
                    <div className='testPoszukiwaczaQuestionTitle'>{props.lang === Language.PL ? questions[0].titlePL : questions[0].titleEN}</div>
                    <Radio.Group onChange={(e) => setAnswer1(e.target.value)} value={answer1}>
                        <Space direction="vertical">
                            <Radio value={1}>{questions[0].answer1}</Radio>
                            <Radio value={2}>{questions[0].answer2}</Radio>
                            <Radio value={3}>{questions[0].answer3}</Radio>
                        </Space>
                    </Radio.Group>
                </div>
                <div>
                    <div className='testPoszukiwaczaQuestionTitle'>{props.lang === Language.PL ? questions[1].titlePL : questions[0].titleEN}</div>
                    <Radio.Group onChange={(e) => setAnswer2(e.target.value)} value={answer2}>
                        <Space direction="vertical">
                            <Radio value={1}>{questions[1].answer1}</Radio>
                            <Radio value={2}>{questions[1].answer2}</Radio>
                            <Radio value={3}>{questions[1].answer3}</Radio>
                        </Space>
                    </Radio.Group>
                </div>
                <div>
                    <div className='testPoszukiwaczaQuestionTitle'>{props.lang === Language.PL ? questions[2].titlePL : questions[0].titleEN}</div>
                    <Radio.Group onChange={(e) => setAnswer3(e.target.value)} value={answer3}>
                        <Space direction="vertical">
                            <Radio value={1}>{questions[2].answer1}</Radio>
                            <Radio value={2}>{questions[2].answer2}</Radio>
                            <Radio value={3}>{questions[2].answer3}</Radio>
                        </Space>
                    </Radio.Group>
                </div>
                <div>
                    <div className='testPoszukiwaczaQuestionTitle'>{props.lang === Language.PL ? questions[3].titlePL : questions[3].titleEN}</div>
                    <Radio.Group onChange={(e) => setAnswer4(e.target.value)} value={answer4}>
                        <Space direction="vertical">
                            <Radio value={1}>{questions[3].answer1}</Radio>
                            <Radio value={2}>{questions[3].answer2}</Radio>
                            <Radio value={3}>{questions[3].answer3}</Radio>
                        </Space>
                    </Radio.Group>
                </div>
                <div className='testPoszukiwaczaSubmitButton'>
                    <Button
                        size='large'
                        type="primary"
                        disabled={answer1 === undefined || answer2 === undefined || answer3 === undefined || answer4 === undefined}
                        onClick={() => submitAnswers()}>
                        {props.lang === Language.PL ? testPoszukiwaczaSubmitButtonTextPL : testPoszukiwaczaSubmitButtonTextEN}</Button>
                </div>
            </div>}
        {areAnswerSubmitted && isFailed && !isAnswersPreviewVisible && <QuestTestPoszukiwaczaFailed lang={props.lang} setIsAnswersPreviewVisible={() => setIsAnswersPreviewVisible(true)}/>}
        {areAnswerSubmitted && !isFailed && !isAnswersPreviewVisible && <QuestTestPoszukiwaczaCompleted lang={props.lang} setIsAnswersPreviewVisible={() => setIsAnswersPreviewVisible(true)}/>}
        {areAnswerSubmitted && isAnswersPreviewVisible && <QuestTestPoszukiwaczaAnswers lang={props.lang} questions={questions} answers={[answer1, answer2, answer3, answer4]}/>}
    </div>)
}

export default QuestTestPoszukiwaczaScreen;