import React from 'react';
import { Members } from '../../../../constants';
import './MemberPhoto.scss'

interface MemberPhotoProps {
    name: string,
}

function MemberPhoto(props: MemberPhotoProps) {
    return (<a href={Members.get(props.name)?.socialMediaUrl} className='link'>
        <div className='memberPhotoBox'>
            <img src={Members.get(props.name)?.imgUrl} className="memberPhoto"/>
            <span className='memberPhotoText'>{Members.get(props.name)?.name}</span>
        </div></a>)
}

export default MemberPhoto;