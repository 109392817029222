import React from 'react';
import './QuestTestPoszukiwaczaFailed.scss'
import klee from '../../../assets/graphics/emojis/klee1.webp'
import { Button } from 'antd';
import { Language, questGoBackToMenuEN, questGoBackToMenuPL, testPoszukiwaczaFailedEN, testPoszukiwaczaFailedPL, testPoszukiwaczaViewAnswersEN, testPoszukiwaczaViewAnswersPL } from '../../../constants';
import { Link } from 'react-router-dom';

interface QuestTestPoszukiwaczaFailedProps {
    lang: Language,
    setIsAnswersPreviewVisible: () => void,
}

function QuestTestPoszukiwaczaFailed(props: QuestTestPoszukiwaczaFailedProps) {
    return (<div>
        <div className='questTestPoszukiwaczaFailedEmojiBox'>
            <div className="questTestPoszukiwaczaFailedSpeechBubble">
                <p className='questTestPoszukiwaczaFailedSpeechBubbleText'>{props.lang === Language.PL ? testPoszukiwaczaFailedPL : testPoszukiwaczaFailedEN}</p>
            </div>
            <img src={klee} className="questTestPoszukiwaczaFailedEmoji" alt='Klee'/>
        </div>
        <div className='questTestPoszukiwaczaFailedButtonBox'>
            <Button size='large' type="primary" className='questTestPoszukiwaczaFailedButton' onClick={() => props.setIsAnswersPreviewVisible()}>
                {props.lang === Language.PL ? testPoszukiwaczaViewAnswersPL : testPoszukiwaczaViewAnswersEN}
            </Button>
            <Link to="/questMenu"><Button size='large' type="primary">{props.lang === Language.PL ? questGoBackToMenuPL : questGoBackToMenuEN}</Button></Link>
        </div>
    </div>)
}

export default QuestTestPoszukiwaczaFailed;